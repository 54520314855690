import { materialActions } from '@/actions/materialActions'

const initialState = {
  list: [],
  params: { page: 1, pageSize: 10 },
  position: 0,
  noMore: false,
  categorys: []
}
export default (state = initialState, { payload, type }) => {
  switch (type) {
    case materialActions.MATERIAL_LIST:
      return {
        ...state,
        list: payload
      }
    case materialActions.MATERIAL_PARAMS:
      return {
        ...state,
        params: payload
      }
    case materialActions.MATERIAL_POSITION:
      return {
        ...state,
        position: payload
      }
    case materialActions.MATERIAL_NOMORE:
      return {
        ...state,
        noMore: payload
      }
    case materialActions.MATERIAL_CATEGORYS:
      return {
        ...state,
        categorys: payload,
        params: { ...state.params }
      }
    default:
      return state
  }
}
