import { liveBuryingPoint } from '@/services/global'

/**
 * 小程序直播统计
 * @param {object} data
 * @param {string | number} data.code - code值
 * @param {string | number} data.live_ids - 直播间ids
 * @param {string | number} data.goods_ids - 直播间ids
 * @param {string | number} data.amount - 订单金额
 */
export const __zhp_miniliveStat = async data => {
  try {
    liveBuryingPoint(data)
  } catch (error) {}
}

window.__zhp_miniliveStat = __zhp_miniliveStat

export default () => {
  // qq mobile stats
  // eslint-disable-next-line
  var _mtac = { performanceMonitor: 1, senseQuery: 1 }
  ;(function() {
    var mta = document.createElement('script')
    mta.src = '//pingjs.qq.com/h5/stats.js?v2.0.4'
    mta.setAttribute('name', 'MTAH5')
    mta.setAttribute('sid', '500681357')
    mta.setAttribute('cid', '500681380')
    var s = document.getElementsByTagName('script')[0]
    s.parentNode.insertBefore(mta, s)
  })()
}
