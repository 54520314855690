import { parse } from 'querystring'
import config from '@/config'
export const production = process.env.NODE_ENV === 'production'
// cdn 路径
export const staticPath = config.STATIC_PATH
// http cdn路径
export const HttpStaticPath = config.HTTP_STATIC_PATH
// api
export const serverApi = config.HOST_API
export const houseKeepApi = config.HOST_HOUSEKEEP_API
// node api
export const serverNodeApi = config.HOST_NODE_API
// video api
export const videoApi = config.HOST_VIDEO_API
// msg api
export const msgApi = config.HOST_MSG_API
// plugin
export const pluginPath = config.PLUGIN_HOST
// public
export const publicPath = process.env.PUBLIC_URL
// 上传url
export const CommonUploadUrl = `${serverApi}/uploadsImage`
// 页面loading文案
export const pageLoadingText = '加载中'
// 微信登录开关
export const wechatAuthTrigger = true
// 微信验证url
export const wechatAuthUrl = config.WECHAT_AUTH_PATH
// 店铺信息
export const Shop = {
  name: '购宜购',
  shopName: '购宜购商城',
  shopDesc: '购宜购商城描述简介',
  host: config.HOST
}
// cdn图片大小配置
export const ImageSize = {
  icon: '?imageView2/2/w/38/q/100',
  max: '?imageView2/2/w/750/q/100',
  img1: '?imageView2/2/w/710/q/100',
  min: '?imageView2/2/w/100/q/85',
  comment: '?imageView2/2/w/280/q/85',
  normal: '?imageView2/2/w/350/q/85',
  sourceComment: '?imageView2/2/w/750/q/85',
  avatar: '?imageView2/2/w/150/q/85',
  sourceAvatar: '?imageView2/2/w/750/q/85',
  goods: '?imageView2/2/w/375/q/85',
  sourceGoods: '?imageView2/2/w/750/q/85',
  smallGoods: '?imageView2/2/w/150/q/85'
}
//判断是否是微信
export const isWeixin = (function() {
  try {
    var ua = window.navigator.userAgent.toLowerCase()
    var matched = ua.match(/MicroMessenger/i)
    // @HACK
    /* eslint-disable no-underscore-dangle */
    console.log(ua, ua.match(/MicroMessenger/i), 'micromessenger')
    return (matched && matched[0]) === 'micromessenger'
  } catch (error) {
    alert('isWechat env error!')
  }
})()
// 安卓环境
export const isAndroid = window.navigator.userAgent.match(/android/i)
// ios环境
export const isIos = window.navigator.userAgent.match(/(iPhone|iPod|iPad);?/i)
//移动端环境
export const isMobile = window.navigator.userAgent.match(
  /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
)
// webview环境
export const isWebview =
  (typeof window.webkit !== 'undefined' ||
    typeof window.android !== 'undefined') &&
  !isWeixin

export const isAdminView = parse(window.location.search.substr(1)).isAdminView

export const clientHeight = window.innerHeight

// udesk key
export const UDESK_KEY = '66d04f125980facfd10ff6d8df0c9277'
