import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Modal } from 'antd-mobile'
import styles from './newCoupon.module.less'
import { staticPath } from '@/variable'
import StyleFloatNumber from './StyleFloatNumber'

export default props => {
  console.log(props.data)
  const [visible, setVisible] = useState(true)
  const [couponList] = useState(props.data || [])

  const goToUse = () => {
    setVisible(false)
  }
  const bgImg = !couponList.length ? null : couponList[0].img
  const couponStyle = bgImg
    ? { backgroundImage: `url(${staticPath}${bgImg})` }
    : {}
  return (
    <div>
      <Modal
        visible={visible}
        popup
        className={styles.wrapper}
        transparent
        // maskClosable={true}
        // onClose={() => setVisible(false)}
      >
        <div className={styles.box}>
          <div className={styles.container} style={couponStyle}>
            <div
              className={`${styles.wrapper} ${
                couponList.length * 1 === 1 ? styles.mgb : ''
              }`}
            >
              {couponList.map((val, index) => (
                <div key={index} className={`${styles.couponsText}`}>
                  <span className={styles.leftText}>
                    <small className={styles.rmb}>￥</small>
                    <span>
                      <StyleFloatNumber
                        value={val.coupon_val}
                        intClass={styles.intClass}
                        pointerClass={styles.pointerClass}
                        floatClass={styles.floatClass}
                      />
                    </span>
                  </span>
                  <span className={styles.rightText}>
                    {val.coupon_info.condition_amount * 1 === 0 ? (
                      <>无门槛使用</>
                    ) : (
                      <>
                        满{val.coupon_info.condition_amount * 1}-
                        {val.coupon_val * 1}
                      </>
                    )}
                  </span>
                </div>
              ))}
            </div>
            <Link
              to={
                couponList.length * 1 === 1
                  ? `/zone/coupon?id=${
                      couponList[0].coupon_ids
                    }&_timestamp=${Date.now()}`
                  : '/category'
              }
              onClick={goToUse}
              className={styles.btn}
            >
              立即使用
            </Link>
          </div>
          <div onClick={() => setVisible(false)} className={styles.close} />
        </div>
      </Modal>
    </div>
  )
}
