const UPDATE = 'listView/UPDATE'

const listViewActions = {
  UPDATE
}

export const initialState = {
  // list加载loading
  loading: false,
  // 列表加载error
  error: false,
  // 列表是否全部加载完
  done: false,
  // 列表请求相关参数
  params: undefined,
  service: undefined,
  // 列表数据
  list: [],
  // window滚动条位置
  scrollTop: 0,
  // 默认从page页开始请求
  page: 1,
  // 默认分页长度
  pageSize: 10,
  // 辅助数据
  extraData: undefined,
  // 服务器返回的分页信息
  _page: {},
  // 场景值
  key: undefined
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case listViewActions.UPDATE:
      return {
        ...state,
        ...payload
      }
    default:
      return state
  }
}
