import { tabliveActions } from '@/actions/tabliveActions'

const initialState = {
  liveList: [],
  params: { page: 1, pageSize: 10, type: 6 },
  position: 0,
  liveNoMore: false
}
export default (state = initialState, { payload, type }) => {
  switch (type) {
    case tabliveActions.LIVE_LIST:
      return {
        ...state,
        liveList: payload
      }
    case tabliveActions.LIVE_PARAMS:
      return {
        ...state,
        params: payload
      }
    case tabliveActions.LIVE_POSITION:
      return {
        ...state,
        position: payload
      }
    case tabliveActions.LIVE_NOMORE:
      return {
        ...state,
        liveNoMore: payload
      }
    default:
      return state
  }
}
