import { orderActions } from '@/actions/orderActions'

const initialState = {
  first: true,
  loading: true,
  error: false,
  done: false,
  list: [],
  scrollTop: 0,
  tabStatus: 0,
  order_delete_cancel_config: 0,
  addAddress: null, // 订单新增的地址,订单新增地址的时候，直接返回订单页面，需要用到这个参数
  modifyAddr: null // 修改订单地址
}

export default (state = initialState, { type, payload }) => {
  const reset = () => {
    const getReset = true
    return getReset
  }
  switch (type) {
    case orderActions.REQUEST:
      return {
        ...state,
        done: false,
        error: false,
        loading: true
      }
    case orderActions.SCROLLTOP:
      return {
        ...state,
        scrollTop: payload
      }
    case orderActions.ADDADDRESS:
      return {
        ...state,
        addAddress: payload
      }
    case orderActions.TABSTATUS:
      return {
        ...state,
        tabStatus: payload
      }
    case orderActions.MODIFY:
      return {
        ...state,
        modifyAddr: payload
      }
    case orderActions.PARAMS:
      return {
        ...state,
        params: payload
      }
    case orderActions.OK:
      return {
        ...state,
        first: reset ? reset : false,
        list: payload.init ? payload._list : [...state.list, ...payload._list],
        params: {
          ...state.params,
          ...payload._page
        },
        loading: false,
        done: true,
        order_delete_cancel_config: payload.order_delete_cancel_config
      }
    case orderActions.ERROR:
      return {
        ...state,
        done: true,
        loading: false,
        error: payload
      }
    default:
      return { ...state, reset }
  }
}
