import React, { useState, useRef, useEffect } from 'react'
import CustomizedLink from '@/components/CustomizedLink'
import { Toast } from 'antd-mobile'
import { getModalClicks } from '@/services/global'
import { staticPath } from '@/variable'
import styles from './styles.module.less'
import closeIcon from './close.png'
import { throttle } from 'lodash'

export default ({ modalData, closeHomePageSmallModal }) => {
  const [visible, setVisible] = useState(true)
  const [mousePosition1, setP1] = useState({})
  const [mousePosition2, setP2] = useState({ x: 0, y: 0 })
  const [isScroll, setIsScroll] = useState(0)
  const scrollRef = useRef()
  const rightBtn = useRef()

  useEffect(() => {
    window.addEventListener('scroll', onScrollThrottle)
    return () => {
      clearTimeout(scrollRef.current)
      window.removeEventListener('scroll', onScrollThrottle)
    }
  }, [])

  const onScroll = () => {
    if (scrollRef.current) {
      clearTimeout(scrollRef.current)
      setIsScroll(true)
    }
    scrollRef.current = setTimeout(() => {
      setIsScroll(false)
    }, 1000)
  }
  const onScrollThrottle = throttle(onScroll, 500)

  //获取手指按下位置
  const getMousePosition = e => {
    const touch = e.targetTouches[0]
    setP1({
      x: touch.clientX,
      y: touch.clientY
    })
    document.addEventListener('touchmove', null, { passive: false })
  }

  const onLinkClick = async () => {
    const { type, msg } = await getModalClicks({ ids: modalData.ids * 1 })
    if (type === 1) return Toast.fail(msg)
    window.JAnalyticsCevent('2020.02.13-home-activitydialog')
    setVisible(false)
  }

  const touchMove = e => {
    e.stopPropagation()
    e.preventDefault()
    const touch = e.targetTouches[0]
    const { moveX } = getXposition(touch.clientX * 1)
    const resultY = getYposition(touch.clientY * 1)
    rightBtn.current.style.transform =
      'translate(' + moveX + 'px,' + resultY + 'px)'
    rightBtn.current.style.transition = 'null'
  }
  const moseEnd = e => {
    const touch = e.changedTouches[0]
    // const { resultX } = getXposition(touch.clientX * 1)
    const resultY = getYposition(touch.clientY * 1)
    setP2({
      x: 0,
      y: resultY
    })
    rightBtn.current.style.transform =
      'translate(' + 0 + 'px,' + resultY + 'px)'
    rightBtn.current.style.transition = 'left 0.5s ease'
    document.addEventListener('touchmove', null, { passive: true })
  }

  const getXposition = x => {
    //x总共移动的距离
    const moveX = x - mousePosition1.x * 1 + mousePosition2.x * 1
    const elementRight = parseInt(
      window.getComputedStyle(rightBtn.current).right
    )
    //中间线
    const halfViewWidth =
      window.innerWidth / 2 - rightBtn.current.offsetWidth / 2
    // 判断最终停留,大于0在右边
    const resultX =
      moveX * 1 + elementRight * 1 + halfViewWidth >= 0 ? 0 : -halfViewWidth * 2
    return {
      moveX,
      resultX
    }
  }

  const getYposition = y => {
    const elementTop = parseInt(window.getComputedStyle(rightBtn.current).top)
    //y总共移动的距离
    const moveY = y - mousePosition1.y * 1 + mousePosition2.y * 1
    //y的最大值
    const maxY = window.innerHeight - rightBtn.current.offsetHeight
    //设置y的边界
    const resultY =
      moveY * 1 + elementTop * 1 < 0
        ? -elementTop
        : moveY * 1 + elementTop * 1 > maxY
        ? maxY - elementTop
        : moveY
    return resultY
  }

  if (
    JSON.stringify(modalData) === '{}' ||
    !modalData ||
    !visible ||
    modalData.pops * 1 === 0
  ) {
    return null
  }
  return (
    <div
      className={`${styles.homePgae} ${
        isScroll ? styles.easeOut : styles.easeIn
      }`}
    >
      <div
        ref={ref => (rightBtn.current = ref)}
        className={`${styles.rightBtn} ${!visible ? '' : styles.hide} `}
        // onClick={showModal}
        onTouchStart={getMousePosition}
        onTouchMove={touchMove}
        onTouchEnd={moseEnd}
      >
        <CustomizedLink
          type={modalData.link_type}
          outlink={modalData.outlink}
          onClick={() => onLinkClick()}
        >
          <div
            style={{ backgroundImage: `url(${staticPath}${modalData.thumb})` }}
            className={styles.smallImg}
          />
        </CustomizedLink>
        <img
          src={closeIcon}
          alt=""
          className={styles.closeIcon}
          onClick={() => closeHomePageSmallModal()}
        />
      </div>
    </div>
  )
}
