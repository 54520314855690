const CHANGE_TAB = 'searchVideoOrLive/CHANGE_TAB'
const UPDATE_USERLIST = 'searchVideoOrLive/UPDATE_USERLIST'
const UPDATE_LIVELIST = 'searchVideoOrLive/UPDATE_LIVELIST'
const UPDATE_VIDEOLIST = 'searchVideoOrLive/UPDATE_VIDEOLIST'
const UPDATE_GRAPHICLIST = 'searchVideoOrLive/UPDATE_GRAPHICLIST'
const RESET_DATA = 'searchVideoOrLive/RESET_DATA'
const UPDATE_POSITION = 'searchVideoOrLive/UPDATE_POSITION'
const UPDATE_PARAMS = 'searchVideoOrLive/UPDATE_PARAMS'
const UPDATE_RESULTNOMORE = 'searchVideoOrLive/UPDATE_RESULTNOMORE'

export const searchActions = {
  CHANGE_TAB,
  UPDATE_USERLIST,
  UPDATE_LIVELIST,
  UPDATE_VIDEOLIST,
  UPDATE_GRAPHICLIST,
  RESET_DATA,
  UPDATE_POSITION,
  UPDATE_PARAMS,
  UPDATE_RESULTNOMORE
}

const updateTab = payload => ({
  type: CHANGE_TAB,
  payload
})

const updateUserList = payload => ({
  type: UPDATE_USERLIST,
  payload
})

const updateLiveList = payload => ({
  type: UPDATE_LIVELIST,
  payload
})

const updateVideoList = payload => ({
  type: UPDATE_VIDEOLIST,
  payload
})

const updateGraphicList = payload => ({
  type: UPDATE_GRAPHICLIST,
  payload
})

const resetData = payload => ({
  type: RESET_DATA,
  payload
})

const updatePosition = payload => ({
  type: UPDATE_POSITION,
  payload
})

const updateParams = payload => ({
  type: UPDATE_PARAMS,
  payload
})

const updateResultNoMore = payload => ({
  type: UPDATE_RESULTNOMORE,
  payload
})

export {
  updateTab,
  updateUserList,
  updateLiveList,
  updateVideoList,
  updateGraphicList,
  resetData,
  updatePosition,
  updateParams,
  updateResultNoMore
}
