import React, { Suspense, lazy } from 'react'
import { Route, Switch } from 'react-router-dom'
import RouteLoader from '@/components/RouteLoader'
import RouteLoaderError from '@/components/RouteLoader/Error'
import NoMatch from '@/containers/Exception/404'
import routes from './config'
import Authorized from '@/components/Authorized'

// init React.lazy Comp
function LazyLoader(props) {
  return lazy(() => import(`@/containers/${props}`))
}

// init menu config be react router config style
function processRoutesLoadable(routes) {
  routes.forEach((route, index) => {
    if (route.component && typeof route.component === 'string') {
      route.component = LazyLoader(route.component)
    }
  })
  return routes
}
// flatten routes
function generatorRoutes(arr) {
  const rs = []
  function cyc(routes) {
    return routes.forEach(route => {
      if (route.routes) {
        cyc(route.routes.map(el => ({ ...el, noAuth: route.noAuth })))
        delete route.routes
      }
      rs.push(route)
    })
  }
  cyc(arr)
  return rs
}
// 路由扁平数据
export const flattenRoutes = generatorRoutes(routes)
const _routes = processRoutesLoadable(flattenRoutes)
// 路由path -> title 映射
export const routeTitles = flattenRoutes.reduce((a, v) => {
  if (v.title) a[v.path] = v.title
  return a
}, {})

// export the react lazy component for code split chunk
export default (
  <RouteLoaderError>
    <Suspense fallback={<RouteLoader />}>
      <Switch>
        {_routes.map(({ component: Comp, ...route }, i) => (
          <Route
            {...route}
            key={i}
            render={props => {
              if (route.noAuth) return <Comp {...props} />
              return (
                <Authorized>
                  <Comp {...props} />
                </Authorized>
              )
            }}
          />
        ))}
        {/* 404page */}
        <Route component={NoMatch} />
      </Switch>
    </Suspense>
  </RouteLoaderError>
)
