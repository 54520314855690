import { statisticActions } from '@/actions/statisticActions'

const initialState = {
  videoStatus: 'paused'
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case statisticActions.VIDEO_STATUST:
      return {
        ...state,
        videoStatus: payload
      }

    default:
      return state
  }
}
