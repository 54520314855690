import React, { useState } from 'react'
import styles from './newUserCoupon.module.less'
import { Modal, Toast } from 'antd-mobile'
import { getNewUserPackage } from '@/services/global'
import { staticPath } from '@/variable'
import { useDispatch } from 'react-redux'
import { setShowNewUserPackage } from '@/actions'
import CustomizedLink from '@/components/CustomizedLink'
import history from '@/routes/history'

const SuccessModal = ({ visible, changeVisible, data }) => {
  const goToUse = () => {
    changeVisible(false)
    if (data.link) return
    const { use_goods_type } = data
    if (use_goods_type * 1 === 0) {
      history.push('/category')
    } else {
      history.push(`/zone/coupon?id=${data.ids}&_timestamp=${Date.now()}`)
    }
  }
  return (
    <Modal visible={visible} popup className={styles.wrapper} transparent>
      <div className={styles.box}>
        <div className={styles.successModal}>
          <span className={styles.title}>领取成功</span>
          <img
            className={`${styles.successModalImg} ${styles.mb40}`}
            src={`${process.env.PUBLIC_URL}/images/getCoupons/sucai.svg`}
            alt=""
          />
          <span className={styles.mb40}>请到【我的-优惠券】查看</span>
          {data.link ? (
            <CustomizedLink
              type={data.link.link_type}
              outlink={data.link.outlink}
            >
              <span span className={styles.useBtn} onClick={goToUse}>
                去使用
              </span>
            </CustomizedLink>
          ) : (
            <span span className={styles.useBtn} onClick={goToUse}>
              去使用
            </span>
          )}
        </div>
        <div className={styles.close} onClick={() => changeVisible(false)} />
      </div>
    </Modal>
  )
}

export default function({ img }) {
  const dispatch = useDispatch()
  const [visible, setVisible] = useState(true)
  const [successModalVisible, setSuccessModalVisible] = useState(false)
  const [data, setData] = useState({})

  const imgSrc = staticPath + img
  const getPackage = async () => {
    try {
      const { data, type, msg } = await getNewUserPackage()
      if (type === 1) return Toast.fail(msg)
      setVisible(false)
      if (data.num * 1 > 1 && !data.link) {
        const message = (
          <div className={styles.toast}>
            <div>领取成功!</div>
            <div>请到【我的-优惠券】查看</div>
          </div>
        )
        Toast.info(message)
      } else {
        setSuccessModalVisible(true)
        setData(data)
      }
    } catch (error) {
      Toast.offline('领取失败，过会儿再试试吧～', 1.5)
    }
  }

  const onClose = () => {
    setVisible(false)
    dispatch(setShowNewUserPackage(false))
  }
  return (
    <React.Fragment>
      <Modal
        visible={visible}
        popup
        className={styles.wrapper}
        transparent
        wrapClassName={styles.weight}
      >
        <div className={styles.box}>
          <div
            className={styles.container}
            style={{ backgroundImage: `url(${imgSrc})` }}
            onClick={getPackage}
          />
          <div onClick={onClose} className={styles.close} />
        </div>
      </Modal>
      <SuccessModal
        visible={successModalVisible}
        changeVisible={v => setSuccessModalVisible(v)}
        data={data}
      />
    </React.Fragment>
  )
}
