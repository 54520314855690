import React from 'react'
import { Link } from 'react-router-dom'
import { Flex } from 'antd-mobile'
import GoodsImage from './GoodsImage'
import Title from './Title'
import ActivityTag from './components/ActivityTag'
import HotdiscountTag from './components/HotdiscountTag'
import SellText from './SellText'
import GoodsUserPrice from './GoodsUserPrice'
import CartBtn from '../CartBtn'
import styles from './normalGoods.module.less'
import { ImageSize, isWebview } from '@/variable'
import history from '@/routes/history'
import PointDeduction from './components/PointDeduction'
import { invokeAppEvent } from '@/utils/app'
import PriceTag from '../PriceTag'
import RoleTag from '../RoleTag'
import { saleCountFormat } from '@/utils/utils'
import { useSetState } from 'ahooks'
import NewSku from '@/components/Sku'
import { Image, Icon } from '@/components'

// 常规商品列表（推荐/分类）
export default ({
  data,
  titleKey = 'title',
  titleLine = 2,
  goodsIdsKey = 'goodsIds',
  className,
  isHotDiscount = false,
  showPoint = false,
  /** 是否有购买按钮 */
  isBuy = false,
  /** 是否只显示价格 */
  isPrice,
  ...props
}) => {
  const [skuData, setSkuData] = useSetState({
    visible: false,
    gid: undefined
  })
  const status = data.activity_status
  const onCevent = async (from, index) => {
    if (from && index) {
      window.JAnalyticsCevent(from, 'number', Number(index) + 1)
    } else if (from) {
      window.JAnalyticsCevent(from)
    }
  }
  const goToDetail = () => {
    onCevent(props.from, props.index)
    if (isWebview) {
      invokeAppEvent('gotoLinkPage', {
        link_type: 3,
        outlink: {
          ids: data.ids
        }
      })
      return
    }
    history.push(
      +data.is_tbk
        ? `/goods/tbk?${goodsIdsKey}=${data.ids}`
        : `/goods/detail?${goodsIdsKey}=${data.ids}`
    )
  }
  const target =
    data &&
    data.activity_list &&
    data.activity_list.find(
      item => item.type === 'tbk_coupon' && +item.after_price
    )
  return (
    <>
      <div
        className={`${styles.normal} ${isPrice && styles.normalPrice} ${
          className ? styles[className] : ''
        }`}
        onClick={() => goToDetail()}
        {...props}
      >
        <Flex direction="column">
          <GoodsImage src={`${data.img}${ImageSize.normal}`} status={status} />
          <Flex.Item className={styles.body} style={{ width: '100%' }}>
            <Flex direction="column" align="start" className={styles.wordBody}>
              <Flex align="center" className={styles.mb8px}>
                <Title style={{ margin: 0 }} line={titleLine}>
                  {+data.is_tbk ? (
                    <img
                      className={styles.img}
                      alt="logo"
                      src={`${process.env.PUBLIC_URL}/images/tb.png`}
                    />
                  ) : null}
                  {data[titleKey]}
                </Title>
              </Flex>

              {showPoint && data.buy_something && (
                <span className={styles.point}>{data.buy_something}</span>
              )}
              {!isHotDiscount ? (
                <Flex justify="start" className={styles.info}>
                  {String(data.is_tbk) !== '1' && (
                    <ActivityTag data={data.activity_list} />
                  )}
                  <SellText count={data.csale} hide={data.is_new_products} />
                </Flex>
              ) : null}
              {data.integral_deduct_text ? <PointDeduction data={data} /> : ''}
              {+data.is_tbk ? (
                <>
                  <Flex className={styles.tbkPrice}>
                    <PriceTag
                      value={
                        target
                          ? target.after_price
                          : data.rank_price || data.original_price
                      }
                    />
                    <RoleTag isRebuy={data.is_rebuy} data={data} />
                  </Flex>
                  {!isPrice && (
                    <PriceTag type="dash" value={data.original_price} />
                  )}
                </>
              ) : (
                <Flex style={{ width: '100%' }} justify="between">
                  <div>
                    <GoodsUserPrice
                      isPrice={isPrice}
                      data={data}
                      isHotDiscount={isHotDiscount}
                      direction={isHotDiscount ? 'row' : 'column'}
                    />
                  </div>
                  {isBuy ? (
                    <Flex
                      onClick={e => {
                        e.stopPropagation()
                        e.preventDefault()
                        setSkuData({ gid: data.ids, visible: true })
                      }}
                      align="center"
                      justify="center"
                      className={styles.buyIcon}
                    >
                      <Icon icon="icon-gowuche1" />
                    </Flex>
                  ) : null}
                </Flex>
              )}
            </Flex>
            {isHotDiscount && <HotdiscountTag data={data} />}
          </Flex.Item>
        </Flex>
      </div>
      <NewSku
        showType="buy"
        onCancel={() => setSkuData({ visible: false })}
        {...skuData}
      />
    </>
  )
}

// 爆款直降layout2商品
//noActivityTag 无活动标识
//cartBtn 按钮
//invalid 无效商品
export const HotGoods = ({
  data,
  titleKey = 'title',
  idsKey = 'ids',
  showPoint = false,
  render,
  className,
  ...props
}) => {
  const status = data.activity_status
  return (
    <div
      className={`${styles.category} ${styles.hotCategory} ${className}`}
      onClick={() => {
        history.push(
          +data.is_tbk
            ? `/goods/tbk?goodsIds=${data[idsKey]}`
            : `/goods/detail?goodsIds=${data[idsKey]}`
        )
      }}
    >
      <Flex align="start" className={styles.content}>
        <GoodsImage
          className={`${styles.img} ${styles.hotImg}`}
          src={data.img}
          status={status}
          size="row"
        />
        <Flex.Item className={styles.rightContent}>
          <Flex
            style={{ height: '100%' }}
            direction="column"
            justify="between"
            align="start"
          >
            <div>
              <Title
                className={`${styles.title} ${showPoint && styles.mb8}`}
                line={2}
                style={{ fontWeight: 'bold' }}
              >
                {+data.is_tbk ? (
                  <img
                    className={styles.img}
                    alt="logo"
                    src={`${process.env.PUBLIC_URL}/images/tb.png`}
                  />
                ) : null}
                {data[titleKey]}
              </Title>
              {showPoint && data.buy_something && (
                <span className={styles.point}>{data.buy_something}</span>
              )}
            </div>
            {props.invalid ? (
              <img
                className={styles.invalid}
                src={process.env.PUBLIC_URL + '/images/categorys/Invalid.png'}
                alt=""
              />
            ) : (
              <div style={{ width: '100%' }}>
                <Flex align="center" justify="between">
                  <Flex align="center">
                    <GoodsUserPrice
                      isHotDiscount
                      isRebuy={false}
                      direction="row"
                      data={data}
                    />
                  </Flex>
                  {render}
                </Flex>
                <HotdiscountTag className={styles.hotTag} data={data} />
              </div>
            )}
          </Flex>
        </Flex.Item>
      </Flex>
      {props.cartBtn && (
        <CartBtn cb={() => props.onClick(data)}>加入购物车</CartBtn>
      )}
    </div>
  )
}

// 一行三个商品
export const NormalGoodsSimple = ({
  data,
  titleKey = 'title',
  titleLine = 2,
  goodsIdsKey = 'goodsIds',
  className,
  isHotDiscount = false,
  showPoint = false,
  imgClassName,
  /** 是否有购买按钮 */
  isBuy = false,
  ...props
}) => {
  const [skuData, setSkuData] = useSetState({
    visible: false,
    gid: undefined
  })
  const status = data.activity_status
  const onCevent = async (from, index) => {
    if (from && index) {
      window.JAnalyticsCevent(from, 'number', Number(index) + 1)
    } else if (from) {
      window.JAnalyticsCevent(from)
    }
  }
  const goToDetail = () => {
    onCevent(props.from, props.index)
    if (isWebview) {
      invokeAppEvent('gotoLinkPage', {
        link_type: 3,
        outlink: {
          ids: data.ids
        }
      })
      return
    }
    history.push(
      +data.is_tbk
        ? `/goods/tbk?${goodsIdsKey}=${data.ids}`
        : `/goods/detail?${goodsIdsKey}=${data.ids}`
    )
  }
  const target =
    data &&
    data.activity_list &&
    data.activity_list.find(
      item => item.type === 'tbk_coupon' && +item.after_price
    )
  return (
    <>
      <div
        className={`${styles.normal} ${styles.normalSimple} ${
          className ? styles[className] : ''
        }`}
        onClick={() => goToDetail()}
        {...props}
      >
        <Flex direction="column">
          <GoodsImage
            className={`${styles.goodsImg} ${imgClassName}`}
            src={`${data.img}${ImageSize.normal}`}
            status={status}
          />
          <Flex.Item className={styles.body} style={{ width: '100%' }}>
            <Flex direction="column" align="start" className={styles.wordBody}>
              <Flex align="center" className={styles.mb8px}>
                <Title style={{ margin: 0 }} line={titleLine}>
                  {+data.is_tbk ? (
                    <img
                      className={styles.img}
                      alt="logo"
                      src={`${process.env.PUBLIC_URL}/images/tb.png`}
                    />
                  ) : null}
                  {data[titleKey]}
                </Title>
              </Flex>

              {+data.is_tbk ? (
                <>
                  <Flex className={styles.tbkPrice}>
                    <PriceTag
                      value={
                        target
                          ? target.after_price
                          : data.rank_price || data.original_price
                      }
                    />
                  </Flex>
                </>
              ) : (
                <Flex
                  style={{ width: '100%' }}
                  justify="between"
                  align="center"
                >
                  <GoodsUserPrice
                    isPrice
                    data={data}
                    isHotDiscount={isHotDiscount}
                  />
                  {isBuy ? (
                    <Flex
                      onClick={e => {
                        e.stopPropagation()
                        e.preventDefault()
                        setSkuData({ gid: data.ids, visible: true })
                      }}
                      align="center"
                      justify="center"
                      className={styles.buyIcon}
                    >
                      <Icon icon="icon-gowuche1" />
                    </Flex>
                  ) : null}
                </Flex>
              )}
            </Flex>
            {isHotDiscount && <HotdiscountTag data={data} />}
          </Flex.Item>
        </Flex>
      </div>
      <NewSku
        showType="buy"
        onCancel={() => setSkuData({ visible: false })}
        {...skuData}
      />
    </>
  )
}

/** 淘宝客商品列表 */
export const TbkGoodsItem = ({
  data,
  titleKey = 'title',
  titleLine = 2,
  goodsIdsKey = 'goodsIds',
  className,
  isHotDiscount = false,
  ...props
}) => {
  const onCevent = async (from, index) => {
    if (from && index) {
      window.JAnalyticsCevent(from, 'number', Number(index) + 1)
    } else if (from) {
      window.JAnalyticsCevent(from)
    }
  }
  const goToDetail = () => {
    onCevent(props.from, props.index)
    if (isWebview) {
      invokeAppEvent('gotoLinkPage', {
        link_type: 3,
        outlink: {
          ids: data.ids
        }
      })
      return
    }
    history.push(
      +data.is_tbk
        ? `/goods/tbk?${goodsIdsKey}=${data.ids}`
        : `/goods/detail?${goodsIdsKey}=${data.ids}`
    )
  }

  const target =
    data &&
    data.activity_list &&
    data.activity_list.find(
      item => item.type === 'tbk_coupon' && +item.after_price
    )

  return (
    <div
      className={`${styles.tbkGoodsItem} ${className ? styles[className] : ''}`}
      onClick={() => goToDetail()}
      {...props}
    >
      <Flex>
        <Image src={data.img} className={styles.tbkImg} />
        <Flex.Item className={styles.body} style={{ width: '100%' }}>
          <Flex direction="column" align="start" className={styles.wordBody}>
            <Flex align="start" className={styles.mb8px}>
              <Title style={{ margin: 0 }} line={titleLine}>
                {+data.is_tbk ? (
                  <img
                    className={styles.img}
                    alt="logo"
                    src={`${process.env.PUBLIC_URL}/images/tb.png`}
                  />
                ) : null}
                <span>{data[titleKey]}</span>
              </Title>
            </Flex>

            <Flex justify="start" className={styles.info}>
              <span>{saleCountFormat(data.csale)}人已购买</span>
            </Flex>

            <Flex className={styles.tbkPrice}>
              <PriceTag
                value={
                  target
                    ? target.after_price
                    : data.rank_price || data.original_price
                }
              />
              <RoleTag isRebuy={data.is_rebuy} data={data} />
            </Flex>
            <PriceTag type="dash" value={data.original_price} />
          </Flex>
        </Flex.Item>
      </Flex>
    </div>
  )
}

//noActivityTag 无活动标识
//cartBtn 按钮
//invalid 无效商品
export const TransverseGoods = ({
  data,
  titleKey = 'title',
  idsKey = 'ids',
  showPoint = false,
  ...props
}) => {
  const status = data.activity_status
  // data.is_flash_sale * 1 ? data.flash_sale_status : data.seckill_status
  return (
    <Link
      className={styles.transverse}
      to={
        +data.is_tbk
          ? `/goods/tbk?goodsIds=${data[idsKey]}`
          : `/goods/detail?goodsIds=${data[idsKey]}`
      }
    >
      <Flex className={styles.content}>
        <GoodsImage src={data.img} status={status} size="row" />
        <Flex.Item className={styles.rightContent}>
          <Flex
            style={{ height: '100%' }}
            direction="column"
            justify="between"
            align="start"
          >
            <div>
              <Title className={styles.title} line={2}>
                {+data.is_tbk ? (
                  <img
                    className={styles.img}
                    alt="logo"
                    src={`${process.env.PUBLIC_URL}/images/tb.png`}
                  />
                ) : null}
                {data[titleKey]}
              </Title>
              {showPoint && data.buy_something && (
                <span className={styles.point}>{data.buy_something}</span>
              )}
            </div>
            {props.invalid ? (
              <img
                className={styles.invalid}
                src={process.env.PUBLIC_URL + '/images/categorys/Invalid.png'}
                alt=""
              />
            ) : (
              <div>
                <Flex className={styles.bottom}>
                  {!props.noActivityTag && (
                    <ActivityTag data={data.activity_list} />
                  )}
                  <SellText count={data.csale} hide={data.is_new_products} />
                </Flex>
                <GoodsUserPrice data={data} />
              </div>
            )}
          </Flex>
        </Flex.Item>
      </Flex>
      {props.cartBtn && (
        <CartBtn cb={() => props.onClick(data)}>加入购物车</CartBtn>
      )}
    </Link>
  )
}

// 分类商品
//noActivityTag 无活动标识
//cartBtn 按钮
//invalid 无效商品
export const CategoryGoods = ({
  data,
  titleKey = 'title',
  idsKey = 'ids',
  showPoint = false,
  render,
  ...props
}) => {
  const status = data.activity_status
  return (
    <div
      className={styles.category}
      onClick={() => {
        history.push(
          +data.is_tbk
            ? `/goods/tbk?goodsIds=${data[idsKey]}`
            : `/goods/detail?goodsIds=${data[idsKey]}`
        )
      }}
    >
      <Flex align="start" className={styles.content}>
        <GoodsImage
          className={styles.img}
          src={data.img}
          status={status}
          size="row"
        />
        <Flex.Item className={styles.rightContent}>
          <Flex
            style={{ height: '100%' }}
            direction="column"
            justify="between"
            align="start"
          >
            <div>
              <Title
                className={`${styles.title} ${showPoint && styles.mb8}`}
                line={2}
                style={{ fontWeight: 'bold' }}
              >
                {+data.is_tbk ? (
                  <img
                    className={styles.img}
                    alt="logo"
                    src={`${process.env.PUBLIC_URL}/images/tb.png`}
                  />
                ) : null}
                {data[titleKey]}
              </Title>
              {showPoint && data.buy_something && (
                <span className={styles.point}>{data.buy_something}</span>
              )}
            </div>
            {props.invalid ? (
              <img
                className={styles.invalid}
                src={process.env.PUBLIC_URL + '/images/categorys/Invalid.png'}
                alt=""
              />
            ) : (
              <div style={{ width: '100%' }}>
                <Flex className={styles.bottom}>
                  {!props.noActivityTag && (
                    <ActivityTag data={data.activity_list} />
                  )}
                  <SellText count={data.csale} hide={data.is_new_products} />
                </Flex>
                <Flex align="center" justify="between">
                  <Flex align="center">
                    <GoodsUserPrice
                      isRebuy={false}
                      direction="row"
                      data={data}
                    />
                  </Flex>
                  {render}
                </Flex>
              </div>
            )}
          </Flex>
        </Flex.Item>
      </Flex>
      {props.cartBtn && (
        <CartBtn cb={() => props.onClick(data)}>加入购物车</CartBtn>
      )}
    </div>
  )
}

// 大图商品
export const FullWidthGoods = ({
  data,
  titleKey = 'title',
  goodsIdsKey = 'goodsIds'
}) => {
  const status = data.activity_status
  // data.is_flash_sale * 1 ? data.flash_sale_status : data.seckill_status
  return (
    <Link
      className={`${styles.normal} ${styles.fullWidth}`}
      to={
        +data.is_tbk
          ? `/goods/tbk?${goodsIdsKey}=${data.ids}`
          : `/goods/detail?${goodsIdsKey}=${data.ids}`
      }
    >
      <Flex direction="column">
        <GoodsImage src={data.img} size="fullwidth" status={status} />
        <Flex.Item className={styles.body} style={{ width: '100%' }}>
          <Flex direction="column" align="start">
            <Title line="1" className={styles.title}>
              {data[titleKey]}
            </Title>
            <Flex justify="start" className={styles.info}>
              <ActivityTag data={data.activity_list} />
              <SellText count={data.csale} hide={data.is_new_products} />
            </Flex>
            <GoodsUserPrice data={data} />
          </Flex>
        </Flex.Item>
      </Flex>
    </Link>
  )
}

/**
 * 常规商品横向滚动布局 eg: 商详页搭配推荐商品 /containers/GoodsDetail/components/GoodsCollocation
 */
export const HorizontalNormalGoods = ({
  data,
  titleKey = 'title',
  titleLine = 2,
  goodsIdsKey = 'goodsIds',
  isHotDiscount = false,
  className = '',
  ...props
}) => {
  const status = data.activity_status
  return (
    <Link
      className={`${styles.normal} ${styles.horizontal} ${className}`}
      to={
        +data.is_tbk
          ? `/goods/tbk?${goodsIdsKey}=${data.ids}`
          : `/goods/detail?${goodsIdsKey}=${data.ids}`
      }
      {...props}
    >
      <Flex direction="column">
        <GoodsImage
          src={`${data.img}${ImageSize.normal}`}
          size="arrival"
          status={status}
        />
        <Flex.Item className={styles.body} style={{ width: '100%' }}>
          <Flex direction="column" align="start" className={styles.wordBody}>
            <Title line={titleLine} className={styles.title}>
              {data[titleKey]}
            </Title>
            <Flex justify="start" className={styles.info}>
              <ActivityTag data={data.activity_list} />
              <SellText count={data.csale} hide={data.is_new_products} />
            </Flex>
            <GoodsUserPrice data={data} isHotDiscount={isHotDiscount} />
          </Flex>
          {isHotDiscount && <HotdiscountTag data={data} />}
        </Flex.Item>
      </Flex>
    </Link>
  )
}
