import React from 'react'
import { Link } from 'react-router-dom'
import { Toast } from 'antd-mobile'
import { createMemberCoupon } from '@/services/global'
import { stringify } from 'qs'

// { id: 1, name: '微页面' },
// { id: 2, name: '商品分组页' },
// { id: 3, name: '商品页面' },
// { id: 4, name: '购物车' },
// { id: 5, name: '直接购买' },
// { id: 6, name: '店铺主页' },
// { id: 7, name: '列表页' },
// { id: 8, name: '创业营' },
// { id: 9, name: '关于我们' },
// { id: 10, name: '供应商' },
// { id: 11, name: '优惠券' },
// { id: 16, name: '直播间' },
// { id: 17, name: '签到页面' },
// { id: 18, name: '直播频道' },
// { id: 99999, name: '自定义外链' },

// 获取链接url
export const getLinkPath = (type, outlink) => {
  switch (type * 1) {
    case 1:
      if (!outlink || !outlink.ids) return null
      return `/feature/${outlink.ids}`
    case 2:
      // 商品分组跳转 id对应二级分组ids ids对应三级分组ids
      if (!outlink || !outlink.ids || !outlink.id) return null
      return `/category/detail?secondGroupIds=${outlink.id}&groupIds=${outlink.ids}`
    case 3:
      if (!outlink || !outlink.ids) return null
      if (+outlink.is_tbk) {
        return `/goods/tbk?goodsIds=${outlink.ids}`
      } else {
        return `/goods/detail?goodsIds=${outlink.ids}`
      }
    case 4:
      return `/cart`
    case 5:
      return ''
    case 6:
      return `/`
    case 7:
      return `/category`
    case 8:
      return `/member/business_school`
    case 9:
      return `/mine/set/setAbout`
    case 10:
      return outlink ? `/supplier/${outlink.ids}` : ''
    case 11:
      return null
    case 14:
      if (!outlink || !outlink.ids) return null
      return `/limit-buy/${outlink.ids}`
    case 15:
      return `/live`
    case 16:
      if (!outlink || !outlink.ids) return `/live`
      return `/live/detail/${outlink.ids}`
    case 17:
      return `/zone/check`
    case 18:
      return `/discover/lives`
    case 19:
      return `/mine/point`
    // 新人专区
    case 20:
      return `/zone/new-buyer`
    // 爆款排行榜 拉新排行榜
    case 21:
    case 26:
      if (!outlink || !outlink.ids) return null
      return `/zone/hot-goods/${outlink.ids}`
    // 固定模版
    case 22:
      if (!outlink || !outlink.ids) return null
      return `/fixd-temp/${outlink.ids}`

    // 满减专区
    case 23:
      if (!outlink || !outlink.ids) return null
      return `/zone/discount/${outlink.ids}`
    // 一口价专区
    case 24:
      if (!outlink || !outlink.ids) return null
      return `/zone/buyout/${outlink.ids}`
    case 25:
      if (!outlink || !outlink.ids) return null
      return `/sales-promotion/${outlink.ids}`
    case 27:
      return '/topic/points-for-money'
    case 28:
      if (!outlink || !outlink.ids) return null
      return `/topic/gifts`
    case 29:
      return '/group-buy'
    case 30:
      return '/topic/gifts'
    case 31:
      return '/new/topic/lottery'
    case 34:
      return '/discover/mini-lives'
    default:
      return ''
  }
}

const getCoupon = async coupon => {
  Toast.info('正在领取优惠券...', null)
  const { type, msg } = await createMemberCoupon({ couponId: coupon.id })
  if (type === 1) return Toast.offline(msg, 2, null, false)
  Toast.success(msg, 1.5, null, false)
}

export default ({ type, outlink, children, query, ...props }) => {
  let url = getLinkPath(type, outlink)
  const isCoupon = type * 1 === 11
  const isAbslute = type * 1 === 99999
  const notNormalPath = isCoupon || isAbslute
  const isNewProject = url && url.startsWith('/new/')
  if (!notNormalPath)
    if (url) {
      if (query) url += `?${stringify(query)}`
      return (
        <Link to={url} {...props}>
          {children}
        </Link>
      )
    } else {
      return <div {...props}>{children}</div>
    }
  if (isCoupon)
    return (
      <div {...props} onClick={() => getCoupon(outlink)}>
        {children}
      </div>
    )
  if (isNewProject) {
    return (
      <a href={url} {...props}>
        {children}
      </a>
    )
  }
  if (isAbslute) {
    if (!outlink || !outlink.link) return children
    return (
      <a href={outlink.link} {...props}>
        {children}
      </a>
    )
  }
  return null
}
