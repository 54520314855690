import { videoActions } from '@/actions/videoActions'

const initialState = {
  loading: false,
  done: false,
  error: false,
  list: [],
  categorys: [],
  category_pos: 0,
  params: { page: 1, pageSize: 10, totalPage: 99999 },
  noMore: false,
  service: null,
  count: 99999
}

export default (state = initialState, { payload, type }) => {
  switch (type) {
    case videoActions.REQUEST:
      const { service, params } = payload
      const isReset = params.page * 1 === 1
      return {
        ...state,
        service,
        // params,
        list: isReset ? [] : state.list,
        loading: true,
        done: false,
        error: false
      }
    case videoActions.CATEGORYS:
      return {
        ...state,
        categorys: payload
      }
    case videoActions.SERVICE:
      return {
        ...state,
        service: payload
      }
    case videoActions.PARAMS:
      return {
        ...state,
        params: payload
      }
    case videoActions.DONE:
      return {
        ...state,
        list: [...state.list, ...payload],
        loading: false,
        done: true,
        error: false
      }
    case videoActions.ERROR:
      return {
        ...state,
        loading: false,
        done: false,
        error: payload
      }
    case videoActions.CATEGORY_POS:
      return {
        ...state,
        category_pos: payload
      }
    case videoActions.NOMORE:
      return {
        ...state,
        noMore: payload
      }
    case videoActions.COUNT:
      return {
        ...state,
        count: payload
      }
    default:
      return state
  }
}
