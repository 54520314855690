import React from 'react'
import styles from './styles.module.less'
export default function () {
    return (
        <div className={styles.page}>
            <div className={styles.icon}>
                <svg>
                    <use xlinkHref={`#icon-wechat`} />
                </svg>
            </div>
            <div className={styles.des}>请使用微信客户端打开</div>
        </div>
    )
}