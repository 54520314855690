const BUYOUT_LIST = 'buyout/BUYOUT_LIST'
const BUYOUT_PARAMS = 'buyout/BUYOUT_PARAMS'
const BUYOUT_POSITION = 'buyout/BUYOUT_POSITION'
const BUYOUT_NOMORE = 'buyout/BUYOUT_NOMORE'
const BUYOUT_INFO = 'buyout/BUYOUT_INFO'
const DISCOUNT_LIST = 'buyout/DISCOUNT_LIST'
const DISCOUNT_PARAMS = 'buyout/DISCOUNT_PARAMS'
const DISCOUNT_POSITION = 'buyout/DISCOUNT_POSITION'
const DISCOUNT_NOMORE = 'buyout/DISCOUNT_NOMORE'
const DISCOUNT_INFO = 'buyout/DISCOUNT_INFO'

export const buyoutActions = {
  BUYOUT_LIST,
  BUYOUT_PARAMS,
  BUYOUT_POSITION,
  BUYOUT_NOMORE,
  BUYOUT_INFO,
  DISCOUNT_LIST,
  DISCOUNT_PARAMS,
  DISCOUNT_POSITION,
  DISCOUNT_NOMORE,
  DISCOUNT_INFO
}

const updateBuyoutList = payload => ({
  type: BUYOUT_LIST,
  payload
})

const updateBuyoutParams = payload => ({
  type: BUYOUT_PARAMS,
  payload
})

const updateBuyoutPosition = payload => ({
  type: BUYOUT_POSITION,
  payload
})

const updateBuyoutNomore = payload => ({
  type: BUYOUT_NOMORE,
  payload
})
const updateBuyoutInfo = payload => ({
  type: BUYOUT_INFO,
  payload
})
const updateDiscountList = payload => ({
  type: DISCOUNT_LIST,
  payload
})

const updateDiscountParams = payload => ({
  type: DISCOUNT_PARAMS,
  payload
})

const updateDiscountPosition = payload => ({
  type: DISCOUNT_POSITION,
  payload
})

const updateDiscountNomore = payload => ({
  type: DISCOUNT_NOMORE,
  payload
})

const updateDiscountInfo = payload => ({
  type: DISCOUNT_INFO,
  payload
})

export {
  updateBuyoutList,
  updateBuyoutParams,
  updateBuyoutPosition,
  updateBuyoutNomore,
  updateBuyoutInfo,
  updateDiscountList,
  updateDiscountParams,
  updateDiscountPosition,
  updateDiscountNomore,
  updateDiscountInfo
}
