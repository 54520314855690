import { similarActions } from '@/actions/similarActions'

const initialState = {
  list: [],
  params: { page: 1, pageSize: 10 },
  position: 0,
  noMore: false,
  invalidGoods: {}
}
export default (state = initialState, { payload, type }) => {
  switch (type) {
    case similarActions.SIMILAR_LIST:
      return {
        ...state,
        list: payload
      }
    case similarActions.SIMILAR_PARAMS:
      return {
        ...state,
        params: payload
      }
    case similarActions.SIMILAR_POSITION:
      return {
        ...state,
        position: payload
      }
    case similarActions.SIMILAR_NOMORE:
      return {
        ...state,
        noMore: payload
      }
    case similarActions.SIMILAR_INVALID_GOODS:
      return {
        ...state,
        invalidGoods: payload
      }
    case similarActions.RESET_SIMILAR:
      return {
        list: [],
        params: { page: 1, pageSize: 10 },
        position: 0,
        noMore: false,
        invalidGoods: {}
      }
    default:
      return state
  }
}
