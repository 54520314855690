import { stringify } from 'qs'
import request from '@/utils/request'
import { serverApi } from '@/variable'

// 一级分组列表
export function getFirstGoodsGroupListV2(params) {
  return request(`${serverApi}/getFirstGoodsGroupListV2?${stringify(params)}`)
}

// 二级分组列表
export function getSecondGoodsGroupListV2(params) {
  return request(`${serverApi}/getSecondGoodsGroupListV2?${stringify(params)}`)
}

// 三级分组列表
export function getThirdGoodsGroupListV2(params) {
  return request(`${serverApi}/getThirdGoodsGroupListV2?${stringify(params)}`)
}

// 商品列表
export function getGoodsList(params) {
  return request(`${serverApi}/goodsList?${stringify(params)}`)
}

// 获取一级分组列表
export function getFirstGoodsGroupList(params) {
  return request(`${serverApi}/getFirstGoodsGroupList?${stringify(params)}`)
}
//获取二级分组列表
export function getSecondGoodsGroupList(params) {
  return request(`${serverApi}/getSecondGoodsGroupList?${stringify(params)}`)
}
// 获取会员中心信息
export function getThirdGoodsGroupList(params) {
  return request(`${serverApi}/getThirdGoodsGroupList?${stringify(params)}`)
}
