import { stringify } from 'qs'
import request from '@/utils/request'
import { serverApi, videoApi } from '@/variable'

// 获取地址列表信息
export function getAddressList(params) {
  return request(`${serverApi}/memberAddressList?${stringify(params)}`)
}

// 设置个人默认地址
export function setDefaultMemberAddress(params) {
  return request(`${serverApi}/setDefaultMemberAddress`, {
    method: 'POST',
    body: { ...params }
  })
}

// 设为最新选择地址
export function setLatestSelectedAddress(params) {
  return request(`${serverApi}/setLatestSelectedAddress`, {
    method: 'POST',
    body: { ...params }
  })
}

//删除个人中心地址
export function deleteMemberAddress(params) {
  return request(`${serverApi}/deleteMemberAddress`, {
    method: 'POST',
    body: { ...params }
  })
}

//获取地址省份信息
export function getProvinceList(params) {
  return request(`${serverApi}/getProvinceList?${stringify(params)}`)
}

//获取地址市级信息
export function getCityList(params) {
  return request(`${serverApi}/getCityList?${stringify(params)}`)
}

//获取地址区级信息
export function getAreaList(params) {
  return request(`${serverApi}/getAreaList?${stringify(params)}`)
}

//新增个人中心地址
export function createMemberAddress(params) {
  return request(`${serverApi}/createMemberAddress`, {
    method: 'POST',
    body: { ...params }
  })
}

//编辑 --获取地址详情
export function memberAddressDetail(params) {
  return request(`${serverApi}/memberAddressDetail?${stringify(params)}`)
}

//编辑 -- 提交地址信息
export function updateMemberAddress(params) {
  return request(`${serverApi}/updateMemberAddress`, {
    method: 'POST',
    body: { ...params }
  })
}

/** 收货地址智能识别 */
export function addressParsing(params) {
  return request(`${serverApi}/addressParsing`, {
    method: 'POST',
    body: { ...params }
  })
}

// 填写中奖地址
export function setLotteryAddress(params = {}) {
  return request(`${videoApi}/lottery/setAddress?${stringify(params)}`)
}

// 爆款活动选择收货地址
export function setActivityAddress(params) {
  return request(`${serverApi}/hotActivity/chooseAddress`, {
    method: 'POST',
    body: { ...params }
  })
}

// 修改订单收货地址
export function modifyOrderAddress(params) {
  return request(`${serverApi}/modifyOrderAddress`, {
    method: 'POST',
    body: { ...params }
  })
}

// 获取订单收货地址
export function getOrderAddress(params) {
  return request(`${serverApi}/getOrderAddress?${stringify(params)}`)
}
