import React from 'react'
import styles from './styles.module.less'
import { Button, WingBlank } from 'antd-mobile'
// import history from '@/routes/history'

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      hasError: false,
      errorInfo: null
    }
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, errorInfo: error }
  }

  componentDidCatch(error, info) {
    // You can also log the error to an error reporting service
    // console.log(error.stack, info)
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div className={styles.errorLoader}>
          <div className={styles.errorLoaderContent}>
            <WingBlank>
              <div className={styles.emojiIcon}>emmmm~</div>
              {/* <Icon className={styles.icon} type="cross-circle" /> */}
              <p>
                网络出现了点小问题
                <br /> 刷新页面试试?🙏
              </p>
              <Button onClick={() => window.location.reload(true)}>
                马上刷新
              </Button>
              <code className={styles.code}>
                {this.state.errorInfo.toString()}
              </code>
            </WingBlank>
          </div>
        </div>
      )
    }

    return this.props.children
  }
}
