const REQUEST = 'test/REQUEST';
const OK = 'test/OK';
const ERROR = 'test/ERROR';

export const testActions = {
    REQUEST,
    OK,
    ERROR,
};

const getTestRequest = () => ({
    type: REQUEST,
});

const getTestOk = payload => ({
    type: OK,
    payload,
});

const getTestError = payload => ({
    type: ERROR,
    payload,
});

const getTest = () => (dispatch) => {
    dispatch(getTestRequest());

    return setTimeout(() => {
        dispatch(getTestOk({ data: [11, 22, 33, 44], status: 200 }))
    }, 2000)
};

export {
    getTest,
    getTestError
};