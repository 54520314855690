const {
  NODE_ENV,
  SHOP_HOST,
  WECHAT_AUTH_PATH,
  HOST_API,
  HOST_HOUSEKEEP_API,
  HOST_VIDEO_API,
  HOST_NODE_API,
  HOST_MSG_API,
  STATIC_PATH,
  HTTP_STATIC_PATH,
  TRADE,
  PLUGIN_HOST
} = process.env
if (NODE_ENV === 'production') console.log = () => null

export default {
  HOST: SHOP_HOST,
  WECHAT_AUTH_PATH,
  HOST_API,
  HOST_HOUSEKEEP_API,
  HOST_VIDEO_API,
  HOST_NODE_API,
  HOST_MSG_API,
  STATIC_PATH,
  HTTP_STATIC_PATH,
  TRADE,
  PLUGIN_HOST
}
