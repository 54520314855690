import React from 'react'
import styles from '../arrival.module.less'

export default ({ data }) => {
  return (
    <div className={styles.deductbody}>
      <div className={styles.deduct}>积分抵</div>
      <div className={styles.text}>{data.integral_deduct_text}</div>
    </div>
  )
}
