const INTEGRAL_LIST = 'integral/INTEGRAL_LIST'
const INTEGRAL_PARAMS = 'integral/INTEGRAL_PARAMS'
const INTEGRAL_POSITION = 'integral/INTEGRAL_POSITION'
const INTEGRAL_NOMORE = 'integral/INTEGRAL_NOMORE'

export const integralActions = {
  INTEGRAL_LIST,
  INTEGRAL_PARAMS,
  INTEGRAL_POSITION,
  INTEGRAL_NOMORE
}

const updateIntegralList = payload => ({
  type: INTEGRAL_LIST,
  payload
})

const updateIntegralParams = payload => ({
  type: INTEGRAL_PARAMS,
  payload
})

const updateIntegralPosition = payload => ({
  type: INTEGRAL_POSITION,
  payload
})

const updateIntegralNomore = payload => ({
  type: INTEGRAL_NOMORE,
  payload
})

export {
  updateIntegralList,
  updateIntegralParams,
  updateIntegralPosition,
  updateIntegralNomore
}
