const OK = 'appEnv/OK'

export const appEnvActions = {
  OK,
};

const getAppEnvOK = payload => ({
  type: OK,
  payload
});

const getAppEnv = () => async dispatch => {
  if (window.wx && window.wx.miniProgram) {
    window.wx.miniProgram.getEnv(res => {
      //在小程序中
      if (res.miniprogram) {
        return dispatch(getAppEnvOK(true))
      }
    })
  } else {
    return dispatch(getAppEnvOK(false))
  }
}

export {
  getAppEnv
}