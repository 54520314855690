import { globalActions } from '@/actions/globalActions'

const initialState = {
  mobile_auth_visible: false,
  show_upgrade_page: null,
  show_newuser_package: false,
  show_newcoupons: false,
  show_homePage_modal: true,
  show_homePage_small_modal: true,
  show_download_app_modal: false,
  mine_bind_alert: false,
  download_app_modal_option: {},
  region: {
    data: [],
    loading: false,
    error: null
  }
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case globalActions.MOBILE_AUTH_VISIBLE:
      return {
        ...state,
        mobile_auth_visible: payload
      }
    case globalActions.SHOW_NEWUSER_PACKAGE:
      return {
        ...state,
        show_newuser_package: payload
      }
    case globalActions.SHOW_UPGRADE_PAGE:
      return {
        ...state,
        show_upgrade_page: payload
      }
    case globalActions.SHOW_NEWCOUPONS:
      return {
        ...state,
        show_newcoupons: payload
      }
    case globalActions.REGION_REQUEST:
      return {
        ...state,
        region: {
          ...state.region,
          loading: true
        }
      }
    case globalActions.REGION_OK:
      return {
        ...state,
        region: {
          ...state.region,
          loading: false,
          error: null,
          data: processRegin(payload)
        }
      }
    case globalActions.REGION_ERROR:
      return {
        ...state,
        region: {
          ...state.region,
          loading: false,
          error: payload
        }
      }
    case globalActions.HOMEPAGE_MODAL_VISIBLE:
      return {
        ...state,
        show_homePage_modal: false
      }
    case globalActions.HOMEPAGE_SMALL_MODAL_VISIBLE:
      return {
        ...state,
        show_homePage_small_modal: false
      }

    case globalActions.GLOBAL_DOWNLOADAPP_MODAL_VISIBLE:
      return {
        ...state,
        show_download_app_modal: payload.visible,
        download_app_modal_option: payload.option
      }
    case globalActions.MINE_BIND_ALERT:
      return {
        ...state,
        mine_bind_alert: payload
      }
    default:
      return state
  }
}

function processRegin(data) {
  return data.map(el => {
    const new_el = {
      value: el.id,
      label: el.name
    }
    if (el.children && !!el.children.length) {
      new_el.children = processRegin(el.children)
    }
    return new_el
  })
}
