import { initialState } from '@/reducers/listView'

const UPDATE = 'homePage/UPDATE'

const homePageActions = {
  UPDATE
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case homePageActions.UPDATE:
      return {
        ...state,
        ...payload
      }
    default:
      return state
  }
}
