import { getVideoCategorys } from '@/services/discover'

const GOODTHING_LIST = 'goodThing/GOODTHING_LIST'
const GOODTHING_PARAMS = 'goodThing/GOODTHING_PARAMS'
const GOODTHING_POSITION = 'goodThing/GOODTHING_POSITION'
const GOODTHING_NOMORE = 'goodThing/GOODTHING_NOMORE'
const GOODTHING_CATEGORYS = 'goodThing/GOODTHING_CATEGORYS'

export const goodThingActions = {
  GOODTHING_LIST,
  GOODTHING_PARAMS,
  GOODTHING_POSITION,
  GOODTHING_NOMORE,
  GOODTHING_CATEGORYS
}

const getGoodThingCategorys = () => async dispatch => {
  const { data, type } = await getVideoCategorys({ type: 1 })
  if (type === 1) return
  dispatch({ type: GOODTHING_CATEGORYS, payload: data })
}

const updateGoodThingList = payload => {
  return {
    type: GOODTHING_LIST,
    payload
  }
}

const updateGoodThingParams = payload => ({
  type: GOODTHING_PARAMS,
  payload
})

const updateGoodThingPosition = payload => ({
  type: GOODTHING_POSITION,
  payload
})

const updateGoodThingNomore = payload => ({
  type: GOODTHING_NOMORE,
  payload
})

export {
  updateGoodThingList,
  updateGoodThingParams,
  updateGoodThingPosition,
  updateGoodThingNomore,
  getGoodThingCategorys
}
