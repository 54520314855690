import Mock from 'better-mock'

Mock.sendRequest = true
// mock方法,详细的可以看官方文档
const Random = Mock.Random

export default [
  {
    url: '/server/api/market/getOneTenHousandGroup1',
    type: 'get',
    response: () => {
      return {
        type: 0,
        msg: '操作成功',
        data: {
          activity_info: {
            ids: '514617515',
            preloading_start_at: '2021-03-16 09:09:59',
            type: '13',
            show_explain: '爆款直降',
            activity_desc: 'fasdadad',
            end_at: '2021-03-30 23:59:00',
            start_at: '2021-03-29 00:00:00',
            goods_price: '199.00',
            goods_ids: '523845631',
            activity_price: '99.00',
            group_buying_number: '',
            goods_title: '爆款直降（复购）',
            goods_csale: '24',
            goods_img:
              'https://img.jinse.cn/jinse_1646031716639602808_small.png',
            group_buying_status: 0
          },
          share_title: '',
          share_content: '',
          share_img: 'https://img.jinse.cn/jinse_1646031716639602808_small.png',
          other_activity_status: 0,
          remind_count: 1890,
          remind_avatars: [],
          participate_group_count: 970,
          participate_group_avatars: []
        }
      }
    }
  },
  {
    url: '/server/api/market/getGroupDetail1',
    type: 'get',
    response: () => {
      return {
        type: 0,
        msg: '操作成功',
        data: {
          ids: '611942086',
          pre_start_at: '2022-03-10 00:00:00',
          type: '14',
          show_explain: '',
          activity_desc: '',
          activity_img: 'upload/2020-05-27/uaddCfBGsWRPBYn1TYGbkm9mWMrv.png',
          promotion_rules:
            '{"group_buying_count":"3","group_buying_show":"1","simulate_group_buying":{"enable":"0","type":1,"count":0}}',
          end_at: '2022-03-26 23:59:00',
          preloading_start_at: '2022-03-10 09:16:48',
          start_at: '2022-03-10 00:00:00',
          goods_price: null,
          goods_ids: null,
          activity_price: '58.23',
          group_buying_number: '',
          goods_title: null,
          goods_csale: null,
          goods_img: 'upload/2020-05-27/uaddCfBGsWRPBYn1TYGbkm9mWMrv.png',
          success_group_count: '0',
          group_list: [
            {
              group_avatars:
                'upload/2020-05-27/uaddCfBGsWRPBYn1TYGbkm9mWMrv.png',
              countdown: 1000,
              remind_number: 1
            },
            {
              group_avatars:
                'upload/2020-05-27/uaddCfBGsWRPBYn1TYGbkm9mWMrv.png',
              countdown: 1000,
              remind_number: 2
            },
            {
              group_avatars:
                'upload/2020-05-27/uaddCfBGsWRPBYn1TYGbkm9mWMrv.png',
              countdown: 1000,
              remind_number: 3
            }
          ],
          share_title: '',
          share_content: '',
          share_img: '',
          group_buying_status: 1
        }
      }
    }
  },
  {
    url: '/server/api/market/getGroupBuyingList1',
    type: 'get',
    response: () => {
      /* let fileList = []
      for (let i = 0; i < 6; i++) {
        let file = {}
        file.uid = Random.id()
      } */
      return {
        data: {
          _list: [
            {
              goods_ids: '611939853',
              goods_title: '商品首次上架测试',
              goods_csale: '1',
              goods_img: 'upload/2020-05-27/uaddCfBGsWRPBYn1TYGbkm9mWMrv.png',
              goods_price: '3.00',
              activity_price: '666.00',
              activity_ids: '514617475',
              activity_type: '13',
              start_at: '2021-03-17 00:00:00',
              group_buying_status: 0,
              group_date_text: '拼团'
            },
            {
              goods_ids: '611939853',
              goods_title: '商品首次上架测试',
              goods_csale: '1',
              goods_img: 'upload/2020-05-27/uaddCfBGsWRPBYn1TYGbkm9mWMrv.png',
              goods_price: '3.01',
              activity_price: '666.01',
              activity_ids: '514617475',
              activity_type: '13',
              start_at: '2021-03-17 00:00:00',
              group_buying_status: 1,
              group_date_text: '拼团'
            },
            {
              goods_ids: '611939853',
              goods_title: '商品首次上架测试',
              goods_csale: '1',
              goods_img: 'upload/2020-05-27/uaddCfBGsWRPBYn1TYGbkm9mWMrv.png',
              goods_price: '3.01',
              activity_price: '666.01',
              activity_ids: '514617475',
              activity_type: '13',
              start_at: '2021-03-17 00:00:00',
              group_buying_status: 2,
              group_date_text: '拼团'
            },
            {
              goods_ids: '611939853',
              goods_title: '商品首次上架测试',
              goods_csale: '1',
              goods_img: 'upload/2020-05-27/uaddCfBGsWRPBYn1TYGbkm9mWMrv.png',
              goods_price: '3.01',
              activity_price: '666.01',
              activity_ids: '514617475',
              activity_type: '13',
              start_at: '2021-03-17 00:00:00',
              group_buying_status: 3,
              group_date_text: '拼团'
            }
          ],
          _page: {
            page: 1,
            pageSize: 10,
            totalPage: 1,
            totalCount: '1'
          }
        },
        type: 0,
        msg: '操作成功'
      }
    }
  },
  {
    // 商品详情
    url: '/server/api/goodsDetail1',
    type: 'get',
    response: () => {
      return {
        type: 0,
        msg: '操作成功11',
        server_time: 1634658200,
        data: {
          id: '1703',
          ids: '611939853',
          goods_category_ids: '523845627',
          title:
            '分账退款[JJ.FOX]简约轻奢宽边蕾丝蝴蝶结贴服包臀抗菌纯棉内裆女士内裤 3条装 JFML33722',
          img: 'upload/2021-04-12/vzPuWW75LsP6VIrHiH5xVilitVdF.jpg',
          activity_img: '',
          price: '0.01',
          floor_price: '100.00',
          ceiling_price: '100.00',
          stock: 198,
          cquota: 0,
          csale: '19',
          base_csale: '0',
          volume: '0.000',
          weight: '0.000',
          onsale: '1',
          wait_onsale: '0',
          is_deleted: 0,
          discount_percent:
            '{"member":100,"vip":"0","shopowner":"0","senior_shopowner":"0","super_shopowner":"0"}',
          supplier_ids: '611936166',
          file_id: '',
          is_rebuy: 0,
          without_show_match: '0',
          show_start_time: '0000-00-00 00:00:00',
          show_end_time: '0000-00-00 00:00:00',
          sales_target: '0',
          new_products_status: '0',
          platform_type: '0',
          size_template_ids: '0',
          cquota_start_at: '0000-00-00 00:00:00',
          cquota_end_at: '0000-00-00 00:00:00',
          is_invoice: '1',
          customer_service_type: '0',
          goods_img: [
            {
              id: '188350',
              goods_ids: '611939853',
              img: 'upload/2021-04-12/vzPuWW75LsP6VIrHiH5xVilitVdF.jpg'
            }
          ],
          goods_product: [
            {
              id: '174464',
              ids: '611939876',
              goods_ids: '611939853',
              price: '100.00',
              original_price: '0.01',
              stock: '198',
              props: '524544643',
              props_str: '1',
              img: 'upload/2021-04-12/vzPuWW75LsP6VIrHiH5xVilitVdF.jpg',
              csale: '0',
              special_instructions: ''
            }
          ],
          goods_prop: [
            {
              id: '122324',
              goods_ids: '611939853',
              prop_ids: '524544642',
              prop_name: '预售优惠券',
              prop_value_ids: '524544643',
              prop_value_name: '1',
              prop_main: '0',
              prop_main_img: ''
            }
          ],
          goods_prop_main_ids: 0,
          goods_service: [
            {
              service_ids: '33156',
              title: '7天无理由退换货1111111',
              promise:
                '客户自收到快递起七天内，订单未确认收货的，且在产品不影响二次销售的前提下，可以进行7天无理由退换货。',
              icon: 'upload/2019-04-23/nUbY67QyJBaKpRJZ5SDYSWkDoqXU.png'
            },
            {
              service_ids: '33157',
              title: '顺丰包邮',
              promise: '本款商品可享受顺丰包邮服务，快速可靠，服务放心。',
              icon: 'upload/2019-04-23/nUbY67QyJBaKpRJZ5SDYSWkDoqXU.png'
            },
            {
              service_ids: '33170',
              title: '极速退换',
              promise:
                '客户如需退换货，平台会在收到退回件后72小时内完成审核确认并进行退款处理，换货产品会在72小时内打包发出。如遇特殊情况，售后人员会主动电联告知。',
              icon: 'upload/2019-04-23/nUbY67QyJBaKpRJZ5SDYSWkDoqXU.png'
            },
            {
              service_ids: '314674438',
              title: '关于售后',
              promise: '特殊商品不支持退换货',
              icon: 'upload/2019-08-12/g0jP6oSLOuXuKsXKeNpWGXwmNXHS.png'
            },
            {
              service_ids: '33169',
              title: '品质保障',
              promise:
                '平台产品从选品上架到发货售后，拥有专业的质检团队和售后人员，严格把控，保障品质与服务。',
              icon: 'upload/2019-04-23/nUbY67QyJBaKpRJZ5SDYSWkDoqXU.png'
            },
            {
              service_ids: '33171',
              title: '售后无忧',
              promise:
                '自收到产品后15日内，如产品遇到非人为原因的售后问题，平台可免费提供维修护理。',
              icon: 'upload/2019-04-23/nUbY67QyJBaKpRJZ5SDYSWkDoqXU.png'
            }
          ],
          video_url: '',
          intro: '',
          description: '<p>4658</p>',
          rebuy_discount: '1.00',
          specs_price: '100.00',
          member_rank: 0,
          original_price: '0.01',
          product_price: '100.00',
          rank_price: '100.00',
          limitArea: ['澳门特别行政区', '香港特别行政区', '台湾', '海南省'],
          is_new_products: 0,
          goods_match_relation: '0',
          match_recommend_img: [],
          follow_up_actions: [],
          seckill_status: 0,
          discount: [],
          flash_sale_info: null,
          is_flash_sale: 0,
          buyout_price_activity_info: null,
          newborn_zone_info: null,
          straight_down: null,
          is_straight_down: 1, // 是否爆款直降 0否 1是
          coupon: [
            {
              id: '164',
              ids: '536716850',
              name: '测试',
              content_type: '1',
              condition_amount: '0.00',
              coupon_amount: '10.00',
              condition_client: '0',
              total_count: '0',
              pre_start_time: '2021-10-20 00:00:00',
              pre_end_time: '2023-10-28 23:59:59',
              pre_start_timestamp: '1634659200',
              pre_end_timestamp: '1698508799',
              start_time: '2021-10-20 00:00:00',
              end_time: '2023-10-28 23:59:59',
              get_rule: '0',
              get_num: '0',
              type: '0',
              type_desc: '',
              use_goods_type: '0',
              use_time_type: '0',
              use_day: '0',
              status: '0',
              send_num: '0',
              used_num: '0',
              description: '',
              created_at: '2021-10-20 16:07:44',
              updated_at: '2021-10-20 16:10:31',
              get_type: '0'
            }
          ],
          is_advance_sale: 0,
          group_buying_info: {
            activity_price: 20,
            activity_price_text: '.52起',
            activity_status: 1,
            show_date_text: '今日8:00开始',
            show_text: '2人团',
            is_remind: 1,
            start_time_timestamp: '1634659200',
            end_time_timestamp: '1634659200'
          },
          activity_status: 0,
          activity_price: 0,
          is_collect: 0,
          supplier_info: {
            ids: '611936166',
            name: '祝璞测试供应商',
            stores_logo: '',
            stores_image: '',
            on_sale_num: 2
          },
          video_cover_url: 'upload/2021-04-12/vzPuWW75LsP6VIrHiH5xVilitVdF.jpg',
          live_ids: 0,
          video_data: { list: [], count: 0 },
          is_general_goods: 1,
          goods_match_relation_list: [],
          advertising_detail: [
            {
              id: '57',
              ids: '526064813',
              image: 'upload/2020-11-10/BNRFGrC29UfApI9XaDyopsuP06Ni.jpg',
              link_type: 8,
              outlink: { name: '', id: '', ids: '', link: '' },
              title: 'app',
              start_at: '0000-00-00 00:00:00',
              end_at: '0000-00-00 00:00:00',
              show_position: '0',
              goods_type: '0',
              goods_ids_array: []
            }
          ],
          promotion_info: null,
          coupon_share_activity_info: null,
          integral_deduct_text: ''
        }
      }
    }
  },
  {
    url: '/server/api/orderList1',
    type: 'get',
    response: () => {
      let fileList = []
      for (let i = 0; i < 3; i++) {
        let file = {
          amount: '1.00',
          created_at: '2022-03-15 10:31:27',
          id: Random.id(),
          is_evaluate: 0,
          list: [
            {
              advance_sale_ids: '0',
              created_at: '2022-03-15 10:31:27',
              difference_price: '0.00',
              goods_img: 'upload/2020-08-25/p4ojg1oYQLyswofXpn5v89FbroRn.png',
              goods_name: '预售1修改',
              group_buy_info: {
                status: 20,
                title: '【拼购中】',
                text: '还剩1人拼团成功',
                market_type: 4
              },
              id: '534',
              is_advance_sale: 0,
              is_evaluate: '0',
              is_refund_close: 0,
              order_goods_ids: '611942122',
              order_ids: '611942121',
              pay_price: '1.00',
              price: '200.00',
              props: '321',
              quantity: '1',
              refund_quantity: '0',
              refund_success_quantity: '0',
              status: '15'
            }
          ],
          order_id: '406',
          order_ids: '611942121',
          quantity: '1',
          quantityCount: 1,
          status: '15',
          totalCount: 200
        }
        fileList.push(file)
      }
      return {
        type: 0,
        msg: '操作成功',
        data: {
          type: 1,
          title: '标题111',
          _list: fileList,
          _page: {
            page: 1,
            pageSize: 10,
            totalCount: '397',
            totalPage: 40
          }
        }
      }
    }
  },
  {
    url: '/server/api/orderDetail1',
    type: 'get',
    response: () => {
      return {
        data: {
          member_ids: '510717424',
          id: '407',
          amount: '1',
          balance_amount: '0.00',
          consignee: '\u9884\u8b66\u5730\u5740',
          mobile: '11888888888',
          is_evaluate: 0,
          province_name: '\u5317\u4eac',
          city_name: '\u5317\u4eac\u5e02',
          district_name: '\u660c\u5e73\u533a',
          address:
            '\u9999\u6e2f\u597d\u597d\u53d1\u7684\u641c\u72d0\u53d1\u90fd\u662f\u4f5b\u7231\u4e0a',
          created_at: '2022-03-15 13:44:06',
          status: '20',
          shipment_fee: '10.00',
          order_sn: '202203151344204020',
          goods_amount: '100.00',
          payment_code: 'balance',
          payment_sn: '202203150000000006',
          pay_at: '2022-03-15 13:44:09',
          member_remark: '',
          close_type: '0',
          close_reason: '\u9000\u6b3e',
          refund_status: '',
          peerpay: '0',
          pay_integral: '0.00',
          finished_at: '0000-00-00 00:00:00',
          type: '1',
          market_type: '2',
          market_ids: '611942086',
          order_type: 1,
          integral_deduction_amount: '0.00',
          orderGoods: [
            {
              id: '535',
              ids: '611942125',
              order_ids: '611942124',
              goods_name: '\u9884\u552e1\u4fee\u6539',
              refund_success_quantity: '0',
              is_evaluate: 0,
              difference_price: '0.00',
              goods_img: 'upload/2020-08-25/p4ojg1oYQLyswofXpn5v89FbroRn.png',
              quantity: '1',
              price: '100.00',
              props: '1',
              status: '20',
              goods_product_ids: '525243745',
              goods_ids: '525243742',
              extend_days: '0',
              refund_quantity: '0',
              finished_at: '0000-00-00 00:00:00',
              receipt_at: '0000-00-00 00:00:00',
              pay_price: '1.00',
              market_ids: '611942086',
              market_type: '8',
              pay_integral: '0.00',
              shipment_timestamp: '0',
              advance_sale_ids: '0',
              earnest_money: '0.00',
              is_refund_close: '0',
              order_goods_ids: '611942125',
              integral_deduction_percent: '0.00',
              is_discount: 0,
              is_seckill: 0,
              is_flash_sale: 0,
              is_buyout_price_activity: 0,
              is_advance_sale: 0,
              special_instructions: ''
            }
          ],
          package: null,
          order_ids: '611942124',
          auto_close_time: 0,
          auto_shipment_time: 0,
          auto_finish_time: 0,
          auto_show_text: '',
          new_auto_show_text: '',
          GoodsUmp: null,
          refund_id: '',
          payment_name: '\u4f59\u989d\u652f\u4ed8',
          pay: [
            {
              payment_code: 'balance',
              payment_name: '\u4f59\u989d\u652f\u4ed8',
              payment_sn: '202203150000000006',
              pay_at: '2022-03-15 13:44:09'
            }
          ],
          advance_amount: 0,
          tail_amount: '1.00',
          is_invoice: 0,
          order_invoice_apply_ids: null,
          customer_service_type: '0',
          order_delete_cancel_config: 0,
          after_sale_config: 0,
          initiative_refund_list: [],
          total_initiative_refund_money: 0,
          is_all_initiative_refund: 0,
          is_refund_close: '0',
          get_integral_text: '',
          is_finish_refund: 0,
          group_buy_info: {
            ids: 111,
            status: 10,
            title: '【拼购中】',
            text: '还剩1人拼团成功',
            market_type: 3,
            avatar: 'upload/2020-08-25/p4ojg1oYQLyswofXpn5v89FbroRn.png'
          }
        },
        type: 0,
        msg: '\u64cd\u4f5c\u6210\u529f',
        server_time: 1647408048
      }
    }
  },
  {
    url: '/server/api/submitOrder1',
    type: 'POST',
    response: () => {
      return {
        type: 0,
        data: {
          address: {
            id: '收货地址id',
            ids: '收货地址ids',
            consignee: '收货人',
            mobile: '手机号',
            province: '省id',
            city: '市id',
            district: '区id',
            city_name: '城市名称',
            province_name: '省份名称',
            district_name: '地区名称',
            address: '详细地址',
            zipcode: '邮编'
          },
          orderGoods: [
            {
              goodsId: '商品id',
              goodsIds: '商品ids',
              goodsProductId: '商品规格id',
              goodsProductIds: '商品规格ids',
              price: '商品规格价格',
              goodsName: '商品名称',
              propsStr: '商品规格',
              quantity: '商品数量',
              img: '商品图片',
              is_newborn_zone: '是否是新人专享',
              is_advance_sale: '是否是预售',
              special_instructions: '特殊说明',
              is_gifts: '是否有赠品'
            }
          ],
          discount: [
            {
              id: '满减活动id',
              ids: '满减活动ids',
              discount: {
                fullMoney: '满多少',
                subtractMoney: '减多少'
              }
            }
          ],
          memberCoupon: [
            {
              id: '优惠券id',
              ids: '优惠券ids',
              coupon_val: '优惠券金额',
              coupon_ids: '',
              name: '优惠券名称',
              coupon_info: {
                condition_amount: '500.00'
              },
              start_time: '有效期开始时间',
              end_time: '有效期结束时间',
              coupon_type: '1',
              type: '0',
              description: '优惠券描述',
              created_at: '领取时间'
            }
          ],
          gifts: [
            {
              ids: '赠品ids',
              goodsName: '赠品名称',
              img: '赠品图片',
              quantity: '赠品数量'
            }
          ],
          orderPayAmount: '订单总金额',
          goodsAmount: '商品金额',
          discountAmount: '满减优惠',
          couponAmount: '优惠券',
          freight: '运费',
          isDeduction: '是否余额抵扣',
          buyoutPriceDiscountAmount: '一口价优惠金额',
          newbornZoneDiscountAmount: '新人专享优惠金额',
          integralAmount: '订单积分总金额'
        },
        msg: 'success'
      }
    }
  }
]
