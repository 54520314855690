import React from 'react'
import styles from './title.module.less'

export default function Title({
  children,
  size = 'normal',
  line = 2,
  className = '',
  style = {}
}) {
  return (
    <div
      className={`${styles.title} ${styles[size]} ${
        styles['cuteline' + line]
      } ${className}`}
      style={style}
    >
      {children}
    </div>
  )
}
