import { Modal } from 'antd-mobile'
import { isAndroid, isIos, isWebview } from '@/variable'

export function invokeAppEvent(methodName, options) {
  if (!isWebview) return
  if (isAndroid) return invokeAndroidEvent(methodName, options)
  if (isIos) return invokeIosEvent(methodName, options)
}

export function invokeAndroidEvent(methodName, option) {
  try {
    if (window.android && window.android[methodName]) {
      return option
        ? window.android[methodName](
            typeof option === 'object' ? JSON.stringify(option) : option
          )
        : window.android[methodName]()
    }
  } catch (err) {
    invokeAppError(err)
  }
}

export function invokeIosEvent(methodName, option) {
  try {
    if (window.webkit) {
      return window.webkit.messageHandlers[methodName].postMessage(option)
    }
  } catch (error) {
    invokeAppError(error)
  }
}

export function compareVersion(curV, targetV) {
  if (curV && targetV) {
    //将两个版本号拆成数字
    var arr1 = curV.split('.'),
      arr2 = targetV.split('.')
    var minLength = Math.min(arr1.length, arr2.length),
      position = 0,
      diff = 0
    //依次比较版本号每一位大小，当对比得出结果后跳出循环
    while (
      position < minLength &&
      (diff = parseInt(arr1[position]) - parseInt(arr2[position])) === 0
    ) {
      position++
    }
    diff = diff !== 0 ? diff : arr1.length - arr2.length
    //若curV大于targetV，则返回true
    return diff >= 0
  } else {
    //输入为空
    console.log('版本号不能为空')
    return false
  }
}

function invokeAppError(e) {
  Modal.alert(
    '提示',
    `当前版本不支持该功能，请更新app后再来哦～${
      process.env.REACT_APP_ENV !== 'production' ? JSON.stringify(e) : ''
    }`
  )
}
