import React from 'react'
import styles from '../sell.module.less'

/**
 * activity_status 0:无活动 1: 未开始 2：预热中 3:进行中 4:已结束 5:已抢完 6:有机会
 */

export default ({ data, className }) => {
  const title =
    data.activity_status * 1 === 0 ||
    data.activity_status * 1 === 1 ||
    data.activity_status * 1 === 2
      ? '即将开抢'
      : data.activity_status * 1 === 3
      ? '立即抢购'
      : data.activity_status * 1 === 4
      ? '已结束'
      : data.activity_status * 1 === 5
      ? '已抢完'
      : data.activity_status * 1 === 6
      ? '有机会'
      : ''

  const tagBackground =
    data.activity_status * 1 === 5
      ? process.env.PUBLIC_URL + '/images/home/hotdiscount_graygoods.png'
      : process.env.PUBLIC_URL + '/images/home/hotdiscount_goods.png'
  return (
    <div
      className={`${styles.hotGoodsTag} ${className}`}
      style={{ backgroundImage: `url(${tagBackground})` }}
    >
      <div
        className={`${styles.left} ${
          data.activity_status * 1 === 5 ? styles.grayleft : ''
        }`}
      >
        <span>直降</span>
        <span>¥{data.discounts_price}</span>
      </div>
      <div className={styles.right}>{title}</div>
    </div>
  )
}
