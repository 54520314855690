import { goodThingActions } from '@/actions/goodThingActions'

const initialState = {
  list: [],
  params: { page: 1, pageSize: 10, type: 2 },
  position: 0,
  noMore: false,
  categorys: []
}
export default (state = initialState, { payload, type }) => {
  switch (type) {
    case goodThingActions.GOODTHING_LIST:
      return {
        ...state,
        list: payload
      }
    case goodThingActions.GOODTHING_PARAMS:
      return {
        ...state,
        params: payload
      }
    case goodThingActions.GOODTHING_POSITION:
      return {
        ...state,
        position: payload
      }
    case goodThingActions.GOODTHING_NOMORE:
      return {
        ...state,
        noMore: payload
      }
    case goodThingActions.GOODTHING_CATEGORYS:
      return {
        ...state,
        categorys: payload
      }
    default:
      return state
  }
}
