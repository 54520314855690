import { supplierActions } from '@/actions/supplierActions'

const initialState = {
  info: {},
  infoLoading: false,
  infoDone: false,
  infoError: false,
  first: true,
  loading: false,
  error: false,
  done: false,
  list: [],
  scrollTop: 0,
  params: {
    sortBy: 'weight-desc',
    page: 1
  }
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case supplierActions.INFO_REQUEST:
      return {
        ...state,
        infoDone: false,
        infoError: false,
        infoLoading: true
      }
    case supplierActions.INFO_OK:
      return {
        ...state,
        info: payload,
        infoDone: true,
        infoError: false,
        infoLoading: false
      }
    case supplierActions.INFO_ERROR:
      return {
        ...state,
        infoDone: false,
        infoError: true,
        infoLoading: false
      }
    case supplierActions.REQUEST:
      return {
        ...state,
        done: false,
        params: payload,
        list:
          payload.page === 1 || payload.page === undefined ? [] : state.list,
        error: false,
        loading: true
      }
    case supplierActions.SCROLLTOP:
      return {
        ...state,
        scrollTop: payload
      }
    case supplierActions.OK:
      return {
        ...state,
        first: false,
        list:
          payload._page.page === 1
            ? payload._list
            : [...state.list, ...payload._list],
        params: {
          ...state.params,
          page: payload._page.page
        },
        loading: false,
        done: true
      }
    case supplierActions.ERROR:
      return {
        ...state,
        done: true,
        loading: false,
        error: payload
      }
    default:
      return state
  }
}
