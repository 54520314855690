import {
  showNewUserPackage,
  getNewCoupons,
  getSubRegionsTree
} from '@/services/global'

const MOBILE_AUTH_VISIBLE = 'global/MOBILE_AUTH_VISIBLE'
const SHOW_UPGRADE_PAGE = 'global/SHOW_UPGRADE_PAGE'
const SHOW_NEWUSER_PACKAGE = 'global/SHOW_NEWUSER_PACKAGE'
const SHOW_NEWCOUPONS = 'global/SHOW_NEWCOUPONS'
const REGION_REQUEST = 'global/REGION_REQUEST'
const REGION_OK = 'global/REGION_OK'
const REGION_ERROR = 'global/REGION_ERROR'
const HOMEPAGE_MODAL_VISIBLE = 'global/HOMEPAGE_MODAL_VISIBLE'
const HOMEPAGE_SMALL_MODAL_VISIBLE = 'global/HOMEPAGE_SMALL_MODAL_VISIBLE'
const GLOBAL_DOWNLOADAPP_MODAL_VISIBLE =
  'global/GLOBAL_DOWNLOADAPP_MODAL_VISIBLE'
const MINE_BIND_ALERT = 'global/MINE_BIND_ALERT'

export const globalActions = {
  MOBILE_AUTH_VISIBLE,
  SHOW_UPGRADE_PAGE,
  SHOW_NEWUSER_PACKAGE,
  SHOW_NEWCOUPONS,
  REGION_REQUEST,
  REGION_OK,
  REGION_ERROR,
  HOMEPAGE_MODAL_VISIBLE,
  HOMEPAGE_SMALL_MODAL_VISIBLE,
  GLOBAL_DOWNLOADAPP_MODAL_VISIBLE,
  MINE_BIND_ALERT
}

const setGlobalDownloadAppVisible = (visible, option) => ({
  type: GLOBAL_DOWNLOADAPP_MODAL_VISIBLE,
  payload: { visible, option }
})

const setMobileAuthVisible = payload => ({
  type: MOBILE_AUTH_VISIBLE,
  payload
})

const setShowUpgradePage = payload => ({
  type: SHOW_UPGRADE_PAGE,
  payload
})

const setShowNewUserPackage = payload => ({
  type: SHOW_NEWUSER_PACKAGE,
  payload
})

const setShowNewCoupons = payload => ({
  type: SHOW_NEWCOUPONS,
  payload
})

const setMineBindAlert = payload => ({
  type: MINE_BIND_ALERT,
  payload
})

const getNewUserPackage = () => async dispatch => {
  try {
    const t = Date.parse(new Date())
    const { data, type } = await showNewUserPackage({ t })
    if (type !== 1 && data.isReach * 1 && data.img) {
      dispatch(setShowNewUserPackage(data.img))
    }
  } catch (error) {
    console.log(`new user package error`, error)
  }
}

const getNewCouponList = () => async dispatch => {
  try {
    const { data } = await getNewCoupons()
    if (data && data.length * 1 !== 0) {
      dispatch(setShowNewCoupons(data))
    }
  } catch (error) {
    console.log(`new coupons error`, error)
  }
}

const getShowUpgradePage = params => async dispatch => {
  try {
    dispatch(setShowUpgradePage(params))
  } catch (error) {
    dispatch(setShowUpgradePage(false))
  }
}

const getRegionRequest = payload => ({
  type: REGION_REQUEST,
  payload
})
const getRegionOk = payload => ({
  type: REGION_OK,
  payload
})
const getRegionError = payload => ({
  type: REGION_ERROR,
  payload
})
const getRegion = () => async dispatch => {
  getRegionRequest(true)
  try {
    const { data, type, msg } = await getSubRegionsTree()
    if (type === 1) return dispatch(getRegionError(msg))
    dispatch(getRegionOk(data))
  } catch (error) {
    dispatch(getRegionError('服务器未响应...'))
  }
}

const closeHomePageModal = payload => ({
  type: HOMEPAGE_MODAL_VISIBLE,
  payload
})

const closeHomePageSmallModal = payload => ({
  type: HOMEPAGE_SMALL_MODAL_VISIBLE,
  payload
})

export {
  setGlobalDownloadAppVisible,
  setMobileAuthVisible,
  getShowUpgradePage,
  setShowNewUserPackage,
  getNewUserPackage,
  getNewCouponList,
  getRegion,
  closeHomePageModal,
  closeHomePageSmallModal,
  setMineBindAlert
}
