import React, { useState, useEffect } from 'react'
import { bindDistrbutor } from '@/services/global'
import { ActivityIndicator } from 'antd-mobile'
import FullScreenCenter from '@/components/Flex/FullScreenCenter'
import FullScreenCenterInfo from '../Flex/FullScreenCenterInfo'
import Entry from './Entry'

const LoadingComp = () => (
  <FullScreenCenter>
    <ActivityIndicator text="信息校验中..." />
  </FullScreenCenter>
)

const useBindParent = mids => {
  const [err, setErr] = useState(false)
  const [loading, setLoading] = useState(mids * 1 > 0)

  useEffect(() => {
    const bind = async () => {
      try {
        await bindDistrbutor({ mids })
        setLoading(false)
      } catch (error) {
        setErr(true)
      }
    }
    if (mids) bind()
  }, [])

  return [loading, err]
}

export default ({ mids, children }) => {
  const [loading, err] = useBindParent(mids)
  if (err) return <FullScreenCenterInfo>网站升级中...</FullScreenCenterInfo>
  if (loading) return <LoadingComp />
  return <Entry children={children} />
}
