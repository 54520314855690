import React from 'react'
import styles from '../sell.module.less'
// import coupon from './coupon.svg'
// import flash from './qianggou.svg'
// import discount from './manjian.svg'
// import buyout from './nyuannjian.svg'
// import seckill from './xianshimiaosha.svg'
// import hotdiscount from './baokuanzhijiang.svg'
import newProduct from './xinpin.svg'
import newbornzone from './newbornzone.svg'
import advanceSale from './advance_sale.svg'

export const NewProductImg = props => <img alt="" src={newProduct} {...props} />
export const NewBornZoneImg = props => (
  <img alt="" src={newbornzone} {...props} />
)
export const PresaleImg = props => <img alt="" src={advanceSale} {...props} />

export default ({
  align = 'right',
  // isCoupon,
  // isDiscount,
  // isHotdiscount,
  // isSeckill,
  // isFlash,
  // isBuyout,
  // hideSeckill,
  // isNewProduct,
  // isNewbornZone,
  data,
  className
}) =>
  Array.isArray(data) && data.length ? (
    <div
      className={`${styles.coupon} ${align === 'left' ? styles.left : ''} ${
        className ? className : ''
      }`}
    >
      {data.map((v, i) => (
        <span key={i} className={styles.tag}>
          {v.show_text || v}
        </span>
      ))}
    </div>
  ) : null
// {isHotdiscount * 1 ? <img alt="" src={hotdiscount} /> : null}
// {isCoupon * 1 ? <img alt="" src={coupon} /> : null}
// {isDiscount * 1 ? <img alt="" src={discount} /> : null}
// {isSeckill * 1 && !hideSeckill ? <img alt="" src={seckill} /> : null}
// {isFlash * 1 && !hideSeckill ? <img alt="" src={flash} /> : null}
// {isBuyout * 1 && !hideSeckill ? <img alt="" src={buyout} /> : null}
// {isNewProduct ? <NewProductImg /> : null}
// {isNewbornZone ? <NewBornZoneImg /> : null}
