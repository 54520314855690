import { getCurrentUser } from '@/services/global'

const REQUEST = 'user/REQUEST'
const OK = 'user/OK'
const CLEAR = 'user/CLEAR'
const ERROR = 'user/ERROR'
const PAGEPOSITION = 'user/PAGEPOSITION'
const INFO = 'user/INFO'
const AGGREE_PROTOCOL = 'user/AGGREE_PROTOCOL'
//保存首页信息
const SAVEPAGEDATA = 'user/SAVEPAGEDATA'
const BIND_PARENT = 'user/BIND_PARENT'

export const userActions = {
  REQUEST,
  OK,
  CLEAR,
  ERROR,
  INFO,
  PAGEPOSITION,
  SAVEPAGEDATA,
  AGGREE_PROTOCOL,
  BIND_PARENT
}

const getUserRequest = () => ({
  type: REQUEST
})

const getUserOk = payload => ({
  type: OK,
  payload
})

const getUserError = payload => ({
  type: ERROR,
  payload
})

const clearUser = payload => ({
  type: CLEAR,
  payload
})

const setUserInfo = payload => ({
  type: INFO,
  payload
})

const userAgreeProtocol = payload => ({
  type: AGGREE_PROTOCOL,
  payload
})

const userBindParent = payload => ({
  type: BIND_PARENT,
  payload
})

const getUser = (params, cb) => async dispatch => {
  dispatch(getUserRequest())
  try {
    const { data, type, msg } = await getCurrentUser(params)
    if (type === 1) {
      dispatch(getUserError(msg))
    } else {
      dispatch(getUserOk(data))
      cb && cb()
    }
    return data
  } catch (error) {
    return {}
    // dispatch(getUserError(error))
  }
}
//保存页面当前位置
const savePagePosition = payload => ({
  type: PAGEPOSITION,
  payload
})
// 保存首页信息
const savePageData = payload => ({
  type: SAVEPAGEDATA,
  payload
})

export {
  getUser,
  clearUser,
  getUserError,
  savePagePosition,
  savePageData,
  setUserInfo,
  userAgreeProtocol,
  userBindParent
}
