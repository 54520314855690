import { testActions } from '@/actions/testActions'

const initialState = {
    loading: false,
    error: false,
    done: false,
    data: []
}

export default (state = initialState, action) => {
    switch(action.type) {
        case testActions.REQUEST:
            return {
                ...initialState,
                loading: true,
            }
        case testActions.OK:
            return {
                error: false,
                loading: false,
                data: action.payload.data,
                done: true
            }
        case testActions.ERROR:
            return {
                ...state,
                loading: false,
                done: true,
                error: true
            }
        default:
            return state
    }
}