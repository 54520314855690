import React from 'react'
import styles from './cartBtn.module.less'
export default ({ children, cb, type }) => {
  const handleClick = e => {
    e.preventDefault()
    cb && cb()
  }
  return (
    <span
      className={`${styles.cartBtn} ${type === 'primary' && styles.primary}`}
      onClick={handleClick}
    >
      {children}
    </span>
  )
}
