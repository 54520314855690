const API_KEY = '4e81a5140d56ecbe6dc6879c'

window.JAnalyticsInterface &&
  window.JAnalyticsInterface.init({
    appkey: API_KEY,
    debugMode: true,
    channel: 'default-channel',
    loc: false,
    singlePage: true
  })

function JAnalyticsCevent(name, key, value) {
  if (!window.JAnalyticsInterface) return
  var CountEvent = window.JAnalyticsInterface.Event.CountEvent
  var cEvent = new CountEvent(name)
  if (key) {
    cEvent.addKeyValue(key, value)
  }
  window.JAnalyticsInterface.onEvent(cEvent)
}

window.JAnalyticsCevent = JAnalyticsCevent

class JGService {
  getToken() {
    return new Promise((resolve, reject) => {
      window.JVerificationInterface.init({
        appkey: API_KEY, // 极光官网中创建应用后分配的 appkey，必填
        debugMode: true, // 设置是否开启 debug 模式。true 则会打印更多的日志信息。设置 false 则只会输出 w、e 级别的日志
        fail: function(err) {
          console.error(
            'JVerificationInterface init fail:' + JSON.stringify(err)
          )
          reject()
        },
        success: function(res) {
          console.log(
            'JVerificationInterface init success:' + JSON.stringify(res)
          )
          if (!window.JVerificationInterface.checkVerifyEnable()) {
            console.error('JVerificationInterface checkVerifyEnable failed')
            return reject()
          }
          // 获取运营商token，默认尝试顺序：移动>联通>电信
          // 实际使用中建议在初始化成功回掉中调用此接口
          window.JVerificationInterface.getToken({
            fail: data => {
              console.error(
                'JVerificationInterface getToken fail:' + JSON.stringify(data)
              )
              reject()
            },
            success: data => {
              var token = data.content
              resolve(token)
              console.warn('JVerificationInterface getToken success:' + token)
            }
          })
        }
      })
    })
  }
  // doc http://docs.jiguang.cn/janalytics/client/web_api/
  JAnalyticsInit() {
    window.JAnalyticsInterface.init({
      appkey: API_KEY,
      debugMode: true,
      channel: 'default-channel',
      loc: false,
      singlePage: true
    })
  }
}

export default JGService
