const PRIZE_LIST = 'myPrize/PRIZE_LIST'
const LIST_PARAMS = 'myPrize/LIST_PARAMS'
const LIST_POSITION = 'myPrize/LIST_POSITION'
const LIST_NOMORE = 'myPrize/LIST_NOMORE'

export const myPrizeActions = {
  PRIZE_LIST,
  LIST_PARAMS,
  LIST_POSITION,
  LIST_NOMORE
}

const updatePrizeList = payload => {
  return {
    type: PRIZE_LIST,
    payload
  }
}

const updatePrizeParams = payload => ({
  type: LIST_PARAMS,
  payload
})

const updatePrizePosition = payload => ({
  type: LIST_POSITION,
  payload
})

const updatePrizeNomore = payload => ({
  type: LIST_NOMORE,
  payload
})

export {
  updatePrizeList,
  updatePrizeParams,
  updatePrizePosition,
  updatePrizeNomore
}
