import { staticPath, isWeixin, isAndroid } from '@/variable'
import { Toast } from 'antd-mobile'
import { invokeAndroidEvent, invokeAppEvent } from './app'
export const getCookie = name => {
  var arr,
    reg = new RegExp('(^| )' + name + '=([^;]*)(;|$)')
  var rs = (arr = document.cookie.match(reg))
  if (rs) {
    return unescape(arr[2])
  } else {
    return null
  }
}

export function getImageUrl(url) {
  return `${process.env.STATIC_PATH}bg/${url}`
}

export const getToken = () => {
  if (isAndroid && !isWeixin) {
    return invokeAppEvent('getToken')
  }
  return window.localStorage.getItem('authorization_token')
}

export const setToken = newToken => {
  return window.localStorage.setItem('authorization_token', newToken)
}
// ios webview设置token
window.setToken = setToken

// ios 设置app版本号
const setAppVersion = ver => {
  return window.localStorage.setItem('app_version', ver)
}
export const getAppVersion = () => {
  return isAndroid
    ? invokeAppEvent('getAppVersion')
    : window.localStorage.getItem('app_version')
}
window.setAppVersion = setAppVersion

export const clearToken = newToken => {
  return window.localStorage.removeItem('authorization_token')
}

export const getSeckillTime = (pre_time, start_time, end_time, now_time) => {
  const now = now_time * 1000 || Date.now()
  const pre_time_at = getTruthTime(pre_time.replace(/-/g, '/'))
  const start_time_at = getTruthTime(start_time.replace(/-/g, '/'))
  const end_time_at = getTruthTime(end_time.replace(/-/g, '/'))
  if (now - pre_time_at > 0 && now < start_time_at) {
    // 未开始 预热
    return {
      time: start_time_at - now,
      status: 1
    }
  } else if (now >= start_time_at && now < end_time_at) {
    // 正在进行
    return {
      time: end_time_at - now,
      status: 2
    }
  } else if (now >= end_time_at) {
    // 已结束
    return {
      time: 0,
      status: 3
    }
  }
  // 预热都还没开始
  return {
    time: start_time_at - now,
    status: 0
  }
}
// 将本地时间转化成东八区时间
export const getTruthTime = time => {
  // 服务器端时区，北京东八区（-8*60）
  let timezone = -480
  // 客户端实际时区(例如东京为东九区：-540)
  let offsetGMT = new Date().getTimezoneOffset()
  // 计算差值（用户在东京时区下下单，需要补回1小时）
  let adjust = timezone - offsetGMT
  // 需要修复的时间，支持字符串传参
  let nowDate = time
    ? new Date(time.replace(/-/g, '/')).getTime()
    : new Date().getTime()
  let timeStamp = new Date(nowDate + adjust * 60 * 1000)
  return timeStamp.getTime()
}

// 分享店铺
export const shareShop = user => {
  const shareOption = {}
  const shopConfig = user.shop_config
  const distributor = user.distributor
  const member = user.member
  const isMember = member.status * 1 !== 1
  const isVip =
    distributor &&
    (distributor.status * 1 === 0 || distributor.status * 1 === 1)
  const showDistrrbutor = isMember && isVip
  const avatarImg = distributor ? distributor.logo : member.avatar
  // vip分享
  if (showDistrrbutor) {
    shareOption.title = distributor ? distributor.title : shopConfig.title
    shareOption.desc = shopConfig.desc
    shareOption.imgUrl = /^https?:\/\//.test(avatarImg)
      ? avatarImg
      : `${staticPath}${avatarImg}`
  } else {
    shareOption.title = shopConfig.title
    shareOption.desc = shopConfig.desc
    shareOption.imgUrl = `${staticPath}${shopConfig.share_wechat_logo}`
  }
  shareOption.link = `${shopConfig.host}?mids=${member.ids}`
  window.wx.ready(function() {
    //需在用户可能点击分享按钮前就先调用
    window.wx.updateAppMessageShareData(shareOption)
    window.wx.updateTimelineShareData(shareOption)
    console.log(shareOption)
  })
  window.wx.error(function(res) {
    console.log(`wechat share error`, res)
  })
}

window.___WX_SHARE_OPTION___ = {}
// 自定义分享
// 获取配置信息
const getWxShareOption = (user = { shop_config: {} }, options) => {
  const imgUrl =
    options.img ||
    options.imgUrl ||
    `${staticPath}${user.shop_config.share_wechat_logo}`
  const member = user.member
  const mids = member ? `mids=${member.ids}` : ''
  const link = !mids
    ? options.link
    : options.link && options.link.indexOf('?') > -1
    ? `${options.link}&${mids}`
    : `${options.link}?${mids}`
  return {
    title: options.title,
    desc: options.desc,
    dialogTitle: options.dialogTitle,
    link: link,
    imgUrl,
    success: options.success || (() => console.log('wx share success')),
    complate: options.complate || (() => console.log('wx share complate')),
    fail: options.fail || (() => console.log('wx share fail'))
  }
}

/**
 * 自定义微信分享
 * @param {*} user store中的user.data
 * @param {Object} options 自定义配置
 * @param {string} options.dialogTitle app 模态框标题文字
 * @param {string} options.title 自定义分享标题
 * @param {string} options.desc 自定义分享描述
 * @param {string} options.link 自定义分享链接
 * @param {string} options.img 自定义分享图片
 */
export const customizeShare = (user, options) => {
  const { dialogTitle, ...shareOption } = getWxShareOption(user, options)
  window.___WX_SHARE_OPTION___ = {
    title: shareOption.title,
    desc: shareOption.desc || '购宜购商城',
    link: shareOption.link,
    imgUrl: shareOption.imgUrl,
    dialogTitle
  }
  window.wx.ready(function() {
    //需在用户可能点击分享按钮前就先调用
    window.wx.updateAppMessageShareData(shareOption)
    window.wx.updateTimelineShareData(shareOption)
  })
}
// 挂载app_invoke_getWxShareOption至window上 以便webview调用
window.app_invoke_getWxShareOption = () => ({
  title: window.___WX_SHARE_OPTION___.title,
  dialogTitle: window.___WX_SHARE_OPTION___.dialogTitle,
  desc: window.___WX_SHARE_OPTION___.desc,
  link: window.___WX_SHARE_OPTION___.link,
  imgUrl: window.___WX_SHARE_OPTION___.imgUrl.replace(staticPath, '')
})

// wechat浏览器 软键盘推起页面 错位
let triggerWindowResizeTimer
export const triggerWindowResize = () => {
  if (triggerWindowResizeTimer) clearTimeout(triggerWindowResizeTimer)
  triggerWindowResizeTimer = setTimeout(() => window.scrollTo(0, 0), 100)
}

// 格式化手机号码
export const formatPhoneNumber = n => {
  if (typeof n !== 'string') return ''
  const ns = n.toString()
  return `${ns.substr(0, 3)}****${ns.substr(7)}`
}

// 销量格式化
export const saleCountFormat = count => {
  const saleCount = count * 1
  if (saleCount < 10000) {
    return saleCount
  } else {
    return `${floatWithTowFloat(saleCount * 0.0001)}万`
  }
}
export const floatWithTowFloat = (num, len = 1) => {
  const [intNum, floatNumStr] = num.toString().split('.')
  if (floatNumStr === undefined) return num * 1
  const floatNum = floatNumStr.substr(0, len)
  return (intNum + '.' + floatNum) * 1
}

// 等比计算图片长宽
export const processLazyImage = (obj, fullwidth) => {
  if (!obj.height) return { width: '100%', height: '100%' }
  const { width = 750, height } = obj
  const vw = 100 / 750
  const sp = width > 750 || fullwidth ? 750 / width : 1
  const w = sp * width * vw
  const h = sp * height * vw
  return { width: `${w}vw`, height: `${h}vw` }
}

// 预处理图片路径
export const getImageSrc = str => {
  if (typeof str !== 'string') return ''
  if (/^https?:\/\//.test(str)) return str
  return `${staticPath}${str}`
}

//微信预览图片
export const previewImage = (currentUrl, urlArr) => {
  const current = getImageSrc(currentUrl)
  const urls = urlArr ? urlArr.map(el => getImageSrc(el)) : [current]
  if (isWeixin) window.wx.previewImage({ current, urls })
}

// 获取身份text
export const getUserIdentityText = (info, isRebuy) => {
  if (info.vip && isRebuy) return '复购价'
  if (!info) return null
  if (info.superPartner) return '复购价'
  if (info.highPartner) return '复购价'
  if (info.partner) return '复购价'
  if (info.vip) return '复购价'
  return null
}
// url添加随机字符串
export function urlAddHash(url, keys) {
  var key = (keys || 't') + '=' //默认是"t"
  var reg = new RegExp(key + '\\d+') //正则：t=1472286066028
  var timestamp = +new Date()
  if (url.indexOf(key) > -1) {
    //有时间戳，直接更新
    return url.replace(reg, key + timestamp)
  } else {
    //没有时间戳，加上时间戳
    if (url.indexOf('?') > -1) {
      var urlArr = url.split('?')
      if (urlArr[1]) {
        return urlArr[0] + '?' + key + timestamp + '&' + urlArr[1]
      } else {
        return urlArr[0] + '?' + key + timestamp
      }
    } else {
      if (url.indexOf('#') > -1) {
        return url.split('#')[0] + '?' + key + timestamp + window.location.hash
      } else {
        return url + '?' + key + timestamp
      }
    }
  }
}
//函数嵌套一层try catch
//返回[err, res]
export const errorCaptured = async (
  asyncFunc,
  params = {},
  loadingText = '加载中',
  errorText = '网络繁忙，亲等会儿再来吧～'
) => {
  Toast.loading(loadingText, null, null)
  try {
    const res = await asyncFunc(params)
    if (res.type === 1) {
      Toast.fail(res.msg)
      return [null, res]
    }
    Toast.hide()
    return [null, res]
  } catch (e) {
    Toast.fail(errorText)
    return [e, null]
  }
}

//点赞数 关注数 粉丝数 视频数量格式化
export const formatNumber = (number = 0) => {
  return !number
    ? 0
    : number * 1 >= 10000
    ? `${parseInt((number / 10000) * 10) / 10}w`
    : number
}

/**
 * 字符串\n转化成<br />标签
 * @param {string} content
 */
export function transferHtmlString(content) {
  if (!content) return ''
  var string = content
  try {
    string = string.replace(/(\r\n|\n)/g, '<br />')
  } catch (e) {
    console.log(e)
  }
  return string
}
// 判断当前分页数据是否异常 异常则刷新页面
export function needRefreshPage(page = 1, listLen) {
  return !((page * 1 - 1) * 10 < listLen && listLen <= page * 10)
}
// 修复modal滚动穿透问题
export function fixedBody() {
  const scrollTop =
    document.body.scrollTop || document.documentElement.scrollTop
  document.body.style.cssText += 'position:fixed;top:-' + scrollTop + 'px;'
}
export function looseBody() {
  const body = document.body
  body.style.position = ''
  const top = body.style.top
  document.body.scrollTop = document.documentElement.scrollTop = -parseInt(top)
  body.style.top = ''
}
// 更新页面title 包括webview
export function updateDocumentTitle(title) {
  // 同步webview title
  if (isAndroid && title) invokeAndroidEvent('modifyTitle', title)
  if (title) window.document.title = title
}

//商品数
export const formatNumberTxt = (number = 0) => {
  return !number
    ? 0
    : number * 1 >= 10000
    ? `${parseInt((number / 10000) * 10) / 10}万`
    : number
}

// 时间戳转换成日期字符串
export const timestampToDateString = d => {
  var now = new Date(d)
  // var year = now.getYear();
  var month = now.getMonth() + 1
  var monthstr = month < 10 ? `0${month}` : month
  var date = now.getDate()
  var hour = now.getHours() < 10 ? `0${now.getHours()}` : now.getHours()
  var minute = now.getMinutes() < 10 ? `0${now.getMinutes()}` : now.getMinutes()
  // var second = now.getSeconds();
  return monthstr + '月' + date + '日 ' + hour + ':' + minute
}

/**
 * 获取随机整数 [min, max)
 *
 * eg: getRandomInt(0, 10)获取0-9的随机整数
 * @param {number} min 最小数
 * @param {number} max 最大数
 */
export function getRandomInt(min, max) {
  min = Math.ceil(min)
  max = Math.floor(max)
  return Math.floor(Math.random() * (max - min) + min)
}

/**
 * 数组项相等
 * @param {array} arr1
 * @param {array} arr2
 */
export const arrayEquity = (arr1 = [], arr2 = []) => {
  if (arr1.length !== arr2.length) return false
  const rs = arr1.filter(el => arr2.indexOf(el) === -1)
  return rs.length === 0
}

/**
 * 数组包含
 * @param {array} arr1
 * @param {array} arr2
 */
export const arrayInclude = (arr1 = [], arr2 = []) => {
  return arr2.every(v => arr1.includes(v))
}

/**
 * 两数相乘(精度丢失问题)
 * @param {number} a
 * @param {number} b
 * @return {number}
 */
export const times = (a, b) => {
  let m = 0
  const s1 = a.toString()
  const s2 = b.toString()
  try {
    m += s1.split('.')[1].length
  } catch (e) {
    m = 0
  }
  try {
    m += s2.split('.')[1].length
  } catch (e) {
    m = 0
  }
  return (
    (Number(s1.replace('.', '')) * Number(s2.replace('.', ''))) /
    Math.pow(10, m)
  )
}

/**
 * object转换为query字符串
 * @param {object} obj key-value对象
 * @param {string} [separator='&'] 分隔符
 * @return {string}
 */
export const queryString = (obj, separator = '&') => {
  const [u, r] = [encodeURIComponent, []]
  for (let k in obj) r.push(`${u(k)}=${u(obj[k])}`)
  return r.join(separator)
}
