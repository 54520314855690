import React from 'react'
import { Flex, Image } from '@/components'
import styles from './index.module.less'

export const DetailEvaltem = ({ data, className, ...props }) => {
  const img = data.images && data.images.length ? data.images[0] : undefined
  return (
    <Flex
      className={`${className} ${styles.box} ${!img && styles.box1}`}
      justify="between"
      {...props}
    >
      <Flex className={styles.main} direction="column">
        <Flex className="w-full" align="center">
          <Image
            className={styles.avatar}
            src={
              +data.is_anonymous === 1
                ? 'https://sitshop.gygmall.com/images/unuser.svg'
                : data.avatar
            }
          />
          <p className="mr-24px">
            {+data.is_anonymous === 1
              ? '匿名用户'
              : processNickName(data.nickname)}
          </p>
        </Flex>
        <p className={`${styles.evaluate} ${img && 'w-350px'}`}>
          {data.evaluate}
        </p>
      </Flex>
      {img ? (
        <div className={styles.image}>
          <Image className="h-140px w-140px" src={img} />
          {data.images.length > 1 ? (
            <p className={styles.label}>{data.images.length}张</p>
          ) : null}
        </div>
      ) : null}
    </Flex>
  )
}

/** 格式化用户昵称 */
function processNickName(nickname) {
  if (!nickname) return '***'
  let arr = []
  for (let k of nickname) {
    arr.push(k)
  }
  if (arr.length <= 1) return `${arr[0]}***`
  return `${arr[0] || ''}***${arr[arr.length - 1]}`
}
