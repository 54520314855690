/* eslint-disable react/react-in-jsx-scope */
// import { ReactNode } from 'react'
import React from 'react'
import classNames from 'classnames'

/* type FlexProps = {
  children?: ReactNode,
  // 对齐内容
  justify?: 'start' | 'center' | 'end' | 'between' | 'around' | 'evenly',
  // 对齐项目
  align?: 'start' | 'end' | 'center' | 'baseline' | 'stretch',
  // Flex Direction
  direction?: 'row' | 'column' | 'row-reverse' | 'column-reverse',
  wrap?: 'wrap' | 'wrap-reverse' | 'nowrap',
  className?: string
} */

const Flex = ({
  align = 'start',
  justify = 'start',
  direction = 'row',
  wrap = 'wrap',
  children,
  className,
  ...props
}) => {
  const prefixCls = 'tv-flex'
  const classes = classNames(
    prefixCls,
    {
      [`${prefixCls}-${direction}`]: direction,
      [`${prefixCls}-${wrap}`]: wrap,
      [`${prefixCls}-justify-${justify}`]: justify,
      [`${prefixCls}-align-${align}`]: align
    },
    className
  )
  return (
    <div className={classes} {...props}>
      {children}
    </div>
  )
}

export default Flex
