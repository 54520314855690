import React from 'react'
import { Flex, Image } from '@/components'
import styles from './index.module.less'

/** 失效商品 */
export const RemovedGoods = ({ data, className, ...props }) => {
  return (
    <div className={`${styles.container} ${className}`} {...props}>
      <Flex wrap="nowrap" align="stretch" className={styles.full}>
        <Image className={styles.img} src={data.img} />
        <Flex direction="column" justify="between">
          <Flex className={styles.full} direction="column">
            <p className={styles.title} ellipsis>
              {data.title}
            </p>
            <p className={styles.props} ellipsis type="secondary">
              {data.props}
            </p>
          </Flex>
        </Flex>
      </Flex>
      {/* <p className={styles.desc}>该商品已下架去看看相似宝贝吧～</p> */}
    </div>
  )
}
