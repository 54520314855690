import { searchResultActions } from '@/actions/searchResultActions'

const initialState = {
  loading: false,
  error: false,
  done: false,
  list: [],
  scrollTop: 0,
  noMore: false,
  box: [],
  searchListIndex: 0,
  hot: [],
  params: {
    sort: 0,
    page: 1,
    pageSize: 10
  }
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case searchResultActions.REQUEST:
      return {
        ...state,
        list: payload.page === 1 ? [] : state.list,
        done: false,
        error: false,
        loading: true
      }
    case searchResultActions.SCROLLTOP:
      return {
        ...state,
        scrollTop: payload
      }
    case searchResultActions.SEARCH_LIST_INDEX:
      return {
        ...state,
        searchListIndex: payload
      }
    case searchResultActions.PARAMS:
      return {
        ...state,
        params: { ...state.params, ...payload }
      }
    case searchResultActions.OK:
      return {
        ...state,
        list: [...state.list, ...payload._list],
        noMore:
          payload._page.page >= payload._page.totalPage ||
          (Array.isArray(payload._list) && !payload._list.length),
        loading: false,
        done: true
      }
    case searchResultActions.ERROR:
      return {
        ...state,
        done: true,
        loading: false,
        error: payload
      }
    case searchResultActions.SEARCH_BOX:
      return {
        ...state,
        box: payload
      }
    case searchResultActions.SEARCH_HOT:
      return {
        ...state,
        hot: payload
      }
    case searchResultActions.RESET:
      return { ...initialState, hot: state.hot }
    default:
      return state
  }
}
