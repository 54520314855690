import {
  orderList,
  alreadyEvaluated,
  toBeEvaluated
} from '@/services/mine/order'
const PARAMS = 'order/PARAMS'
const SCROLLTOP = 'order/SCROLLTOP'
const TABSTATUS = 'order/TABSTATUS'
const REQUEST = 'order/REQUEST'
const OK = 'order/OK'
const ERROR = 'order/ERROR'
const MODIFY = 'order/MODIFY'
const ADDADDRESS = 'order/ADDADDRESS'

export const orderActions = {
  PARAMS,
  SCROLLTOP,
  TABSTATUS,
  REQUEST,
  OK,
  ERROR,
  ADDADDRESS,
  MODIFY
}

const setOrderParams = payload => ({
  type: PARAMS,
  payload
})

const setScrollTop = payload => ({
  type: SCROLLTOP,
  payload
})

const setTabStatus = payload => ({
  type: TABSTATUS,
  payload
})

const setAddAddress = payload => ({
  type: ADDADDRESS,
  payload
})

const getOrderRequest = () => ({
  type: REQUEST
})

const getOrderOk = payload => ({
  type: OK,
  payload
})

const getOrderError = payload => ({
  type: ERROR,
  payload
})

const setModifyAddr = payload => ({
  type: MODIFY,
  payload
})

const getOrder = (params = {}, init) => async dispatch => {
  dispatch(setOrderParams(params))
  dispatch(getOrderRequest())
  const { status, evaluateStatus } = params
  if (status === 0) {
    delete params.status
    const { type, msg, data } = await orderList(params)
    if (type === 1) return getOrderError(msg)
    dispatch(getOrderOk({ ...data, init }))
  } else if (
    status * 1 === 40 &&
    (evaluateStatus * 1 === 0 || evaluateStatus === undefined)
  ) {
    delete params.evaluateStatus
    delete params.status
    const { type, msg, data } = await toBeEvaluated(params)
    if (type === 1) return getOrderError(msg)
    dispatch(getOrderOk({ ...data, init }))
  } else if (status * 1 === 40 && evaluateStatus * 1 === 1) {
    delete params.evaluateStatus
    delete params.status
    const { type, msg, data } = await alreadyEvaluated(params)
    if (type === 1) return getOrderError(msg)
    dispatch(getOrderOk({ ...data, init }))
  } else {
    const { type, msg, data } = await orderList(params)
    if (type === 1) return getOrderError(msg)
    dispatch(getOrderOk({ ...data, init }))
  }
}

export { getOrder, setScrollTop, setTabStatus, setAddAddress, setModifyAddr }
