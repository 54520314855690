import { getSupplierInfo, getSupplierGoods } from '@/services/global'

const SCROLLTOP = 'supplier/SCROLLTOP'
const REQUEST = 'supplier/REQUEST'
const OK = 'supplier/OK'
const ERROR = 'supplier/ERROR'

const INFO_REQUEST = 'supplier/INFO_REQUEST'
const INFO_OK = 'supplier/INFO_OK'
const INFO_ERROR = 'supplier/INFO_ERROR'

export const supplierActions = {
  SCROLLTOP,
  REQUEST,
  OK,
  ERROR,
  INFO_REQUEST,
  INFO_OK,
  INFO_ERROR
}

const setScrollTop = payload => ({
  type: SCROLLTOP,
  payload
})

const getSupplierRequest = payload => ({
  type: REQUEST,
  payload
})

const getSupplierOk = payload => ({
  type: OK,
  payload
})

const getSupplierError = payload => ({
  type: ERROR,
  payload
})

const getSupplierInfoRequest = payload => ({
  type: INFO_REQUEST,
  payload
})

const getSupplierInfoOk = payload => ({
  type: INFO_OK,
  payload
})

const getSupplierInfoError = payload => ({
  type: INFO_ERROR,
  payload
})

const getSupplier = (params = {}) => async dispatch => {
  try {
    dispatch(getSupplierInfoRequest())
    const { type, msg, data } = await getSupplierInfo(params)
    if (type === 1) return getSupplierInfoError(msg)
    dispatch(getSupplierInfoOk({ ...data }))
  } catch (error) {
    getSupplierInfoError()
  }
}
const getSupplierProduct = (params = {}) => async dispatch => {
  dispatch(getSupplierRequest(params))
  const { type, msg, data } = await getSupplierGoods(params)
  if (type === 1) return getSupplierError(msg)
  dispatch(getSupplierOk({ ...data }))
}

export { getSupplier, getSupplierInfoOk, getSupplierProduct, setScrollTop }
