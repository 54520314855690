const SIMILAR_LIST = 'similar/SIMILAR_LIST'
const SIMILAR_PARAMS = 'similar/SIMILAR_PARAMS'
const SIMILAR_POSITION = 'similar/SIMILAR_POSITION'
const SIMILAR_NOMORE = 'similar/SIMILAR_NOMORE'
const SIMILAR_INVALID_GOODS = 'similar/SIMILAR_INVALID_GOODS'
const RESET_SIMILAR = 'similar/RESET_SIMILAR'

export const similarActions = {
  SIMILAR_LIST,
  SIMILAR_PARAMS,
  SIMILAR_POSITION,
  SIMILAR_NOMORE,
  SIMILAR_INVALID_GOODS,
  RESET_SIMILAR
}

const updateSimilarList = payload => {
  return {
    type: SIMILAR_LIST,
    payload
  }
}

const resetSimilar = payload => {
  return {
    type: RESET_SIMILAR,
    payload
  }
}

const updateSimilarParams = payload => ({
  type: SIMILAR_PARAMS,
  payload
})

const updateSimilarPosition = payload => ({
  type: SIMILAR_POSITION,
  payload
})

const updateSimilarNomore = payload => ({
  type: SIMILAR_NOMORE,
  payload
})

const saveInvalidGoods = payload => ({
  type: SIMILAR_INVALID_GOODS,
  payload
})

export {
  updateSimilarList,
  updateSimilarParams,
  updateSimilarPosition,
  updateSimilarNomore,
  saveInvalidGoods,
  resetSimilar
}
