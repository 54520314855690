import clsx from 'classnames'
import React, { cloneElement } from 'react'
import Flex from '../Flex'
import styles from './index.module.less'

export default ({
  children,
  visible,
  animate,
  zIndex = 50,
  className,
  center = true,
  ...props
}) => {
  const prefixCls = 'overlay'
  const classes = clsx(
    [styles[prefixCls]],
    {
      [styles[`${prefixCls}--open`]]: visible,
      [styles[`${prefixCls}--open__animate`]]: visible && animate,
      [styles[`${prefixCls}--close__animate`]]: !visible && animate
    },
    className
  )
  return (
    <div style={{ zIndex: zIndex }} className={classes} {...props}>
      {center ? (
        <Flex align="center" justify="center" className={styles.hFull}>
          {children &&
            cloneElement(children, {
              onClick: e => {
                if (children.props.onClick) {
                  // 设置显示内容
                  children.props.onClick(e)
                }
                e.stopPropagation()
              }
            })}
        </Flex>
      ) : (
        children
      )}
    </div>
  )
}
