import { stringify } from 'qs'
import request from '@/utils/request'
import { serverApi } from '@/variable'

// 大促详情接口
export function designPromotion(params) {
  return request(`${serverApi}/designPromotion?${stringify(params)}`)
}

// 领取
export function getCoupon(params) {
  return request(`${serverApi}/createMemberCoupon`, {
    method: 'POST',
    body: { ...params }
  })
}
