import { confirmOrderActions } from '@/actions/confirmOrderActions'

const initialState = {
    loading: false,
    error: false,
    done: false,
    data: {}
}

export default (state = initialState, { type, payload }) => {
    switch(type) {
        case confirmOrderActions.REQUEST:
            return {
                ...state,
                loading: true,
            }
        case confirmOrderActions.OK:
            return {
                ...state,
                data: payload,
                done: true
            }
        case confirmOrderActions.ERROR:
            return {
                ...state,
                done: true,
                error: true
            }
        default:
            return state
    }
}