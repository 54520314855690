import { stringify } from 'qs'
import request from '@/utils/request'
import { serverApi, msgApi } from '@/variable'

// 积分商城头部信息
export function getIntegralMallHeadInfo(params) {
  return request(
    `${serverApi}/integralGoods/getIntegralMallHeadInfo?${stringify(params)}`
  )
}

// 积分优惠券
export function getIntegralCouponDetail(params) {
  return request(
    `${serverApi}/integralGoods/getIntegralCouponDetail?${stringify(params)}`
  )
}

//积分明细
export function getMemberMagicRecordList(params) {
  return request(`${serverApi}/getMemberMagicRecordList&${stringify(params)}`)
}

//兑换凭证
export function getExchangeCertificate(params) {
  return request(
    `${serverApi}/coupon/getExchangeCertificate?${stringify(params)}`
  )
}

// 获取微信息 -- 头像
export function confirmOrder(params) {
  return request(`${serverApi}/confirmOrder`, {
    method: 'POST',
    body: { ...params }
  })
}

// 获取积分任务列表
export function getIntegralTaskList(params) {
  return request(
    `${serverApi}/integralTask/getIntegralTaskList?${stringify(params)}`
  )
}

// 完成积分任务
export function finishTask(params) {
  return request(`${msgApi}/integralTask/finishTask`, {
    method: 'POST',
    body: { ...params }
  })
}
