import React from 'react'
import styles from './image.module.less'
import LazyImg from '@/components/LazyImg'

const getGoodsStatusBg = status => {
  if (status === 4) return 'yijieshu'
  if (status === 5) return 'yiqiangwan'
  if (status === 6) return 'youjihui'
  return null
}

const staticUrl = `${process.env.PUBLIC_URL}/images/categorys/`

export default function GoodsImage({
  src,
  size = 'normal',
  status,
  type,
  coner,
  className = ''
}) {
  const _status = getGoodsStatusBg(status * 1)
  return (
    <div className={`${styles.goodsImg} ${styles[size]} ${className}`}>
      {/* {React.isValidElement(coner) && coner} */}
      {/* <ConerTag>{coner}</ConerTag> */}
      {coner && <div className={styles.coner}>{coner}</div>}
      {!!_status && (
        <img
          className={`${styles.status} ${styles[size]}`}
          alt="sold out"
          src={`${staticUrl}${getGoodsStatusBg(status * 1)}.svg`}
        />
      )}
      <LazyImg
        src={src}
        className={`${styles.main} ${type === 'center' ? styles.center : ''}`}
      />
    </div>
  )
}
