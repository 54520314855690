import React from 'react'
import history from '@/routes/history'
import { useSelector } from 'react-redux'

const bindRoute = '/bind-parent'

export default ({ children }) => {
  const { is_need_bind } = useSelector(state => state.user.data)
  const innerClick = e => {
    e.preventDefault()
    history.push(bindRoute)
    e.stopPropagation()
    return
  }
  if (typeof children === 'function') {
    return children({
      goToBind: innerClick,
      bindRoute,
      needBind: is_need_bind
    })
  }
  if (is_need_bind) {
    return React.cloneElement(children, { onClick: innerClick })
  }
  return children
}
