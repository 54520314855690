import React, { useState } from 'react'
import { Image, Flex, Countdown } from '@/components'
import { Progress } from 'antd-mobile'
import CountdownPng from './countdown.png'
import styles from './index.module.less'

const PointsCountdown = ({ onComplete = () => {} }) => {
  // 倒计时秒数
  const date = 30
  // 倒计时
  const [time, setTime] = useState(0)
  return (
    <Flex
      align="center"
      justify="center"
      className={styles.main}
      direction="column"
    >
      <Image className={styles.img} src={CountdownPng} />
      <div className={styles.box}>
        <Progress
          position="normal"
          className={styles.progress}
          percent={time}
        />
        <p className={styles.txt}>
          {
            <Countdown
              date={date * 1000}
              onComplete={onComplete}
              intervalDelay={1000}
              renderer={props => {
                const target = props.total / 1000
                setTime(((date - target) / date) * 100)
                return target === 0
                  ? '已完成'
                  : '浏览' + (30 - target + 1) + 'S'
              }}
            />
          }
        </p>
      </div>
    </Flex>
  )
}

export default PointsCountdown
