import { authActions } from '@/actions/authActions'

const initialState = {
  loading: false,
  error: false,
  done: false,
  data: {}
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case authActions.REQUEST:
      return {
        ...state,
        loading: true
      }
    case authActions.OK:
      return {
        ...state,
        data: payload,
        done: true
      }
    case authActions.ERROR:
      return {
        ...state,
        done: true,
        error: true
      }
    default:
      return state
  }
}
