import { userActions } from '@/actions/userActions'

const initialState = {
  loading: false,
  error: false,
  done: false,
  data: {},
  pagePosition: {},
  pageData: null
}

function processUserInfo(info) {
  if (!info) return null
  // 当前身份字段
  // 1: 普通会员； 2: vip； 3:店长；4:超级店长
  const { member } = info
  const rank = member.rank * 1
  // 是否是vip
  const isVip = rank > 0
  // vip状态说明
  // 0 永久
  // 1 临时
  // 2 禁用
  // 3 取消身份
  // null 不是vip
  const vipStatus = member.status * 1
  // 是否是店长
  const isPartner = rank > 1
  // 是否是高级店长
  const isHighPartner = rank > 2
  // 是否是超级店长
  const isSuperPartner = rank > 3
  const _userIdentity = {
    vipStatus,
    vip: isVip,
    partner: isPartner,
    superPartner: isSuperPartner,
    highPartner: isHighPartner
  }
  info._userIdentity = _userIdentity
  return info
}

export default (state = initialState, action) => {
  switch (action.type) {
    case userActions.REQUEST:
      return {
        ...state,
        loading: true
      }
    case userActions.INFO:
      return {
        ...state,
        data: processUserInfo(action.payload)
      }
    case userActions.OK:
      const newData = processUserInfo(action.payload)
      return {
        error: false,
        loading: false,
        data: newData,
        done: true
      }
    case userActions.CLEAR:
      return {
        error: false,
        loading: false,
        data: null,
        done: true
      }
    case userActions.ERROR:
      return {
        ...state,
        loading: false,
        done: true,
        error: action.payload
      }
    case userActions.PAGEPOSITION:
      return {
        ...state,
        pagePosition: action.payload
      }
    case userActions.SAVEPAGEDATA:
      return {
        ...state,
        pageData: action.payload
      }
    case userActions.AGGREE_PROTOCOL:
      const agreeData = { ...state.data }
      agreeData.member.is_privacy_agreement = 1
      return {
        ...state,
        data: agreeData
      }
    case userActions.BIND_PARENT:
      const bindData = { ...state.data }
      bindData.is_need_bind = 0
      return {
        ...state,
        data: bindData
      }

    default:
      return state
  }
}
