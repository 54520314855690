const LIVE_LIST = 'playbackLive/LIVE_LIST'
const LIVE_PARAMS = 'playbackLive/LIVE_PARAMS'
const LIVE_POSITION = 'playbackLive/LIVE_POSITION'
const LIVE_NOMORE = 'playbackLive/LIVE_NOMORE'

export const playbackLiveActions = {
  LIVE_LIST,
  LIVE_PARAMS,
  LIVE_POSITION,
  LIVE_NOMORE
}

const updatePlaybackLiveList = payload => {
  return {
    type: LIVE_LIST,
    payload
  }
}

const updatePlaybackLiveParams = payload => ({
  type: LIVE_PARAMS,
  payload
})

const updatePlaybackLivePosition = payload => ({
  type: LIVE_POSITION,
  payload
})

const updatePlaybackLiveNomore = payload => ({
  type: LIVE_NOMORE,
  payload
})

export {
  updatePlaybackLiveList,
  updatePlaybackLiveParams,
  updatePlaybackLivePosition,
  updatePlaybackLiveNomore
}
