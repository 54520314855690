import React, { useEffect, memo } from 'react'
import LazyLoad from 'vanilla-lazyload'

const lazyloadConfig = {
  elements_selector: '.lazy'
}

// Only initialize it one time for the entire application
if (!document.lazyLoadInstance) {
  document.lazyLoadInstance = new LazyLoad(lazyloadConfig)
}

const VanillaImage = props => {
  // Update lazyLoad after first rendering of every image
  // Update lazyLoad after rerendering of every image
  useEffect(() => {
    document.lazyLoadInstance.update()
    return () => null
  })

  // Just render the image with data-src
  const {
    alt = 'alt',
    src,
    srcset,
    sizes,
    width,
    height,
    className,
    onClick
  } = props
  return (
    <div
      width={width}
      height={height}
      className={`lazywrapper bg ${className}`}
      onClick={onClick}
    >
      <img
        alt={alt}
        className="lazy"
        data-src={src}
        data-srcset={srcset}
        data-sizes={sizes}
        onLoad={e => {
          e.target.parentNode.className = `lazywrapper ${className}`
        }}
        {...props}
        onClick={() => null}
      />
    </div>
  )
}

export default memo(VanillaImage, (prev, next) => prev.src === next.src)
