import create from 'zustand'
import { useRequest } from 'ahooks'
import { getShoppingCartProductQuantity } from '@/services/global'

const useStore = create(set => ({
  isLoading: false,
  data: 0,
  setData: data => set(() => ({ data: data })),
  setLoading: data => set(() => ({ isLoading: data }))
}))

/** 购物车数量 */
const useQuantity = () => {
  const [data, setData, isLoading, setLoading] = useStore(store => [
    store.data,
    store.setData,
    store.isLoading,
    store.setLoading
  ])
  const { run } = useRequest(getShoppingCartProductQuantity, {
    manual: isLoading,
    onSuccess: res => {
      setData(res.data.quantity)
      setLoading(true)
    }
  })
  return {
    data,
    refresh: run
  }
}

export default useQuantity
