import { stringify } from 'qs'
import request from '@/utils/request'
import { videoApi } from '@/variable'

// 视频首页列表
export function getVideoList(params) {
  return request(`${videoApi}/video/list?${stringify(params)}`)
}
// 视频首页列表
export function isShowTopic() {
  return request(`${videoApi}/topic/isShow`)
}
//关注
export function giveAuthorFocus(params) {
  return request(`${videoApi}/homePage/follow`, {
    method: 'POST',
    body: { ...params }
  })
}
//取消关注
export function cancelAuthorFocus(params) {
  return request(`${videoApi}/homePage/unFollow`, {
    method: 'POST',
    body: { ...params }
  })
}
//个人主页详情
export function getHomePageDetail(params) {
  return request(`${videoApi}/homePage/getDetail?${stringify(params)}`)
}
//个人主页视频列表
export function getMineVideoList(params) {
  return request(`${videoApi}/homePage/getVideoList?${stringify(params)}`)
}
//个人主页图文列表
export function getGraphicList(params) {
  return request(`${videoApi}/homePage/getMaterialList?${stringify(params)}`)
}
//个人主页收藏视频列表
export function getCollectionVideoList(params) {
  return request(
    `${videoApi}/homePage/getCollectionVideoList?${stringify(params)}`
  )
}
//个人主页收藏图文列表
export function getCollectionGraphicList(params) {
  return request(
    `${videoApi}/homePage/getCollectionMaterialList?${stringify(params)}`
  )
}
//个人主页喜欢视频列表
export function getLikedVideoList(params) {
  return request(`${videoApi}/homePage/getLikedVideoList?${stringify(params)}`)
}
//个人主页喜欢图文列表
export function getLikedGraphicList(params) {
  return request(
    `${videoApi}/homePage/getLikedMaterialList?${stringify(params)}`
  )
}
//关注列表
export function getFollowList(params) {
  return request(`${videoApi}/homePage/getFollowList?${stringify(params)}`)
}
//素材未读提示
export function materialUnreadPrompt(params) {
  return request(`${videoApi}/material/unreadPrompt?${stringify(params)}`)
}
//粉丝列表
export function getFansList(params) {
  return request(`${videoApi}/homePage/getFansList?${stringify(params)}`)
}
// 获取分类列表
export function getVideoCategorys(params) {
  return request(`${videoApi}/video/categoryList?${stringify(params)}`)
}
// 获取参与话题列表
export function getTopicList(params) {
  return request(`${videoApi}/topic/getParticipateList?${stringify(params)}`)
}

// 未读提示
export function unreadPrompt(params) {
  return request(`${videoApi}/video/unreadPrompt?${stringify(params)}`)
}
