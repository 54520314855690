import React, { useEffect, useMemo, useState } from 'react'
import NP from 'number-precision'
import { getProductSku } from '@/services/global'
import { addShippingCart } from '@/services/cart'
import { useQuantity } from '@/model'
import { arrayInclude, previewImage } from '@/utils/utils'
import { staticPath, ImageSize } from '@/variable'
import { Modal, Flex, Toast } from 'antd-mobile'
import { useSelector } from 'react-redux'
import Counter from '@/components/Counter'
import RcSize from './RcSize'
import styles from './new.module.less'
import { useSetState } from 'react-use'
import history from '@/routes/history'
import PriceTag from '@/components/PriceTag'
import skuPropBtnSrc from './sku_prop_btn.png'
import skuPropQhSrc from './sku_prop_qh.png'
import skuPropQhAcSrc from './sku_prop_qh_ac.png'

import LazyImg from '../LazyImg'

export default ({
  gid,
  visible,
  defaultData,
  defaultParams = {},
  hideQty,
  showType = 'default',
  onCancel,
  groupBuyActivityIds,
  groupBuyType, // 1:单独购买
  groupBuyLeaderIds, // 点去参团(拼团的ids)
  onPropChange,
  onOk: _onOk,
  groupBuyCount, // 阶梯团选择的参数
  onComplete
}) => {
  const { refresh: quantityRefresh } = useQuantity()
  const user = useSelector(state => state.user.data)
  const [groupDisabled, setGroupDisabled] = useState(false)
  const is_newborn = user.member.rank * 1 === 0
  const [state, set] = useSetState({
    loading: false,
    defaultProduct: undefined,
    isArrivalGoods: false,
    select: {},
    resp: {},
    quantity: 1,
    groupBuyCount
  })
  useEffect(() => {
    set(v => ({ ...v, groupBuyCount }))
    setGroupDisabled(!!groupBuyCount)
  }, [groupBuyCount])
  useEffect(() => {
    if (defaultData && defaultData.ids && !gid) {
      const { number: quantity = 1 } = defaultData.purchase_restrictions || {}
      set({ resp: defaultData, quantity: +quantity || 1 })
      _processResponse(defaultData)
    }
  }, [defaultData])
  // 拼团数据，是否是拼团通过有无改数据
  const { group_buying_info } = state.resp
  // 什么拼团 13：普通团 14：新人团 15：阶梯团 16：万人团
  const groupStatus = group_buying_info && +group_buying_info.market_type

  useEffect(() => {
    // 出入的gid和缓存的ids不同才请求新数据
    if (visible && gid && state.resp.ids !== gid) {
      getData({ goodsIds: gid })
    }
  }, [visible])

  const getData = async params => {
    set({ loading: true })
    Toast.loading(null, null)
    try {
      const { data, type, msg } = await getProductSku(params)
      if (type === 1) throw Error(msg)
      _processResponse(data)
      Toast.hide()
    } catch (error) {
      Toast.info(error.message)
      set({ loading: false })
    }
  }

  const _processResponse = data => {
    processSkuBaseData(data)
    processAttrsStatus({}, data.goods_prop, data.goods_product)
    const defaultProduct = {
      img: data.img,
      stock: data.stock,
      price: data.floor_price,
      original_price: data.original_price,
      ladder_price:
        +groupBuyType !== 1 && data.group_buying_info
          ? data.group_buying_info.min_ladder_price
          : []
    }
    const resp = { ...data }
    const isArrivalGoods =
      resp.onsale * 1 &&
      resp.is_general_goods &&
      !resp.integral_goods_info &&
      defaultParams.from_goods_detail
    set({
      loading: false,
      select: {},
      defaultProduct,
      isArrivalGoods,
      resp
    })
  }
  // 属性点击事件
  const onItemClick = prop => {
    const { select, isArrivalGoods, resp } = state
    if (!prop.canuse && !isArrivalGoods) return
    const { goods_prop: _attrs, goods_product: _products } = resp
    if (select[prop.prop_ids] === prop.prop_value_ids) {
      delete select[prop.prop_ids]
    } else {
      select[prop.prop_ids] = prop.prop_value_ids
    }
    processAttrsStatus(select, _attrs, _products)
    set({ select, resp })
  }
  const onClose = () => {
    onCancel()
  }
  const onBuy = () => {
    if (user.is_need_bind) {
      history.push('/bind-parent')
      return
    }
    if (!_preCheck()) return
    const { quantity, resp } = state
    const params = {
      groupBuyType,
      groupBuyLeaderIds,
      groupBuyActivityIds,
      groupBuyCount: state.groupBuyCount,
      goods: [
        {
          goodsIds: resp.integral_goods_info
            ? resp.integral_goods_info.ids
            : resp.ids,
          goodsProductIds: currentProduct.ids,
          quantity,
          seckill: resp.is_seckill ? 1 : 0
        }
      ],
      memberAddressId: 0,
      type: resp.integral_goods_info
        ? 2
        : resp.is_advance_sale * 1 === 1
        ? 3
        : groupStatus === 15 && !state.groupBuyCount
        ? 1
        : group_buying_info && +groupBuyType !== 1
        ? 4
        : 1,
      ...defaultParams
    }
    window.JAnalyticsCevent('2020.02.13-detail-buynow')
    window.localStorage.setItem('submut_order_params', JSON.stringify(params))
    onClose()
    setTimeout(() => history.push('/auction/confirm_order'), 500)
  }
  const onAddcart = async () => {
    if (!_preCheck()) return
    const { quantity, resp } = state
    const params = {
      goodsId: resp.id,
      goodsProductIds: currentProduct.ids,
      quantity
    }
    try {
      Toast.loading()
      const { data, type, msg } = await addShippingCart(params)
      if (type === 1) throw Error(msg)
      quantityRefresh()
      window.JAnalyticsCevent('2020.02.13-detail-addcart')
      Toast.info(msg, 2, null, false)
      onClose()
      if (onComplete) {
        setTimeout(() => onComplete(data, params), 300)
      }
    } catch (error) {
      Toast.fail(error.message)
    }
  }
  const onOk = () => {
    if (showType === 'addcart') {
      onAddcart()
    } else if (showType === 'buy') {
      onBuy()
    } else if (showType === 'default') {
      if (!_preCheck()) return
      const params = _getParams()
      if (_onOk) _onOk(params)
    } else {
      console.log('unkown showType value: ', showType)
    }
  }

  const onPresale = () => {
    if (!resp.advance_sale_info) return
    if (resp.advance_sale_info.activity_status === 2) {
      onBuy()
    }
    if (resp.advance_sale_info.activity_status === 4) {
      history.push('/cart')
    }
  }

  const getPropStr = () => {
    const { select, resp } = state
    if (!resp.ids) return null
    // 获取未选规格的提示文案
    let str = resp.goods_prop.reduce((a, v) => {
      if (select[v.prop_ids]) return a
      a.push(v.prop_name)
      return a
    }, [])
    // 所有规格都选择了 展示已选文案(从currentProduct取出)
    if (!str.length && resp.goods_prop.length) {
      str = currentProduct.props_str.replace(/:/gi, ',')
      return `已选: ${str}`
    }
    return `请选择 ${str.join(',')}`
  }

  const triggerPropChange = () => {
    if (onPropChange && showType === 'sku') {
      onPropChange(getPropStr())
    }
  }

  // 提交前检查数据
  const _preCheck = () => {
    const { select, resp } = state
    const { advance_sale_info } = resp
    // 预售预热中 不支持购买
    if (advance_sale_info && advance_sale_info.activity_status === 1)
      return false
    if (Object.values(select).length !== resp.goods_prop.length) {
      const propsStr = getPropStr()
      Toast.info(propsStr)
      return false
    }
    return true
  }
  // 获取提交参数
  const _getParams = () => {
    const { quantity, resp } = state
    const params = {
      ...defaultParams,
      goodsId: resp.id,
      goodsIds: resp.ids,
      quantity,
      goodsProductIds: currentProduct.ids,
      props_str: currentProduct.props_str
    }
    return params
  }

  const { resp, defaultProduct, select, isArrivalGoods } = state
  const { number: purchaseNumber, multiple: purchaseMultiple } =
    resp.purchase_restrictions || {}

  const purchaseText = useMemo(() => {
    const hasPurchaseMultiple = +purchaseMultiple > 1
    if (hasPurchaseMultiple) {
      return (
        <div className={styles.purchaseText}>
          仅支持{purchaseMultiple}倍购买
        </div>
      )
    }
    return null
  }, [purchaseNumber, purchaseMultiple])

  if (!resp.ids) return null
  // 未择任何规格时展示默认商品信息(defaultProduct)
  if (state.loading) return null
  const currentProduct =
    JSON.stringify(select) === '{}'
      ? defaultProduct
      : resp.goods_product.find(product =>
          arrayInclude(product.props.split(':'), Object.values(select))
        )
  const selectDone = Object.values(select).length === resp.goods_prop.length
  const propsTitleText = getPropStr()

  triggerPropChange()
  if (groupStatus === 15) {
    // 阶梯团价格
    if (state.groupBuyCount) {
      // 已选阶梯团
      const idx = group_buying_info.group_buying_count.findIndex(
        item => item === state.groupBuyCount
      )
      const target = resp.goods_product.find(
        item => item.props_str === _getParams().props_str
      )
      currentProduct.activity_price = target
        ? target.ladder_price[idx]
        : resp.activity_price
    } else {
      // 未选阶梯团
      currentProduct.activity_price = currentProduct.price
    }
  }
  // 限购数量
  const num = +(
    (group_buying_info && groupBuyType !== 1 && group_buying_info.cquota) ||
    resp.cquota
  )

  return (
    <Modal
      wrapClassName={styles.modal}
      popup
      closable
      transparent
      transitionName="dialog"
      maskTransitionName="overly-mask"
      animationType="slide-up"
      visible={visible}
      onClose={onClose}
    >
      <div
        className={`${styles.skuBlock} ${
          resp.goods_prop_main_ids * 1 > 0 ? styles.largeView : ''
        } `}
      >
        <div className={styles.imgBox}>
          <div
            className={styles.img}
            onClick={() => previewImage(currentProduct.img)}
          >
            <img
              alt="img"
              src={`${staticPath}${currentProduct.img}${ImageSize.smallGoods}`}
            />
          </div>
          <div className={styles.prices}>
            {resp.advance_sale_info ? (
              <div className={styles.presaleBox}>
                <Flex className={styles.item} align="center">
                  <span className={styles.count}>定金</span>{' '}
                  <PriceTag value={resp.advance_sale_info.deposit} />
                  <span className={styles.count}>预售价</span>{' '}
                  <PriceTag value={currentProduct.price} />
                </Flex>
                <Flex className={styles.item} align="center">
                  <span className={styles.count}>尾款</span>{' '}
                  <PriceTag
                    style={{ margin: 0 }}
                    value={
                      currentProduct.final_price ||
                      resp.advance_sale_info.floor_final_price
                    }
                  />
                  {resp.advance_sale_info.floor_final_price * 1 !==
                    resp.advance_sale_info.ceiling_final_price * 1 &&
                    !currentProduct.ids && (
                      <>
                        <span style={{ color: '#f44336' }}>-</span>{' '}
                        <PriceTag
                          value={resp.advance_sale_info.ceiling_final_price}
                        />
                      </>
                    )}
                </Flex>
              </div>
            ) : (
              <div className={styles.top}>
                {resp.integral_goods_info ? (
                  <div className={styles.integralPrice}>
                    {resp.integral_goods_info.integral}
                    <span className={styles.cn}>积分</span>
                    {resp.integral_goods_info.price * 1 > 0 && (
                      <span className={styles.price}>
                        + <small>￥</small>
                        {resp.integral_goods_info.price}
                      </span>
                    )}
                  </div>
                ) : (
                  <div className={styles.mainPrice}>
                    {resp.newborn_zone_info && (
                      <div className={styles.newBuyerText}>新人专享价</div>
                    )}
                    {resp.ceiling_price * 1 !== resp.floor_price * 1 &&
                    !currentProduct.ids ? (
                      <>
                        <PriceTag
                          style={{ marginRight: 0 }}
                          value={resp.floor_price}
                        />{' '}
                        —
                        <PriceTag symbol={false} value={resp.ceiling_price} />
                      </>
                    ) : (
                      <PriceTag
                        value={
                          group_buying_info && groupBuyType !== 1
                            ? currentProduct.activity_price
                            : currentProduct.price
                        }
                      />
                    )}
                    <PriceTag
                      type="dash"
                      value={currentProduct.original_price}
                    />
                  </div>
                )}
                <div className={styles.stock}>库存{currentProduct.stock}件</div>
              </div>
            )}
            <div className={styles.pick}>{propsTitleText}</div>
            {selectDone && currentProduct.special_instructions ? (
              <div className={styles.specText}>
                {currentProduct.special_instructions}
              </div>
            ) : null}
          </div>
        </div>
        <RcSize detail={resp} />
        <div className={styles.bigLine} />
        <div className={styles.body}>
          {resp.goods_prop.map((propGroup, index) => (
            <div key={index} className={`${styles.attr}`}>
              <div className={styles['attr-title']}>{propGroup.prop_name}</div>
              <div className={styles['attr-wrapper']}>
                {propGroup.props.map((prop, i) => (
                  <div
                    key={i}
                    onClick={() => onItemClick(prop)}
                    className={`
                    ${
                      prop.prop_main_img
                        ? styles['attr-item-large']
                        : styles['attr-item']
                    }  
                    ${
                      select[prop.prop_ids] === prop.prop_value_ids
                        ? currentProduct.stock * 1 === 0
                          ? styles.noStockActive
                          : styles.active
                        : ''
                    } 
                    ${
                      !prop.canuse
                        ? prop.prop_main_img && isArrivalGoods
                          ? ''
                          : styles.disabled
                        : ''
                    }
                    `}
                  >
                    {prop.prop_main_img ? (
                      <>
                        <img
                          alt=""
                          className={styles.ibtn}
                          src={skuPropBtnSrc}
                          onClick={() => previewImage(prop.prop_main_img)}
                        />
                        {!prop.canuse && (
                          <img
                            alt=""
                            className={styles.iqh}
                            src={
                              select[prop.prop_ids] === prop.prop_value_ids
                                ? skuPropQhAcSrc
                                : skuPropQhSrc
                            }
                          />
                        )}
                        <div className={styles.pimg}>
                          <LazyImg
                            src={`${prop.prop_main_img}${ImageSize.comment}`}
                          />
                        </div>
                        <div className={styles.ptext}>
                          <p>
                            <span>{prop.prop_value_name}</span>
                          </p>
                        </div>
                      </>
                    ) : (
                      prop.prop_value_name
                    )}
                  </div>
                ))}
              </div>
            </div>
          ))}
          {!hideQty && (
            <>
              <div className={styles.countBox}>
                <Flex align="center">
                  <div className={styles.stitle}>数量</div>
                  {num > 0 && (
                    <div className={styles.lq}>
                      限购
                      {num}件
                    </div>
                  )}
                </Flex>
                <div className={styles.counter}>
                  <Counter
                    purchaseMultiple={
                      purchaseMultiple ? +purchaseMultiple : undefined
                    }
                    purchaseNumber={
                      purchaseNumber ? +purchaseNumber : undefined
                    }
                    defaultValue={purchaseNumber ? +purchaseNumber : 1}
                    max={num || currentProduct.stock * 1}
                    onChange={counter => set({ quantity: counter })}
                  />
                </div>
              </div>
              {purchaseText}
            </>
          )}
          {+groupBuyType !== 1 &&
            group_buying_info &&
            group_buying_info.group_buying_count && (
              <div className={styles.groups}>
                {group_buying_info.group_buying_count.map((v, i) => (
                  <div
                    onClick={() =>
                      !groupDisabled &&
                      set(item => ({ ...item, groupBuyCount: v }))
                    }
                    className={`${styles['attr-item']} ${
                      styles.group
                    } ${state.groupBuyCount === v && styles.active}`}
                    key={i}
                  >
                    <PriceTag
                      value={currentProduct.ladder_price[i]}
                      type={state.groupBuyCount === v ? 'primary' : 'light'}
                      size="small"
                      style={{ marginRight: 0 }}
                    />
                    <span>{v}人团</span>
                  </div>
                ))}
              </div>
            )}
        </div>
        <div className={styles.footer}>
          {(() => {
            if (
              group_buying_info &&
              [1, 2].includes(group_buying_info.activity_status)
            ) {
              return (
                <div
                  className={`${styles['footer-btn']} ${styles['footer-btn-disabled']}`}
                >
                  活动未开始
                </div>
              )
            } else if (
              !resp.stock * 1 &&
              defaultParams.from_goods_detail &&
              resp.is_general_goods &&
              resp.onsale * 1
            ) {
              return (
                <div
                  className={`${styles['footer-btn']} ${styles['footer-btn-disabled']}`}
                >
                  商品已售罄
                </div>
              )
            } else if (!resp.stock * 1 || resp.onsale * 1 === 0) {
              return (
                <div
                  className={`${styles['footer-btn']} ${styles['footer-btn-disabled']}`}
                >
                  {(() => {
                    if (resp.advance_sale_info) {
                      return '预售已结束'
                    } else if (resp.integral_goods_info) {
                      return resp.integral_goods_info.exchange_status * 1 === 1
                        ? '今日已兑完'
                        : '已兑完'
                    } else if (resp.wait_onsale * 1) {
                      return '即将上架'
                    } else if (resp.onsale * 1 === 0) {
                      return '商品已下架'
                    } else if (!resp.stock * 1) {
                      return '商品已售罄'
                    } else {
                      return '商品已下架'
                    }
                  })()}
                </div>
              )
            } else if (
              (resp.newborn_zone_info ||
                (groupStatus === 14 && groupBuyLeaderIds)) &&
              !is_newborn
            ) {
              return (
                <div
                  className={`${styles['footer-btn']} ${styles['footer-btn-disabled']}`}
                >
                  仅限新用户购买
                </div>
              )
            } else if (resp.advance_sale_info) {
              return (
                <div
                  className={`${styles['footer-btn']} ${styles['footer-btn-presale']}`}
                  onClick={onPresale}
                >
                  {(() => {
                    if (
                      resp.advance_sale_info.activity_status === 1 ||
                      resp.advance_sale_info.activity_status === 2
                    ) {
                      return (
                        <>
                          <div
                            className={`${
                              resp.advance_sale_info.activity_status === 1
                                ? styles['text-sub-color']
                                : ''
                            }`}
                          >
                            支付定金
                          </div>
                          ¥{resp.advance_sale_info.deposit}
                        </>
                      )
                    } else if (
                      resp.advance_sale_info.activity_status === 3 ||
                      resp.advance_sale_info.activity_status === 4
                    ) {
                      return (
                        <div
                          className={`${
                            resp.advance_sale_info.activity_status === 3
                              ? styles['text-sub-color']
                              : ''
                          }`}
                        >
                          去付尾款
                        </div>
                      )
                    }
                  })()}
                </div>
              )
            } else if (
              !currentProduct.stock &&
              selectDone &&
              defaultParams.from_goods_detail
            ) {
              return (
                <div
                  className={`${styles['footer-btn']} ${styles['footer-btn-disabled']}`}
                >
                  商品已售罄
                </div>
              )
            } else if (showType === 'sku') {
              return (
                <>
                  {!resp.seckill_info &&
                  !resp.newborn_zone_info &&
                  !resp.integral_goods_info &&
                  !defaultParams.videoIds ? (
                    <div
                      onClick={onAddcart}
                      className={`${styles['footer-btn']} ${styles['footer-btn-cart']}`}
                    >
                      加入购物车
                    </div>
                  ) : null}
                  {!(resp.flash_sale_info && resp.activity_status < 3) &&
                  !(resp.is_straight_down && resp.activity_status < 3) &&
                  !(resp.gifts_info && +resp.activity_status < 3) ? (
                    <div onClick={onBuy} className={styles['footer-btn']}>
                      {resp.integral_goods_info ? '立即兑换' : '立即购买'}
                    </div>
                  ) : null}
                </>
              )
            } else {
              return (
                <div
                  onClick={() => {
                    if (
                      !!(resp.activity_status && resp.activity_status === 2) &&
                      !resp.group_buying_info
                    ) {
                      return
                    }
                    onOk()
                  }}
                  className={`${styles['footer-btn']} ${!!(
                    resp.activity_status && resp.activity_status === 2
                  ) &&
                    !resp.group_buying_info &&
                    styles['footer-btn-disabled']}`}
                >
                  确定
                </div>
              )
            }
          })()}
        </div>
      </div>
    </Modal>
  )
}

function processSkuBaseData(data) {
  const { goods_prop = [], is_seckill, is_flash_sale, newborn_zone_info } = data
  data.goods_prop = goods_prop.reduce((a, v) => {
    const attri = a.findIndex(el => el.prop_ids === v.prop_ids)
    if (attri > -1) {
      a[attri].props.push(v)
    } else {
      const prop = {
        prop_name: v.prop_name,
        prop_ids: v.prop_ids,
        id: v.id,
        props: [v]
      }
      a.push(prop)
    }
    return a
  }, [])
  // 处理商品复购价格
  if (is_seckill * 1 || is_flash_sale * 1 || newborn_zone_info) {
    data.rebuy_discount = 1
  }
  data.goods_product.forEach(product => {
    // 限时抢购商品使用限时抢购价格
    const price = NP.times(product.price * 1, data.rebuy_discount * 1)
    product.price = price
  })
}
// 获取规格可用状态
function processAttrsStatus(select, attrs, products) {
  attrs.forEach(attr => {
    attr.props.forEach(prop => {
      const _select = { ...select }
      if (prop.prop_ids in _select) delete _select[prop.prop_ids]
      const canuse = products
        .filter(product =>
          arrayInclude(product.props.split(':'), Object.values(_select))
        )
        .some(
          product =>
            product.props.indexOf(prop.prop_value_ids) > -1 && product.stock > 0
        )
      prop.canuse = canuse
    })
  })
}
