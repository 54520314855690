import { buyoutActions } from '@/actions/buyoutActions'

const initialState = {
  buyoutList: [],
  buyoutParams: { page: 1, pageSize: 10, totalPage: 99999, order: 1 },
  buyoutPosition: 0,
  buyoutNoMore: false,
  buyoutInfo: { promotion: [] },
  discountList: [],
  discountParams: { page: 1, pageSize: 10, totalPage: 99999, sort: 1 },
  discountPosition: 0,
  discountNoMore: false,
  discountInfo: {}
}

function uniqueArray(arr) {
  const rs = []
  arr.reduce((a, v) => {
    if (!a[v.ids]) {
      a[v.ids] = v.ids
      rs.push(v)
    }
    return a
  }, {})
  return rs
}

export default (state = initialState, { payload, type }) => {
  switch (type) {
    case buyoutActions.BUYOUT_LIST:
      return {
        ...state,
        buyoutList: uniqueArray(payload)
      }
    case buyoutActions.BUYOUT_PARAMS:
      return {
        ...state,
        buyoutParams: payload
      }
    case buyoutActions.BUYOUT_POSITION:
      return {
        ...state,
        buyoutPosition: payload
      }
    case buyoutActions.BUYOUT_NOMORE:
      return {
        ...state,
        buyoutNoMore: payload
      }
    case buyoutActions.BUYOUT_INFO:
      return {
        ...state,
        buyoutInfo: payload
      }
    case buyoutActions.DISCOUNT_LIST:
      return {
        ...state,
        discountList: uniqueArray(payload)
      }
    case buyoutActions.DISCOUNT_PARAMS:
      return {
        ...state,
        discountParams: payload
      }
    case buyoutActions.DISCOUNT_POSITION:
      return {
        ...state,
        discountPosition: payload
      }
    case buyoutActions.DISCOUNT_NOMORE:
      return {
        ...state,
        discountNoMore: payload
      }
    case buyoutActions.DISCOUNT_INFO:
      return {
        ...state,
        discountInfo: payload
      }
    default:
      return state
  }
}
