import { searchActions } from '@/actions/searchVideoOrLiveAction'

const initialState = {
  userList: [],
  liveList: [],
  videoList: [],
  graphicList: [],
  tab: 1,
  params: { page: 1, pageSize: 10, keyword: '' },
  resultNoMore: false,
  searchPosition: 0
}

// function uniqueArray(arr) {
//   const rs = []
//   arr.reduce((a, v) => {
//     if (!a[v.ids]) {
//       a[v.ids] = v.ids
//       rs.push(v)
//     }
//     return a
//   }, {})
//   return rs
// }

export default (state = initialState, { payload, type }) => {
  switch (type) {
    case searchActions.CHANGE_TAB:
      return {
        ...state,
        tab: payload
      }
    case searchActions.UPDATE_USERLIST:
      return {
        ...state,
        userList: payload
      }
    case searchActions.UPDATE_LIVELIST:
      return {
        ...state,
        liveList: payload
      }
    case searchActions.UPDATE_VIDEOLIST:
      return {
        ...state,
        videoList: payload
      }
    case searchActions.UPDATE_GRAPHICLIST:
      return {
        ...state,
        graphicList: payload
      }
    case searchActions.RESET_DATA:
      return {
        userList: [],
        liveList: [],
        videoList: [],
        graphicList: [],
        tab: 1,
        params: { page: 1, pageSize: 10, keyword: '' },
        resultNoMore: false,
        searchPosition: 0
      }
    case searchActions.UPDATE_POSITION:
      return {
        ...state,
        searchPosition: payload
      }
    case searchActions.UPDATE_PARAMS:
      return {
        ...state,
        params: { ...state.params, ...payload }
      }
    case searchActions.UPDATE_RESULTNOMORE:
      return {
        ...state,
        resultNoMore: payload
      }
    default:
      return state
  }
}
