import React, { useState } from 'react'
import { Modal, Button, Toast } from 'antd-mobile'
import { pluginPath } from '@/variable'
import styles from './styles.module.less'
import { signPrivacyAgreement } from '@/services/global'

function closest(el, selector) {
  const matchesSelector =
    el.matches ||
    el.webkitMatchesSelector ||
    el.mozMatchesSelector ||
    el.msMatchesSelector
  while (el) {
    if (matchesSelector.call(el, selector)) {
      return el
    }
    el = el.parentElement
  }
  return null
}

const onWrapTouchStart = e => {
  // fix touch to scroll background page on iOS
  if (!/iPhone|iPod|iPad/i.test(navigator.userAgent)) {
    return
  }
  const pNode = closest(e.target, '.am-modal-content')
  if (!pNode) {
    e.preventDefault()
  }
}

export default ({ onAgree }) => {
  const [visible, setV] = useState(true)
  const onOk = async () => {
    Toast.info('请稍后...')
    const { type, msg } = await signPrivacyAgreement()
    if (type === 1) return Toast.info(msg)
    Toast.info(msg, 1.5, () => onAgree())
  }
  const onShow = () => setV(true)
  const onCancel = () => setV(false)
  return (
    <div className={styles.page}>
      {visible ? (
        <Modal
          className={styles.modal}
          visible={visible}
          transparent
          maskClosable={false}
          onClose={onOk}
          title="隐私政策"
          footer={[
            { text: '不同意', onPress: onCancel },
            { text: '同意', onPress: onOk }
          ]}
          width={'80vw'}
          wrapProps={{ onTouchStart: onWrapTouchStart }}
        >
          <iframe
            title="隐私政策"
            className="iframe"
            src={`${pluginPath}/doc/content?type=3`}
          />
        </Modal>
      ) : (
        <div>
          <img
            alt="img"
            src={`${process.env.PUBLIC_URL}/images/order/submit_order_wating.png`}
          />
          <p>抱歉，未同意协议暂时无法使用</p>
          <Button onClick={onShow} type="primary">
            查看协议
          </Button>
        </div>
      )}
    </div>
  )
}
