import { stringify } from 'qs'
import request from '@/utils/request'
import { serverApi } from '@/variable'

// 获取订单列表信息
export function orderList(params) {
  return request(`${serverApi}/orderList?${stringify(params)}`)
}

// 获取订单待评价信息
export function toBeEvaluated(params) {
  return request(`${serverApi}/evaluate/toBeEvaluated?${stringify(params)}`)
}

// 获取订单已评价信息
export function alreadyEvaluated(params) {
  return request(`${serverApi}/evaluate/alreadyEvaluated?${stringify(params)}`)
}

// 获取订单已评价信息
export function evaluateCount(params) {
  return request(`${serverApi}/evaluate/count?${stringify(params)}`)
}

//提交退换货申请
export function memberCancelOrder(params) {
  return request(`${serverApi}/memberCancelOrder`, {
    method: 'POST',
    body: { ...params }
  })
}

// 再次购买
export function orderReBuy(params) {
  return request(`${serverApi}/orderReBuy`, {
    method: 'POST',
    body: { ...params }
  })
}

//会员删除订单
export function memberDeleteOrde(params) {
  return request(`${serverApi}/memberDeleteOrde`, {
    method: 'POST',
    body: { ...params }
  })
}

// 判断订单是否多个包裹
export function isMorePackage(params) {
  return request(`${serverApi}/isMorePackage?${stringify(params)}`)
}

// 查看订单详情
export function orderDetail(params) {
  return request(`${serverApi}/orderDetail?${stringify(params)}`)
}

// 确认收货 -- 获取最大确认收货数量
export function getMaxConfirmReceipt(params) {
  return request(`${serverApi}/getMaxConfirmReceipt?${stringify(params)}`)
}

// 确认收货 -- 确认
export function orderConfirmReceipt(params) {
  return request(`${serverApi}/orderConfirmReceipt?${stringify(params)}`)
}

// 查看添加评价详情
export function createInfo(params) {
  return request(`${serverApi}/evaluate/createInfo?${stringify(params)}`)
}

//添加评价
export function create(params) {
  return request(`${serverApi}/evaluate/create`, {
    method: 'POST',
    body: { ...params }
  })
}

//添加追评
export function additional(params) {
  return request(`${serverApi}/evaluate/additional`, {
    method: 'POST',
    body: { ...params }
  })
}

// 获取退款id
export function getRefundId(params) {
  return request(`${serverApi}/getRefundId?${stringify(params)}`)
}

// 获取退款id
export function advanceOrderList(params) {
  return request(`${serverApi}/advanceOrderList?${stringify(params)}`)
}

// 获取定金退款详情
export function getAdvanceOrderRefundDetail(params) {
  return request(
    `${serverApi}/orderRefund/getAdvanceOrderRefundDetail?${stringify(params)}`
  )
}

//预售订单数字
export function advanceOrderCount(params) {
  return request(`${serverApi}/advanceOrderCount?${stringify(params)}`)
}

//是否能申请退款
export function isApplyRefund(params) {
  return request(`${serverApi}/orderRefund/isApplyRefund?${stringify(params)}`)
}

// 延长收货时间
export function orderExtendReceiving(params) {
  return request(`${serverApi}/orderExtendReceiving`, {
    method: 'POST',
    body: { ...params }
  })
}
