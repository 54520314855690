import { myPrizeActions } from '@/actions/myPrizeActions'

const initialState = {
  list: [],
  params: { page: 1, pageSize: 10 },
  position: 0,
  listNoMore: false
}
export default (state = initialState, { payload, type }) => {
  switch (type) {
    case myPrizeActions.PRIZE_LIST:
      return {
        ...state,
        list: payload
      }
    case myPrizeActions.LIST_PARAMS:
      return {
        ...state,
        params: payload
      }
    case myPrizeActions.LIST_POSITION:
      return {
        ...state,
        position: payload
      }
    case myPrizeActions.LIST_NOMORE:
      return {
        ...state,
        listNoMore: payload
      }
    default:
      return state
  }
}
