import React, { useState, forwardRef, useImperativeHandle } from 'react'
import { Flex, Modal, Button } from 'antd-mobile'
import { useRequest } from 'ahooks'
import {
  getAddressList,
  setLatestSelectedAddress
} from '@/services/mine/address'
import history from '@/routes/history'
import SelectPng from './select.png'
import Select1Png from './select1.png'
import AddrSvg from './addr.svg'
import styles from './index.module.less'

// 地址选择
export default forwardRef(({ onChange, title }, ref) => {
  const [visible, setVisible] = useState(false)
  const [selected, setSelected] = useState(null)
  const { data, run } = useRequest(getAddressList, {
    manual: true
  })

  useImperativeHandle(ref, () => ({
    open: address => {
      setSelected(address)
      run({ pageSize: 50, page: 1 })
      setVisible(true)
    }
  }))

  const isAddress = data && data.data._list.length
  return (
    <Modal
      title={title}
      popup
      transparent
      closable
      className="send-model"
      transitionName="dialog"
      maskTransitionName="overly-mask"
      animationType="slide-up"
      visible={visible}
      onClose={() => setVisible(false)}
    >
      {isAddress ? (
        <div>
          {data.data._list.map(item => (
            <Flex
              onClick={() => {
                setSelected(item)
                if (onChange) {
                  onChange(item)
                }
                setLatestSelectedAddress({ id: item.id })
                setVisible(false)
              }}
              key={item.ids}
              className={styles.container}
              align="center"
            >
              <img
                className={styles.radio}
                src={
                  selected && String(selected.ids) === String(item.ids)
                    ? SelectPng
                    : Select1Png
                }
                alt="图片"
              />
              <Flex align="start" direction="column">
                <Flex align="center">
                  <p className={styles.addr}>{item.consignee}</p>
                  <p className={styles.mobile}>{item.mobile}</p>
                  {+item.is_default ? (
                    <p className={styles.default}>默认</p>
                  ) : null}
                </Flex>
                <p className={styles.desc}>
                  {item.province_name}
                  {item.city_name}
                  {item.district_name}
                  {item.address}
                </p>
                {item.warning_content && (
                  <p className={styles.warn}>{item.warning_content}</p>
                )}
              </Flex>
            </Flex>
          ))}
        </div>
      ) : (
        <Flex className={styles.none} align="center" justify="center">
          <Flex direction="column">
            <img src={AddrSvg} alt="logo" />
            <p className={styles.desc}>您还没有收货地址可选择哦~</p>
          </Flex>
        </Flex>
      )}
      <Flex align="center" justify="center" className={styles.footer}>
        <Button
          onClick={() => history.push(`/mine/address/add?type=cart`)}
          className={styles.btn}
          type="primary"
        >
          {isAddress ? '新增收货地址' : '选择其他地址'}
        </Button>
      </Flex>
    </Modal>
  )
})
