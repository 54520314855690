import React, { useState } from 'react'
import { Flex } from '@/components'
import { goodsCancelCollect, goodsCollect } from '@/services/global'
import { Toast } from 'antd-mobile'
import StarSvg from './star.svg'
import CollecteSvg from './collecte.svg'
import styles from './index.module.less'

export const Collect = ({ is_collect, goodsIds, goodsId }) => {
  const [isCollect, setIsCollect] = useState(is_collect === '1')
  const updateCollect = ({ type, msg }) => {
    if (type === 1) return Toast.info(msg)
    if (isCollect) {
      Toast.info('已取消收藏')
    } else {
      Toast.info('已成功添加到收藏列表，可在”我的-商品收藏”查看和管理哦')
    }
    setIsCollect(v => {
      return !v
    })
  }
  return (
    <>
      <div className={styles.collect}>
        <Flex
          onClick={() => {
            if (isCollect) {
              goodsCancelCollect({ goodsIds: goodsIds }).then(res =>
                updateCollect(res)
              )
            } else {
              goodsCollect({ goodsId }).then(res => updateCollect(res))
            }
          }}
          justify="center"
          align="center"
          direction="column"
        >
          <img
            alt="logo"
            className={styles.img}
            src={isCollect ? CollecteSvg : StarSvg}
          />
          <p className={styles.text}>收藏</p>
        </Flex>
      </div>
    </>
  )
}
