import React from 'react'
import Img from './404.png'
import styles from './styles.module.less'
import { app_download_url_qq } from '@/components/GlobalHeaderAlert'
export default () => (
  <div className={styles.page}>
    <img alt="" src={Img} />
    <div className={styles.text}>SORRY,您访问的页面弄丢了~</div>
    <a className={styles.btn} href={app_download_url_qq}>
      下载APP
    </a>
    <span>何以解忧 唯有购宜购</span>
  </div>
)
