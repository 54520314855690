import { staticPath } from '@/variable'
import React from 'react'

const Image = ({ src, cdn, imageView, ...props }) => {
  let finallySrc = src || ''
  if (cdn) {
    finallySrc = `${staticPath}/bg/${src}`
  } else if (
    finallySrc.startsWith('upload/') ||
    finallySrc.startsWith('members/') ||
    finallySrc.startsWith('invite/') ||
    finallySrc.startsWith('goodsTbkQrcode/') ||
    finallySrc.startsWith('guomai/') ||
    finallySrc.startsWith('system/') ||
    finallySrc.startsWith('distributorQrcode/')
  ) {
    finallySrc = `${staticPath}/${src}`
  }

  if (finallySrc.includes(staticPath) && !!imageView) {
    finallySrc += `?imageView2/2/${imageView}`
  }

  return <img src={finallySrc} alt="img" {...props} />
}

export default Image
