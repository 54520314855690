import { submitOrder } from '@/services/global'
const REQUEST = 'confirmOrder/REQUEST'
const OK = 'confirmOrder/OK'
const ERROR = 'confirmOrder/ERROR'

export const confirmOrderActions = {
    REQUEST,
    OK,
    ERROR,
};

const getConfrimOrderRequest = () => ({
    type: REQUEST,
});

const getConfrimOrderOk = payload => ({
    type: OK,
    payload,
});

const getConfrimOrderError = payload => ({
    type: ERROR,
    payload,
})

const getConfrimOrder = (params={}) => async dispatch => {
    dispatch(getConfrimOrderRequest())
    const res = await submitOrder(params)
    if (res.type === 1) {
        dispatch(getConfrimOrderError())
    } else {
        res.data.orderGoods.forEach(el => {
            el.propsStr = el.propsStr.replace(':', ';')
        })
        res.data.memberCoupon.forEach(el => {
            el.start_time = el.start_time.split(' ')[0]
            el.end_time = el.end_time.split(' ')[0]
        })
        dispatch(getConfrimOrderOk(res.data))
    }
    return res
}

export {
    getConfrimOrder,
}