import React from 'react'

export default ({ sign, value, pointerClass, intClass, floatClass }) => {
  const notNumber = isNaN(parseFloat(value, 10))
  if (notNumber) return 0
  const [intNum, floatNum] = value.toString().split('.')
  const sys = floatNum !== undefined ? '.' : ''
  const floatNumStr = floatNum !== undefined ? floatNum : ''
  return (
    <>
      {sign}
      <span className={intClass}>{intNum}</span>
      {sys.length > 0 && <span className={pointerClass}>{sys}</span>}
      {sys.length > 0 && <span className={floatClass}>{floatNumStr}</span>}
    </>
  )
}
