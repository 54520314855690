import { accountActions } from '@/actions/accountActions'

const initialState = {
  accountList: [],
  params: { page: 1, pageSize: 20, type: 0, index: 0 },
  position: 0,
  listNoMore: false
}
export default (state = initialState, { payload, type }) => {
  switch (type) {
    case accountActions.ACCOUNT_LIST:
      return {
        ...state,
        accountList: payload
      }
    case accountActions.ACCOUNT_PARAMS:
      return {
        ...state,
        params: payload
      }
    case accountActions.ACCOUNT_POSITION:
      return {
        ...state,
        position: payload
      }
    case accountActions.ACCOUNT_NOMORE:
      return {
        ...state,
        listNoMore: payload
      }
    case accountActions.ACCOUNT_RESET:
      return {
        accountList: [],
        params: { page: 1, pageSize: 20, type: 0, index: 0 },
        position: 0,
        listNoMore: false
      }
    default:
      return state
  }
}
