import { applyMiddleware, compose, createStore } from 'redux'
import thunk from 'redux-thunk'
// import { routerMiddleware, connectRouter } from 'connected-react-router'

import rootReducer from '@/reducers'

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const store = history =>
  createStore(rootReducer(history), composeEnhancer(applyMiddleware(thunk)))

export default store
