import create from 'zustand'
import { useRequest } from 'ahooks'
import { useEffect } from 'react'
import {
  getFirstGoodsGroupListV2,
  getSecondGoodsGroupListV2,
  getThirdGoodsGroupListV2
} from '@/services/category'

const useStore = create(set => ({
  currentChecked: '',
  secondChecked: '',
  thirdChecked: '',
  type: '',
  sort: 0,
  firstGroup: undefined,
  secondGroup: undefined,
  thirdGroup: undefined,
  activedIndex: 0,
  updateChecked: data => set(() => ({ currentChecked: data })),
  updateSecondChecked: data => set(() => ({ secondChecked: data })),
  updateThirdChecked: data => set(() => ({ thirdChecked: data })),
  setType: data => set(() => ({ type: data })),
  refreshSortType: data => set(() => ({ sort: data })),
  setFirstGroup: data => set(() => ({ firstGroup: data })),
  setSecondGroup: data => set(() => ({ secondGroup: data })),
  setThirdGroup: data => set(() => ({ thirdGroup: data })),
  setActivedIndex: data => set(() => ({ activedIndex: data }))
}))

/** 分类选中数据 */
const useCategory = () => {
  const {
    updateChecked,
    updateSecondChecked,
    updateThirdChecked,
    setType,
    firstGroup,
    setFirstGroup,
    setSecondGroup,
    setThirdGroup,
    secondGroup,
    thirdGroup,
    activedIndex
  } = useStore(store => store)
  const { run: firstGroupRun } = useRequest(getFirstGoodsGroupListV2, {
    manual: true,
    onSuccess: res => {
      updateChecked(res.data[0].ids)
      setFirstGroup(res)
      getSecondGroup({ firstGroupIds: res.data[0].ids })
    }
  })
  const { run: getSecondGroup } = useRequest(getSecondGoodsGroupListV2, {
    manual: true,
    onSuccess: res => {
      updateSecondChecked(res.data[0].ids)
      getThirdGroup({ secondGroupIds: res.data[0].ids })
      updateThirdChecked('')
      setSecondGroup(res)
    }
  })

  const { run: getThirdGroup } = useRequest(getThirdGoodsGroupListV2, {
    manual: true,
    onSuccess: async res => {
      await setType(String(res.data.third_list[0].type))
      updateThirdChecked(
        String(res.data.third_list[0].type) === '1'
          ? res.data.third_list[0].supplier_ids
          : res.data.third_list[0].ids
      )
      setThirdGroup(res)
    }
  })
  useEffect(() => {
    if (!firstGroup) {
      firstGroupRun()
    }
  }, [])
  const moveFn = () => {
    setTimeout(() => {
      if (!firstGroup) {
        return
      }
      const idx =
        activedIndex === firstGroup.data.length - 1
          ? activedIndex
          : activedIndex + 1
      const targetNode = document.querySelector(`.scroll-${idx}`)

      targetNode.scrollIntoView({
        block: 'center',
        left: 240
      })
    }, 10)
  }
  useEffect(() => {
    moveFn()
  }, [activedIndex])
  return {
    ...useStore(store => store),
    firstGroup,
    secondGroup,
    thirdGroup,
    getSecondGroup,
    getThirdGroup,
    moveFn
  }
}

export default useCategory
