import { integralActions } from '@/actions/integralActions'

const initialState = {
  integralList: [],
  integralParams: { page: 1, pageSize: 20, totalPage: 99999, type: 0 },
  integralPosition: 0,
  integralNoMore: false
}

function uniqueArray(arr) {
  const rs = []
  arr.reduce((a, v) => {
    if (!a[v.ids]) {
      a[v.ids] = v.ids
      rs.push(v)
    }
    return a
  }, {})
  return rs
}

export default (state = initialState, { payload, type }) => {
  switch (type) {
    case integralActions.INTEGRAL_LIST:
      return {
        ...state,
        integralList: uniqueArray(payload)
      }
    case integralActions.INTEGRAL_PARAMS:
      return {
        ...state,
        integralParams: payload
      }
    case integralActions.INTEGRAL_POSITION:
      return {
        ...state,
        integralPosition: payload
      }
    case integralActions.INTEGRAL_NOMORE:
      return {
        ...state,
        integralNoMore: payload
      }
    default:
      return state
  }
}
