import { getCurrentUser } from '@/services/global'
const REQUEST = 'auth/REQUEST'
const OK = 'auth/OK'
const ERROR = 'auth/ERROR'

export const authActions = {
    REQUEST,
    OK,
    ERROR,
};

const getAuthRequest = () => ({
    type: REQUEST,
});

const getAuthOk = payload => ({
    type: OK,
    payload,
});

const getAuthError = payload => ({
    type: ERROR,
    payload,
})

const getAuth = () => async dispatch => {
    dispatch(getAuthRequest())
    const { type, msg, data } = await getCurrentUser()
    if (type === 1) return getAuthError()
    console.log(msg)
    return getAuthOk(data)
}

export {
    getAuth,
}