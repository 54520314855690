import {
  getProductList,
  getSearchBoxItem,
  getSearchHotItem
} from '@/services/global'
const PARAMS = 'searchResult/PARAMS'
const SCROLLTOP = 'searchResult/SCROLLTOP'
const REQUEST = 'searchResult/REQUEST'
const OK = 'searchResult/OK'
const ERROR = 'searchResult/ERROR'
const RESET = 'searchResult/RESET'
const SEARCH_BOX = 'searchResult/SEARCH_BOX'
const SEARCH_HOT = 'searchResult/SEARCH_HOT'
const SEARCH_LIST_INDEX = 'searchResult/SEARCH_LIST_INDEX'

export const searchResultActions = {
  PARAMS,
  SCROLLTOP,
  REQUEST,
  OK,
  ERROR,
  RESET,
  SEARCH_BOX,
  SEARCH_HOT,
  SEARCH_LIST_INDEX
}

const setSearchResultReset = () => ({
  type: RESET
})

const setSearchListIndex = payload => ({
  type: SEARCH_LIST_INDEX,
  payload
})

const setSearchResultParams = payload => ({
  type: PARAMS,
  payload
})

const setScrollTop = payload => ({
  type: SCROLLTOP,
  payload
})

const getSearchResultRequest = payload => ({
  type: REQUEST,
  payload
})

const getSearchResultOk = payload => ({
  type: OK,
  payload
})

const getSearchResultError = payload => ({
  type: ERROR,
  payload
})

const getSearchResult = (params = {}) => async dispatch => {
  dispatch(getSearchResultRequest(params))
  const { type, msg, data } = await getProductList(params)
  if (type === 1) return getSearchResultError(msg)
  dispatch(getSearchResultOk(data))
}

const getSearchBox = (params = {}) => async dispatch => {
  const { type, data } = await getSearchBoxItem(params)
  if (type === 1) return
  dispatch({ type: SEARCH_BOX, payload: data.search_list })
}
const getSearchHot = (params = {}) => async dispatch => {
  const { type, data } = await getSearchHotItem(params)
  if (type === 1) return
  dispatch({ type: SEARCH_HOT, payload: data })
}

export {
  getSearchResult,
  setSearchResultParams,
  setSearchResultReset,
  setSearchListIndex,
  setScrollTop,
  getSearchHot,
  getSearchBox
}
