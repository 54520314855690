const HOST_LIST = 'hostList/HOST_LIST'
const LIST_PARAMS = 'hostList/LIST_PARAMS'
const LIST_POSITION = 'hostList/LIST_POSITION'
const LIST_NOMORE = 'hostList/LIST_NOMORE'

export const hostListActions = {
  HOST_LIST,
  LIST_PARAMS,
  LIST_POSITION,
  LIST_NOMORE
}

const updateHostList = payload => {
  return {
    type: HOST_LIST,
    payload
  }
}

const updateHostListParams = payload => ({
  type: LIST_PARAMS,
  payload
})

const updateHostListPosition = payload => ({
  type: LIST_POSITION,
  payload
})

const updateHostListNomore = payload => ({
  type: LIST_NOMORE,
  payload
})

export {
  updateHostList,
  updateHostListParams,
  updateHostListPosition,
  updateHostListNomore
}
