import { appEnvActions } from '@/actions/appEnvActions'

const initialState = false

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case appEnvActions.OK:
      return payload
    default:
      return state
  }
}
