const ACCOUNT_LIST = 'account/ACCOUNT_LIST'
const ACCOUNT_PARAMS = 'account/ACCOUNT_PARAMS'
const ACCOUNT_POSITION = 'account/ACCOUNT_POSITION'
const ACCOUNT_NOMORE = 'account/ACCOUNT_NOMORE'
const ACCOUNT_RESET = 'account/ACCOUNT_RESET'

export const accountActions = {
  ACCOUNT_LIST,
  ACCOUNT_PARAMS,
  ACCOUNT_POSITION,
  ACCOUNT_NOMORE,
  ACCOUNT_RESET
}

const updateAccountList = payload => {
  return {
    type: ACCOUNT_LIST,
    payload
  }
}

const updateAccountParams = payload => ({
  type: ACCOUNT_PARAMS,
  payload
})

const updateAccountPosition = payload => ({
  type: ACCOUNT_POSITION,
  payload
})

const updateAccountNomore = payload => ({
  type: ACCOUNT_NOMORE,
  payload
})

const resetAccount = payload => ({
  type: ACCOUNT_RESET,
  payload
})

export {
  updateAccountList,
  updateAccountParams,
  updateAccountPosition,
  updateAccountNomore,
  resetAccount
}
