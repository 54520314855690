/**
 * 全局任务调度处理
 * 1.三种立即执行任务:
 *  a. 浏览商品页面
 *  b. 观看视频
 *  c. 观看直播
 * 2.需在积分任务列表由用户主动触发“去完成”的按钮
 *
 */
import React from 'react'
import { useEffect, useRef, useMemo, useState, useCallback } from 'react'
import { useLocalStorage } from 'react-use'
import { withRouter } from 'react-router'
import { getTruthTime } from '@/utils/utils'
import { useSelector } from 'react-redux'
import { finishTask } from '@/services/mine/integral'
import { PointsCountdown } from '@/features'

function getCurrentDate() {
  const time = getTruthTime()
  const d = new Date(time)
  return `${d.getFullYear()}/${d.getMonth() + 1}/${d.getDate()}`
}
// 常规任务
const JobStatistical = {
  // 本地缓存数据刷新频率
  updateRete: 5,
  // 常规任务key
  key: 'UserJobStatistical',
  initailValue: {
    date: getCurrentDate(),
    video: 0,
    live: 0,
    goodsDetail: 0
  }
}
// 浏览特定商品
export const GoodsJobStatistical = {
  key: 'UserJobStatistical_Goods',
  initailValue: {
    ids: '',
    task_index: 60,
    count: 0
  }
}
// 浏览特定页面
export const PageJobStatistical = {
  key: 'UserJobStatistical_Page',
  initailValue: {
    url: '',
    task_index: 60,
    count: 0
  }
}

const isNeedSendRequest = (data, rate) => {
  if (data * 1 > 0 && (data % 60) / parseInt(rate) === 0) return true
  return false
}

export default withRouter(({ location }) => {
  const timerRef = useRef(null)
  const { data: user = {} } = useSelector(state => state.user)
  const [saveHref, setSaveHref] = useState(false)
  // 判断是否需要显示倒数计时
  const [isProgress, setIsProgress] = useState('')
  // 任务请求频率
  const jobRequestRate = useMemo(
    () =>
      user.shop_config
        ? user.shop_config.integrate_task_request_frequency * 1
        : 1,
    [user]
  )

  const [data, set] = useLocalStorage(
    JobStatistical.key,
    JobStatistical.initailValue
  )
  const [pageData, setPageData] = useLocalStorage(
    PageJobStatistical.key,
    PageJobStatistical.initailValue
  )
  const [goodsData, setGoodsData] = useLocalStorage(
    // GoodsJobStatistical.goodsKey,
    GoodsJobStatistical.key,
    GoodsJobStatistical.initailValue
  )

  const updatePageData = v => {
    setSaveHref(true)
    setPageData(ov => ({ ...ov, ...v }))
  }
  const updateGoodsData = v => {
    setSaveHref(true)
    setGoodsData(ov => ({ ...ov, ...v }))
  }
  // hack 全局透传
  window.___JobStatistical_setPageData = updatePageData
  window.___JobStatistical_setGoodsData = updateGoodsData

  useEffect(() => {
    // 无任务或者非今日任务 重置任务记录
    if (!data || data.date !== JobStatistical.initailValue.date) {
      set(JobStatistical.initailValue)
    }
  }, [])

  useEffect(() => {
    if (isNeedSendRequest(data.goodsDetail, jobRequestRate)) {
      console.log('浏览商品详情满一分钟')
      const taskParam = { task_code: 10009, value: jobRequestRate }
      finishTask(taskParam)
    }
    if (isNeedSendRequest(data.live, jobRequestRate)) {
      console.log('观看直播满一分钟')
      const liveIds = location.pathname.replace('/live/detail/', '')
      finishTask({ task_code: 10005, value: jobRequestRate, live_ids: liveIds })
    }
    if (isNeedSendRequest(data.video, jobRequestRate)) {
      console.log('观看视频满一分钟')
      const liveIds = location.pathname.replace('/live/detail/', '')
      finishTask({ task_code: 10007, value: jobRequestRate, live_ids: liveIds })
    }
  }, [data])

  // 特殊任务监听
  /* useEffect(() => {
    if (goodsData.count >= goodsData.task_index * 1) {
      finishTask({
        task_code: 10014,
        value: goodsData.task_index,
        outer_ids: goodsData.ids
      })
      setGoodsData(GoodsJobStatistical.initailValue)
      clear()
    }
    if (pageData.count >= pageData.task_index * 1) {
      finishTask({
        task_code: 10015,
        value: pageData.task_index,
        outer_ids: pageData.ids
      })
      setPageData(PageJobStatistical.initailValue)
      clear()
    }
  }, [goodsData, pageData]) */
  const onComplete = useCallback(() => {
    if (goodsData.ids) {
      finishTask({
        task_code: 10014,
        value: goodsData.task_index,
        outer_ids: goodsData.ids
      })
      setGoodsData(GoodsJobStatistical.initailValue)
      clear()
    }
    if (pageData.ids) {
      finishTask({
        task_code: 10015,
        value: pageData.task_index,
        outer_ids: pageData.ids
      })
      setPageData(PageJobStatistical.initailValue)
      clear()
    }
  }, [goodsData, pageData])

  useEffect(() => {
    // 根据location做任务记录
    if (saveHref) {
      // 设置是否显示倒计时
      if (isProgress === '') {
        setIsProgress(location.pathname)
      } else {
        setIsProgress('')
        setSaveHref(false)
      }
    }
    if (location.pathname === '/goods/detail') {
      timerRef.current = setInterval(() => {
        set(v => ({
          ...v,
          goodsDetail: v.goodsDetail + JobStatistical.updateRete
        }))
        // 特定商品浏览任务
        if (
          goodsData &&
          goodsData.ids &&
          location.search.indexOf(goodsData.ids) > -1
        ) {
          setGoodsData(v => ({
            ...v,
            count: v.count + JobStatistical.updateRete
          }))
        }
      }, JobStatistical.updateRete * 1000)
    } else if (location.pathname.indexOf('/video/') === 0) {
      timerRef.current = setInterval(() => {
        set(v => ({ ...v, video: v.video + JobStatistical.updateRete }))
      }, JobStatistical.updateRete * 1000)
    } else if (location.pathname.indexOf('/live/detail/') === 0) {
      timerRef.current = setInterval(() => {
        set(v => ({ ...v, live: v.live + JobStatistical.updateRete }))
      }, JobStatistical.updateRete * 1000)
    } else if (
      !!pageData.url &&
      window.location.href.indexOf(pageData.url) > -1
    ) {
      // 特定页面任务
      timerRef.current = setInterval(() => {
        setPageData(v => ({ ...v, count: v.count + JobStatistical.updateRete }))
      }, JobStatistical.updateRete * 1000)
    }

    return () => clear()
  }, [location])

  const clear = () => {
    if (timerRef.current) clearInterval(timerRef.current)
    timerRef.current = null
  }

  return !!isProgress ? <PointsCountdown onComplete={onComplete} /> : null
})
