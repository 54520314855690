import React from 'react'
import styles from './styles.module.less'
import PropTypes from 'prop-types'
import { Toast } from 'antd-mobile'

export default class Counter extends React.Component {
  state = {
    count: 0
  }

  async componentDidMount() {
    const { value, defaultValue = 1 } = this.props
    await this.setState({ count: value || defaultValue })
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.value !== this.props.value) {
      this.setState({ count: nextProps.value })
    }
  }

  onAdd = async e => {
    e.preventDefault()
    const { max = 999, step = 1, purchaseNumber, purchaseMultiple } = this.props
    const count = getCounterValue({
      max,
      step,
      type: 'plus',
      purchaseNumber,
      purchaseMultiple,
      quantity: this.state.count
    })
    this.setState({ count }, () => this.props.onChange(count))
    e.persist()
  }

  onReduce = async e => {
    e.preventDefault()
    const { max = 999, step = 1, purchaseNumber, purchaseMultiple } = this.props
    const count = getCounterValue({
      max,
      step,
      type: 'minus',
      purchaseNumber,
      purchaseMultiple,
      quantity: this.state.count
    })
    this.setState({ count }, () => this.props.onChange(count))
    e.persist()
  }

  onChange = async e => {
    let v = e.target.value
    const { min, max } = this.props
    if (v === '') v = min
    if (v - parseInt(v) !== 0) return
    var count = v * 1
    if (v < min) count = min
    if (v > max) count = max
    await this.setState({ count })
    this.props.onChange(count)
  }
  disabledHandler = () => {
    if (this.props.isCart)
      return Toast.info('该商品为预售商品，请到商品详情页重新下单')
    Toast.info('数量不可更改哦～', 2, null, false)
  }

  render() {
    const { count } = this.state
    let { min = 1, max = 999 } = this.props
    const { disabled, purchaseMultiple, purchaseNumber } = this.props
    min = +purchaseNumber || min
    return (
      <div className={styles.count}>
        <div
          onClick={disabled ? this.disabledHandler : this.onReduce}
          className={`${styles.reduce} ${styles.btn}`}
        >
          <svg className={`icon`}>
            <use xlinkHref={`#icon-move`} />
          </svg>
        </div>
        <input
          type="number"
          pattern="\d*"
          value={String(count)}
          min={min}
          max={max}
          disabled={disabled || +purchaseMultiple > 1 || +purchaseNumber > 1}
          onChange={this.onChange}
        />
        <div
          onClick={disabled ? this.disabledHandler : this.onAdd}
          className={`${styles.add} ${styles.btn}`}
        >
          <svg className={`icon`}>
            <use xlinkHref={`#icon-add`} />
          </svg>
        </div>
      </div>
    )
  }
}

Counter.propTypes = {
  // defaultValue: PropTypes.number,
  min: PropTypes.number,
  max: PropTypes.number,
  step: PropTypes.number,
  isCart: PropTypes.bool,
  onChange: PropTypes.func.isRequired
}

// 获取counter value
function getCounterValue({
  purchaseNumber = 1,
  step: _step = 1,
  purchaseMultiple = 1,
  quantity,
  max = 999,
  type
}) {
  const startNum = +purchaseNumber || _step || 1
  const multiple = +purchaseMultiple || 1

  let step = multiple

  // 设置倍数购买
  if (multiple > 1) {
    const multipleEffectNum = startNum * multiple
    if (type === 'plus' && quantity < multipleEffectNum) {
      step = multipleEffectNum - quantity
    } else if (type === 'minus' && quantity === multipleEffectNum) {
      step = (multiple - 1) * startNum
    } else {
      step = startNum
    }

    let plusValue = quantity + step
    if (plusValue > max) {
      step = plusValue - quantity
    }
    const modulo = max % startNum
    if (quantity === max && modulo !== 0) {
      step = modulo
    }
  }

  let value = type === 'plus' ? quantity + step : quantity - step

  if (value < startNum) value = startNum
  if (value > max) value = max
  return value
}
