const VIDEO_STATUST = 'statistic/VIDEO_STATUST'

export const statisticActions = {
  VIDEO_STATUST
}

const setStatisticVideoStatus = payload => ({
  type: VIDEO_STATUST,
  payload
})

export { setStatisticVideoStatus }
