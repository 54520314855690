import {
  getNavigation,
  getAppFeature as appFeature,
  getNewHome
} from '@/services/global'
import { designPromotion } from '@/containers/SalesPromotion/service'
import { Toast } from 'antd-mobile'

const REQUEST_NAVIGATION = 'home/REQUEST_NAVIGATION'
const OK_NAVIGATION = 'home/OK_NAVIGATION'
const ERROR_NAVIGATION = 'home/ERROR_NAVIGATION'

const REQUEST_FEATURE = 'home/REQUEST_FEATURE'
const OK_FEATURE = 'home/OK_FEATURE'
const ERROR_FEATURE = 'home/ERROR_FEATURE'
const CURRENT_FEATURE = 'home/CURRENT_FEATURE'

const SET_SCROLL = 'home/SET_SCROLL'
const SET_BGCOLOR = 'home/SET_BGCOLOR'

export const homeActions = {
  REQUEST_NAVIGATION,
  OK_NAVIGATION,
  ERROR_NAVIGATION,
  REQUEST_FEATURE,
  CURRENT_FEATURE,
  ERROR_FEATURE,
  SET_SCROLL,
  SET_BGCOLOR,
  OK_FEATURE
}
// 获取首页tabs数据
const getNavigationData = params => async dispatch => {
  dispatch({ type: REQUEST_NAVIGATION })
  try {
    const { data, type } = await getNavigation(params)
    if (type === 1) return dispatch({ type: ERROR_NAVIGATION })
    data.forEach(el => (el.data = undefined))
    const { tab = 0 } = params
    // 获取当前tab的页面数据
    const { data: fdata } =
      tab * 1 === 0
        ? await getNewHome({ needHome: 1 })
        : data[tab].type * 1 === 1
        ? await designPromotion({
            design_promotion_ids: data[tab].design_feature_ids
          })
        : await appFeature({ ids: data[tab].design_feature_ids })
    data[tab].data = fdata
    dispatch({ type: OK_NAVIGATION, payload: data })
  } catch (error) {
    dispatch({ type: ERROR_NAVIGATION })
  }
}
// 获取首页微页面数据
const getAppFeatureData = ({ key, ids, type: pageType }) => async dispatch => {
  dispatch({ type: REQUEST_FEATURE, payload: { key, ids } })
  try {
    Toast.info('数据获取中...', null)
    const { data, type } =
      key * 1 === 0
        ? await getNewHome({ needHome: 1 })
        : pageType * 1 === 1
        ? await designPromotion({ design_promotion_ids: ids })
        : await appFeature({ ids })
    Toast.hide()
    if (type === 1) {
      return dispatch({ type: ERROR_FEATURE, payload: { key, ids } })
    }
    dispatch({ type: OK_FEATURE, payload: { data, key } })
  } catch (error) {
    dispatch({ type: ERROR_FEATURE, payload: { key, ids } })
  }
}
// 记录当前tab index
const setFeatureCurrent = payload => ({
  type: CURRENT_FEATURE,
  payload
})
//保存页面当前位置
const setFeatureScrollPos = payload => ({
  type: SET_SCROLL,
  payload
})
// 设置新首页的背景色
const setNavigationBgColor = payload => ({
  type: SET_BGCOLOR,
  payload
})

export {
  setFeatureScrollPos,
  setFeatureCurrent,
  getNavigationData,
  getAppFeatureData,
  setNavigationBgColor
}
