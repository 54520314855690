import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Modal, Toast } from 'antd-mobile'
import { getModalClicks, popupNew } from '@/services/global'
import CustomizedLink from '@/components/CustomizedLink'
import {
  closeHomePageModal,
  closeHomePageSmallModal
} from '@/actions/globalActions'
import LazyImg from '@/components/LazyImg'
import styles from './styles.module.less'
import MouseIndex from './mouseIndex'
import { withRouter } from 'react-router'

const blackRouteList = ['/dynamic/topic/coupon-share']

const ModalContain = ({ location, ...props }) => {
  const [info, setInfo] = useState(null)
  useEffect(() => {
    if (blackRouteList.find(el => location.pathname.indexOf(el) > -1)) return
    const getData = async () => {
      const { data, type, msg } = await popupNew()
      if (type * 1 === 1) return Toast.info(msg, 2, null, false)
      setInfo(data)
    }
    getData()
  }, [])
  if (!info) return null
  return (
    <>
      {props.show_homePage_modal && (
        <HomePageModal
          modalData={info.pic_info}
          closeHomePageModal={props.closeHomePageModal}
        />
      )}
      {props.show_homePage_small_modal ? (
        <MouseIndex
          modalData={info.thumb_info}
          closeHomePageSmallModal={props.closeHomePageSmallModal}
        />
      ) : null}
    </>
  )
}

const HomePageModal = ({ modalData, closeHomePageModal }) => {
  const [visible, setVisible] = useState(true)

  const goTo = async () => {
    const { type, msg } = await getModalClicks({ ids: modalData.ids * 1 })
    if (type === 1) return Toast.fail(msg)
    setVisible(false)
  }
  if (
    JSON.stringify(modalData) === '{}' ||
    !modalData ||
    modalData.pops * 1 === 0
  ) {
    return null
  }
  return (
    <>
      <Modal visible={visible} popup className={styles.wrapper} transparent>
        <div className={styles.box}>
          <div className={styles.container}>
            <CustomizedLink
              type={modalData.link_type}
              outlink={modalData.outlink}
              onClick={() =>
                window.JAnalyticsCevent('2020.02.13-home-activitydialog')
              }
            >
              <div className={styles.imgWrapper} onClick={goTo}>
                <LazyImg
                  className={styles.image}
                  noStaticPath={
                    !modalData.pic.url || /^https?:\/\//.test(modalData.pic.url)
                  }
                  src={modalData.pic.url}
                />
              </div>
            </CustomizedLink>
            <div
              onClick={() => closeHomePageModal()}
              className={styles.close}
            />
          </div>
        </div>
      </Modal>
    </>
  )
}

// 挂载redux
// const mapDispatchToState = state => ({
//   global: state.global
// })
const mapDispatchToProps = dispatch => ({
  closeHomePageModal: () => dispatch(closeHomePageModal()),
  closeHomePageSmallModal: () => dispatch(closeHomePageSmallModal())
})
export default connect(null, mapDispatchToProps)(withRouter(ModalContain))
