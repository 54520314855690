import { getVideoCategorys } from '@/services/discover'

const REQUEST = 'videoActions/REQUEST'
const CATEGORYS = 'videoActions/CATEGORYS'
const SERVICE = 'videoActions/SERVICE'
const PARAMS = 'videoActions/PARAMS'
const DONE = 'videoActions/DONE'
const ERROR = 'videoActions/ERROR'
const POSITION = 'videoActions/POSITION'
const NOMORE = 'videoActions/NOMORE'
const COUNT = 'videoActions/COUNT'

export const videoActions = {
  SERVICE,
  CATEGORYS,
  REQUEST,
  PARAMS,
  DONE,
  ERROR,
  POSITION,
  NOMORE,
  COUNT
}

const setVideoListPos = payload => ({ type: POSITION, payload })
const setVideoListParams = payload => ({ type: PARAMS, payload })
const setVideoListService = payload => ({ type: SERVICE, payload })

const getVideoCategory = () => async dispatch => {
  const { data, type } = await getVideoCategorys({ type: 0 })
  if (type === 1) return
  dispatch({ type: CATEGORYS, payload: data })
}
const getVideoListData = (service, params, cb) => async dispatch => {
  // 保留当前请求的service和params 给视频页面备用
  dispatch({ type: REQUEST, payload: { service, params } })
  const {
    data: { _list = [], _page = {} },
    type,
    msg
  } = await service(params)
  if (type === 1) return dispatch({ type: ERROR, payload: msg })
  dispatch({ type: DONE, payload: _list })
  if (_page.page >= _page.totalPage) {
    dispatch({ type: NOMORE, payload: true })
  } else {
    dispatch({ type: NOMORE, payload: false })
  }
  dispatch({ type: COUNT, payload: _page.totalCount })
  cb && cb()
}

// const initParams = { page: 1, pageSize: 10, totalPage: 99999 }

export {
  setVideoListService,
  setVideoListPos,
  getVideoListData,
  getVideoCategory,
  setVideoListParams
}
