import { stringify } from 'qs'
import request from '@/utils/request'
import { serverApi, serverNodeApi, videoApi } from '@/variable'

// 获取首页数据
export function getHomePageData() {
  return request(`${serverApi}/navigation`)
}

// 获取商品列表
export function getProductList(params) {
  return request(`${serverApi}/goodsList?${stringify(params)}`)
}
// 购宜购热搜列表（不分页）
export function getGoodsHotSearchList(params) {
  return request(
    `${serverApi}/goods/getGoodsHotSearchList?${stringify(params)}`
  )
}
// 获取商品详情
export function getProductDetail(params) {
  return request(`${serverApi}/goodsDetail?${stringify(params)}`)
}
// 购宜购热搜点击统计
export function goodsHotSearchClick(params) {
  return request(`${serverApi}/goods/goodsHotSearchClick`, {
    method: 'POST',
    body: { ...params }
  })
}
// 获取商品详情 -----> 预览
export function getProductDetailPreview(params) {
  return request(`${serverApi}/preGoodsDetail?${stringify(params)}`)
}
// 收藏商品
//goodsIds
export function goodsCollect(params) {
  return request(`${serverApi}/goodsCollect`, {
    method: 'POST',
    body: { ...params }
  })
}
// 取消收藏商品
export function goodsCancelCollect(params) {
  return request(`${serverApi}/goodsCancelCollect`, {
    method: 'POST',
    body: { ...params }
  })
}
// 获取搭配商品
export function goodsMatchRelation(params) {
  return request(`${serverApi}/goods/goodsMatchRelation?${stringify(params)}`)
}
// 获取商品评论
export function getGoodsComment(params) {
  return request(`${serverApi}/evaluate/list?${stringify(params)}`)
}
// 商品置顶评价列表
export function getTopList(params) {
  return request(`${serverApi}/evaluate/top_list?${stringify(params)}`)
}
// 获取商品评论统计
// goodsIds
export function getGoodsCommentCount(params) {
  return request(`${serverApi}/evaluate/evaluatedCount?${stringify(params)}`)
}
// 评论点赞
export function commentLike(params) {
  return request(`${serverApi}/evaluate/agreeNum?${stringify(params)}`)
}
// 获取商品sku信息
export function getProductSku(params) {
  return request(`${serverApi}/prop?${stringify(params)}`)
}
// 获取商品优惠券信息
export function getProductCoupon(params) {
  return request(`${serverApi}/getCouponList?${stringify(params)}`)
}
// 会员领取优惠券 couponId
export function createMemberCoupon(params) {
  return request(`${serverApi}/createMemberCoupon`, {
    method: 'POST',
    body: { ...params }
  })
}
//领取优惠券  绑定手机号
export function updateMemberMobile(params) {
  return request(`${serverApi}/bindMemberMobile`, {
    method: 'POST',
    body: { ...params }
  })
}
// 获取首页导航
export function getNavigation(params) {
  return request(`${serverApi}/appNavigation?${stringify(params)}`)
}
// 获取app微页面
export function getAppFeature(params) {
  return request(`${serverApi}/appFeature?${stringify(params)}`)
}
// 获取模版
export function getTemplate(params) {
  return request(`${serverApi}/feature?${stringify(params)}`)
}
// 获取杂项模版
export function getOtherTemplate(params) {
  return request(`${serverApi}/feature_type?${stringify(params)}`)
}
// 订单提交
export function submitOrder(params) {
  return request(`${serverApi}/submitOrder`, {
    method: 'POST',
    body: { ...params }
  })
}
// 订单确认
export function confirmOrder(params) {
  return request(`${serverApi}/confirmOrder`, {
    method: 'POST',
    body: { ...params }
  })
}
// 获取支付方式
export function getPaymentList(params) {
  return request(`${serverApi}/payments?${stringify(params)}`)
}
// 获取支付方式页面信息
export function getPaymentListInfo(params) {
  return request(`${serverApi}/simple_order?${stringify(params)}`)
}
// 发起支付
// orderId	是	int	订单id
// paymentId	是	int	支付方式id
export function goCashier(params) {
  return request(`${serverApi}/cashier`, {
    method: 'POST',
    body: { ...params }
  })
}

// 支付成功后的活动页面
export function payAfterActivityInfo(params) {
  return request(`${serverApi}/payAfterActivityInfo?${stringify(params)}`)
}

// 支付后领取优惠券
export function payAfterGetCoupons(params) {
  return request(`${serverApi}/payAfterGetCoupons`, {
    method: 'POST',
    body: { ...params }
  })
}

// 支付有礼活动数据埋点
export function payAfterSceneStatistic(params) {
  return request(
    `${serverApi}/market/payAfterSceneStatistic?${stringify(params)}`
  )
}

// 申请商家入住
// /createSupplier
export function createSupplier(params) {
  return request(`${serverApi}/createSupplier`, {
    method: 'POST',
    body: { ...params }
  })
}
// 推荐商品
export function recommendGoods(params) {
  return request(`${serverApi}/recommendGoods?${stringify(params)}`)
}
export function recommendNewGoods(params) {
  return request(`${serverApi}/newRecommendGoods?${stringify(params)}`)
}
// 优惠券专区商品
export function getCouponGoodsList(params) {
  return request(`${serverApi}/getCouponGoodsList?${stringify(params)}`)
}
// 满减专区商品
export function getDiscountGoodsList(params) {
  return request(`${serverApi}/getDiscountGoodsList?${stringify(params)}`)
}
//满减专区总价
export function getDiscountGoodsTotalMoney(params) {
  return request(`${serverApi}/getDiscountShoppingCartTotalMoney`, {
    method: 'POST',
    body: { ...params }
  })
}
//满减专区加入购物车
export function addToShoppingCart(params) {
  return request(`${serverApi}/discountAddToShoppingCart`, {
    method: 'POST',
    body: { ...params }
  })
}
//满减专区加入购物车
export function buyoutPriceCreate(params) {
  return request(`${serverApi}/shoppingCart/buyoutPriceCreate`, {
    method: 'POST',
    body: { ...params }
  })
}
// 获取商品分享二维码
export function getGoodsShareImg(params) {
  return request(`${serverNodeApi}/goods/qrcode?${stringify(params)}`)
}
// 获取vip二维码列表
export function getDistributorQrcodeList() {
  return request(`${serverApi}/getDistributorQrcodeList`)
}
// 获取vip二维码
export function getDistributorQrcode(params) {
  return request(
    `${serverNodeApi}/distributor/qrcode/1002?${stringify(params)}`
  )
}
// 获取商品关联优惠券列表
export function getGoodsCoupon(params) {
  return request(`${serverApi}/goodsCoupon?${stringify(params)}`)
}
// 获取wx sdk 信息
export function getWechatSign(params) {
  return request(`${serverApi}/signpackage`, {
    method: 'POST',
    body: { ...params }
  })
}
// 获取用户信息
export function getCurrentUser(params) {
  return request(`${serverApi}/getMDPBaseInfo?${stringify(params)}`)
}

/** 获取购物车规格数量 */
export function getShoppingCartProductQuantity() {
  return request(`${serverApi}/getShoppingCartProductQuantity`)
}

// 绑定上级
export function bindDistrbutor(params) {
  return request(`${serverApi}/distributor/bind?${stringify(params)}`)
}
// 微信上传图片
export function postMediaWechat(params) {
  return request(`${serverApi}/upload/getMedia`, {
    method: 'POST',
    body: { ...params }
  })
}
// 获取9个超级店长
export function getRandomSuperShop() {
  return request(`${serverApi}/getRandomSuperShop`)
}
// 手动锁定上级
export function distributorManualBinding(params) {
  return request(`${serverApi}/distributorManualBinding?${stringify(params)}`)
}
// 获取上级信息
export function getDistributorInfo(params) {
  return request(`${serverApi}/distributorInfo?${stringify(params)}`)
}

// 获取商品供应商信息
export function getGoodsDetailSupplierInfo(params) {
  return request(`${serverApi}/getGoodsDetailSupplierInfo?${stringify(params)}`)
}

// 获取供应商信息
export function getSupplierInfo(params) {
  return request(`${serverApi}/supplier/info?${stringify(params)}`)
}

// 获取供应商商品
export function getSupplierGoods(params) {
  return request(`${serverApi}/supplier/goods?${stringify(params)}`)
}

// 是否展示新人大礼包
export function showNewUserPackage(params) {
  return request(`${serverApi}/newGift?${stringify(params)}`)
}
// 领取新人大礼包
export function getNewUserPackage(params) {
  return request(`${serverApi}/getGift`)
}
// 领取平台优惠券
export function getNewCoupons() {
  return request(`${serverApi}/couponPopup`)
}
//领取优惠券--优惠券详情
export function getCouponDetail(params) {
  return request(`${serverApi}/getCouponDetail?${stringify(params)}`)
}

//获取限时抢购详情
export function getFlashSaleDetail(params) {
  return request(
    `${serverApi}/flashSale/getFlashSaleDetail?${stringify(params)}`
  )
}

//获取限时抢购商品列表
export function getFlashSaleGoodsList(params) {
  return request(
    `${serverApi}/flashSale/getFlashSaleGoodsList?${stringify(params)}`
  )
}

//卡片轮播图片访问
export function cardCarouselImageVisit(params) {
  return request(
    `${serverApi}/home/cardCarouselImageVisit?${stringify(params)}`
  )
}

// 获取新首页
export function getNewHome() {
  return request(`${serverApi}/home/getLists`)
}

// 省市区
export function getSubRegionsTree() {
  return request(`${serverApi}/getSubRegionsTree`)
}
//获取首页弹窗点击次数
export function getModalClicks(params) {
  return request(`${serverApi}/popup/click?${stringify(params)}`)
}

//获取首页弹窗信息
export function getHomePageModalInfo() {
  return request(`${serverApi}/popup`)
}

//获取首页弹窗信息
export function popupNew() {
  return request(`${serverApi}/popupNew`)
}
export function getHomeRecommendGoods(params) {
  return request(`${serverApi}/home/getRecommendGoods?${stringify(params)}`)
}

export function signPrivacyAgreement(params) {
  return request(`${serverApi}/member/signPrivacyAgreement`, {
    method: 'POST',
    body: { ...params }
  })
}

//获取商品视频秀
export function getVideoListByGoodsIds(params) {
  return request(`${videoApi}/video/getListByGoodsIds?${stringify(params)}`)
}

//一口价列表
export function getBuyoutList(params) {
  return request(`${serverApi}/buyoutList?${stringify(params)}`)
}

//一口价购物车信息
export function getBuyoutTotalMoney(params) {
  return request(`${serverApi}/shoppingCart/getBuyoutPriceTotalMoney`, {
    method: 'POST',
    body: { ...params }
  })
}

// 一件登录验证
export function postCheckMobile(params) {
  return request(`${serverApi}/checkMobile`, {
    method: 'POST',
    body: { ...params }
  })
}

// 获取喇叭消息
export function getHornDetail(params) {
  return request(`${serverApi}/smallHorn?${stringify(params)}`)
}

// 积分商品列表
export function getIntegralGoodsList(params) {
  return request(
    `${serverApi}/integralGoods/getIntegralGoodsList?${stringify(params)}`
  )
}

// 签到
export function postCheck(params) {
  return request(`${serverApi}/integralCheckIn`, {
    method: 'POST',
    body: { ...params }
  })
}
export function setCheckPush(params) {
  return request(`${serverApi}/setCheckInPush`, {
    method: 'POST',
    body: { ...params }
  })
}
// 签到
export function getCheckDetail(params) {
  return request(`${serverApi}/getCheckInDetail`)
}
// 签到
export function getCheckDate(params) {
  return request(`${serverApi}/getCheckInDate?${stringify(params)}`)
}
// 获取签到海报
export function getCheckPoster(params) {
  return request(`${serverNodeApi}/sign/in/poster?${stringify(params)}`)
}
// 获取用户今日签到积分数据
export function getTodayIntegral() {
  return request(`${serverApi}/getTodayIntegral`)
}
// 获取固定模板详情
export function getFixedTemp(params) {
  return request(`${serverApi}/designfixedtemplate?${stringify(params)}`)
}
// 调查问卷
export function postSurvey(params) {
  return request(`${serverApi}/questionnaire/getAnswer`, {
    method: 'POST',
    body: { ...params }
  })
}

// 猜你想搜
export function getSearchBoxItem() {
  return request(`${serverApi}/goods/searchBox`)
}
export function getSearchHotItem() {
  return request(`${serverApi}/goods/goodsHotSearch`)
}

export function getBottomBarDesign() {
  return request(`${serverApi}/getBottomBarDesign`)
}

// 获取广告位数据
export function getAdvertisingDetail(params) {
  return request(`${serverApi}/advertisingDetail?${stringify(params)}`)
}

// 获取签到文案
export function getRuleInfo() {
  return request(`${serverApi}/integralSignIn/getRuleInfo`)
}

// 用户禁用查看
export function disableDetail() {
  return request(`${serverApi}/disable/detail`)
}

// 买赠-优惠说明
export function getGiftsExplainInfo(params) {
  return request(`${serverApi}/market/getGiftsExplainInfo?${stringify(params)}`)
}

// 获取商品付款列表
export function getGoodsPaymentList(params) {
  return request(`${serverApi}/goods/getShowOrderList?${stringify(params)}`)
}

// 淘宝客商品分享图片
export function tbkQrcode(params) {
  return request(`${serverNodeApi}/goods/tbk/qrcode?${stringify(params)}`)
}

// 直播埋点
export function liveBuryingPoint(body) {
  return request(`${serverApi}/liveBuryingPoint`, {
    method: 'POST',
    body
  })
}
