import React, { useEffect } from 'react'
import { getUser, getNewUserPackage } from '@/actions'
import { useSelector, useDispatch } from 'react-redux'
import { ActivityIndicator } from 'antd-mobile'
import Preparaction from './Preparaction'
import UpgradeUI from './UpgradeUI'
import FullScreenCenter from '@/components/Flex/FullScreenCenter'
import FullScreenCenterInfo from '../Flex/FullScreenCenterInfo'
import { isWebview } from '@/variable'

// single module page
const singleMoudleRoute = [
  // 成为供应商页面
  '/be_bussiness',
  // 签到页面
  '/zone/check',
  // 公益页面
  '/zone/donate',
  // 积分中心
  '/mine/point',
  // vip二维码页面
  '/action/app-qrcode'
]
const pathname = window.location.pathname

export const LoadingComp = () => (
  <FullScreenCenter>
    <ActivityIndicator text="加载中..." />
  </FullScreenCenter>
)

export default ({ children }) => {
  const dispatch = useDispatch()
  const { loading, done, data: user, error } = useSelector(state => state.user)
  const { show_upgrade_page } = useSelector(state => state.global)

  useEffect(() => {
    dispatch(getUser())
    //新手大礼包
    dispatch(getNewUserPackage())
  }, [])

  // 网站升级
  if (show_upgrade_page) return <UpgradeUI src={show_upgrade_page} />
  if (error) return <FullScreenCenterInfo>{error}</FullScreenCenterInfo>
  if (loading) return <LoadingComp />
  if (!loading && done) {
    // 用户被禁用
    if (!user) return '获取用户信息接口失败'
    // 部分路径直接渲染
    // 例如：
    // 申请成为供应商页面
    // 嵌入webview页面
    if (singleMoudleRoute.includes(pathname) || isWebview) return children
    return <Preparaction user={user} children={children} />
  }
  return null
}
