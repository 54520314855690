import React, { Component } from 'react'
import Countdown from 'react-countdown-now'

export const countRenderer = ({
  formatted: { days, hours, minutes, seconds },
  title
}) => {
  return (
    <>
      {title && <span style={{ marginRight: '4px' }}>{title}</span>}
      {days * 1 > 0 ? (
        <>
          <span>{days * 1}</span>天
        </>
      ) : null}
      <span>{hours}</span>小时<span>{minutes}</span>分<span>{seconds}</span>秒
    </>
  )
}

export const countPureRenderer = ({
  formatted: { days, hours, minutes, seconds },
  title
}) => {
  return (
    <>
      {title && <span style={{ marginRight: '4px' }}>{title}</span>}
      {days * 1 > 0 ? (
        <>
          <span>{days * 1}</span>天
        </>
      ) : null}
      <span>{hours}</span>:<span>{minutes}</span>:<span>{seconds}</span>
    </>
  )
}

export default class CounterController extends Component {
  state = {
    date: this.props.date || 0,
    intervalDelay: this.props.intervalDelay || 1000,
    msecond: null
  }
  countdownInterval = 0

  interval = null

  componentDidMount() {
    this.start()
    if (this.props.msecond) {
      // 是否显示毫秒
      this.interval = setInterval(() => {
        this.setState({
          msecond: String(Date.now()).substring(10, 11)
        })
      }, 100)
    }
  }

  componentWillUnmount() {
    this.clearInterval()
    this.interval && this.clearInterval(this.interval)
  }

  start() {
    this.countdownInterval = window.setInterval(() => {
      if (this.state.date <= 0) {
        return this.clearInterval()
      }

      this.setState(({ date }) => ({ date: date - this.state.intervalDelay }))
    }, this.state.intervalDelay)
  }

  clearInterval() {
    window.clearInterval(this.countdownInterval)
  }

  render() {
    const { onComplete, precision, renderer, keys, zeroPadTime } = this.props
    return (
      <Countdown
        date={this.state.date}
        key={keys}
        zeroPadTime={zeroPadTime}
        onComplete={onComplete}
        // intervalDelay={intervalDelay}
        precision={precision}
        renderer={props => renderer({ ...props, msecond: this.state.msecond })}
        controlled={true}
      />
    )
  }
}
