import React, { Component } from 'react'
import history from '@/routes/history'
import styles from './styles.module.less'
import { pluginPath, isWeixin } from '@/variable'

export const app_download_url_qq =
  'https://a.app.qq.com/o/simple.jsp?pkgname=com.xiudu.oneshopping'

export default class GlobalHeaderAlert extends Component {
  state = {
    show: true
  }
  componentDidMount() {
    if (window.wx) {
      window.wx.miniProgram.getEnv(res => {
        if (res.miniprogram) {
          this.setState({ show: false })
        }
      })
    }
  }
  handleShow = () => {
    this.setState({ show: !this.state.show })
  }
  handleDownload = e => {
    e && e.stopPropagation()
    window.location.href = app_download_url_qq
  }
  render() {
    const needHide = /^\/(action|video|live|be_bussiness)/.test(
      window.location.pathname
    )
    if (!isWeixin) return null
    return (
      <>
        <div
          className={`${styles.container} ${
            this.state.show ? '' : styles.close
          }`}
          style={{
            display: needHide ? 'none' : 'flex'
          }}
        >
          <div className={styles.left}>
            <div onClick={this.handleShow} className={styles.hidenBtn}>
              <svg className={`icon`}>
                <use xlinkHref={`#icon-close_light`} />
              </svg>
            </div>
            <div className={styles.main}>
              <img alt="logo" src={`${process.env.PUBLIC_URL}/logo_gyg.png`} />
              <div className={styles.text}>
                <p>购宜购商城</p>
              </div>
            </div>
          </div>

          <div className={styles.right}>
            <div
              onClick={() => history.push('/qrcode')}
              className={styles.download}
            >
              进入公众号
            </div>

            <div
              onClick={this.handleDownload}
              className={styles.download}
              href={`${pluginPath}/app_download`}
            >
              点击下载APP
            </div>
          </div>
        </div>
      </>
    )
  }
}
