import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  userAgreeProtocol,
  setGlobalDownloadAppVisible,
  setMobileAuthVisible
} from '@/actions'
import GlobalMobileAuth from '@/components/GlobalMobileAuth'
import NewUserCoupon from '@/components/MarketModal/NewUserCoupon'
import NewCoupons from '@/components/MarketModal/NewCoupons'
import HomePageModal from '@/components/HomePageModal'
import ModalAppProtocol from '@/components/ModalAppProtocol'
import DownLoadModel from '@/components/DownLoadModel'

// 是否协议
// 用户未绑定上级 => 绑定上级ui

export default ({ user, children }) => {
  const dispatch = useDispatch()
  const {
    show_newuser_package,
    show_newcoupons,
    show_homePage_modal,
    show_homePage_small_modal,
    show_download_app_modal,
    download_app_modal_option
  } = useSelector(state => state.global)
  const {
    member: { is_verify_mobile, is_privacy_agreement }
  } = user

  useEffect(() => {
    if (+is_verify_mobile === 0) {
      dispatch(setMobileAuthVisible(true))
    }
  }, [])
  // 用户协议
  if (+is_privacy_agreement === 0)
    return <ModalAppProtocol onAgree={() => dispatch(userAgreeProtocol())} />
  return (
    <>
      {show_newuser_package && <NewUserCoupon img={show_newuser_package} />}
      {show_newcoupons && <NewCoupons data={show_newcoupons} />}
      <HomePageModal
        show_homePage_modal={show_homePage_modal}
        show_homePage_small_modal={show_homePage_small_modal}
      />

      <DownLoadModel
        visible={show_download_app_modal}
        option={download_app_modal_option}
        handleCancle={() =>
          dispatch(
            setGlobalDownloadAppVisible(false, download_app_modal_option)
          )
        }
      />
      <GlobalMobileAuth />
      {children}
    </>
  )
}
