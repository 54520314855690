const LIVE_LIST = 'tablive/LIVE_LIST'
const LIVE_PARAMS = 'tablive/LIVE_PARAMS'
const LIVE_POSITION = 'tablive/LIVE_POSITION'
const LIVE_NOMORE = 'tablive/LIVE_NOMORE'

export const tabliveActions = {
  LIVE_LIST,
  LIVE_PARAMS,
  LIVE_POSITION,
  LIVE_NOMORE
}

const updateTabliveList = payload => {
  return {
    type: LIVE_LIST,
    payload
  }
}

const updateTabliveParams = payload => ({
  type: LIVE_PARAMS,
  payload
})

const updateTablivePosition = payload => ({
  type: LIVE_POSITION,
  payload
})

const updateTabliveNomore = payload => ({
  type: LIVE_NOMORE,
  payload
})

export {
  updateTabliveList,
  updateTabliveParams,
  updateTablivePosition,
  updateTabliveNomore
}
