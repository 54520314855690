import React from 'react'
import Vanilla from './Vanilla'
import PropTypes from 'prop-types'
import { staticPath } from '@/variable'

const LazyImg = ({
  src,
  className = '',
  noStaticPath = false,
  width,
  height,
  sizes,
  ...props
}) => {
  const isAbsSrc = /^https?:\/\//.test(src)
  return (
    <Vanilla
      alt=""
      className={className}
      src={`${noStaticPath || isAbsSrc ? '' : staticPath}${src}`}
      sizes={sizes}
      width={width}
      height={height}
      {...props}
    />
  )
}

LazyImg.propTypes = {
  src: PropTypes.string.isRequired
}

export default LazyImg
