// import { stringify } from 'qs'
import request from '@/utils/request'
import { serverApi } from '@/variable'
import { stringify } from 'qs'

// 加入购物车
// goodsId	是	int	商品id
// goodsProductId	是	int	商品规格id
// quantity
export function addShippingCart(params) {
  return request(`${serverApi}/addToShoppingCart`, {
    method: 'POST',
    body: { ...params }
  })
}
// 获取购物车列表
export function getShippingCart(option) {
  return request(`${serverApi}/getShoppingCartList`, option)
}
// 修改购物车数量
// id	是	int	购物车id
// quantity	是	int	修改数量(加1或者减1)
// chooseIdArray 已选择的购物车的id数组，如果修改了已经选择的购物车
export function updateShoppingCartQuantity(params) {
  return request(`${serverApi}/updateShoppingCartQuantity`, {
    method: 'POST',
    body: { ...params }
  })
}
// 选择购物车商品
// idArray 已选择的购物车的id数组
export function getShoppingCartTotalMoney(params) {
  return request(`${serverApi}/getShoppingCartTotalMoney`, {
    method: 'POST',
    body: { ...params }
  })
}
// 清空购物车失效商品
export function deleteShoppingCartLoseGoods(params) {
  return request(`${serverApi}/deleteShoppingCartLoseGoods`, {
    method: 'POST',
    body: { ...params }
  })
}
// 删除购物车商品
// idArray 已选择的购物车的id数组
export function deleteShoppingCartGoods(params) {
  return request(`${serverApi}/deleteShoppingCartGoods`, {
    method: 'POST',
    body: { ...params }
  })
}
// 修改购物车规格
// id	是	int	购物车id
// goodsProductId	是	int	商品规格id
export function updateShoppingCartGoodsProduct(params) {
  return request(`${serverApi}/updateShoppingCartGoodsProduct`, {
    method: 'POST',
    body: { ...params }
  })
}
// 商品收藏 idArray
export function moveShoppingCartToGoodsCollect(params) {
  return request(`${serverApi}/moveShoppingCartToGoodsCollect`, {
    method: 'POST',
    body: { ...params }
  })
}
//相似商品
export function getSimilarList(params) {
  return request(`${serverApi}/similarGoodsList?${stringify(params)}`)
}
