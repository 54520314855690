import React, { memo } from 'react'
import { Modal } from 'antd-mobile'
import { pluginPath } from '@/variable'
import styles from './styles.module.less'

const DownLoadModal = props => {
  const { option = {} } = props
  const handleDownload = () => {
    window.location.href = `${pluginPath}/app_download`
  }
  return (
    <Modal
      className="downloadModal"
      transparent
      visible={props.visible}
      footer={[
        { text: option.cancelText || '取消', onPress: props.handleCancle },
        { text: option.okText || '下载APP', onPress: handleDownload }
      ]}
    >
      <div className={styles.downLoadModel}>
        <img
          className={styles.logo}
          src={`${process.env.PUBLIC_URL}/logo_gyg.png`}
          alt=""
        />
        <div>{option.text || '下载购宜购APP,享受到更优质的服务'}</div>
      </div>
    </Modal>
  )
}

export default memo(
  DownLoadModal,
  (prevProps, nextProps) => prevProps.visible === nextProps.visible
)
// export default DownLoadModal
