const LIVE_LIST = 'liveChannel/LIVE_LIST'
const LIVE_PARAMS = 'liveChannel/LIVE_PARAMS'
const LIVE_POSITION = 'liveChannel/LIVE_POSITION'
const LIVE_NOMORE = 'liveChannel/LIVE_NOMORE'

export const liveChannelActions = {
  LIVE_LIST,
  LIVE_PARAMS,
  LIVE_POSITION,
  LIVE_NOMORE
}

const updateLiveChannelList = payload => {
  return {
    type: LIVE_LIST,
    payload
  }
}

const updateLiveChannelParams = payload => ({
  type: LIVE_PARAMS,
  payload
})

const updateLiveChannelPosition = payload => ({
  type: LIVE_POSITION,
  payload
})

const updateLiveChannelNomore = payload => ({
  type: LIVE_NOMORE,
  payload
})

export {
  updateLiveChannelList,
  updateLiveChannelParams,
  updateLiveChannelPosition,
  updateLiveChannelNomore
}
