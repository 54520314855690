import { stringify } from 'qs'
import request from '@/utils/request'
import { videoApi } from '@/variable'

// 好物圈列表
export function getGoodThingList(params) {
  return request(`${videoApi}/getMaterialList?${stringify(params)}`)
}

// 好物详情
export function getGoodThingDetail(params) {
  return request(`${videoApi}/getMaterialDetail?${stringify(params)}`)
}

// 素材分类
export function getCategoryList() {
  return request(`${videoApi}/getMaterialGroupList`)
}

//点击素材商品  统计
export function statisticsGoodsClick(params) {
  return request(`${videoApi}/material/incrViews?${stringify(params)}`)
}
