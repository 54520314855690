import { stringify } from 'qs'
import request from '@/utils/request'
import { serverApi } from '@/variable'

//设置微信号
export function updateMemberWexinNumber(params) {
  return request(`${serverApi}/updateMemberWexinNumber`, {
    method: 'POST',
    body: { ...params }
  })
}

// 获取消息设置信息
export function getMemberMessageSetting(params) {
  return request(`${serverApi}/getMemberMessageSetting?${stringify(params)}`)
}

//更改消息设置信息
export function updateMemberMessageSetting(params) {
  console.log(params, 'par')
  return request(`${serverApi}/updateMemberMessageSetting`, {
    method: 'POST',
    body: { ...params }
  })
}

/** 解绑第三方授权 */
export function unbindThirdPart(params) {
  return request(`${serverApi}/member/unbindThirdPart`, {
    method: 'POST',
    body: { ...params }
  })
}

// 获取账号信息
export function getMemberBaseInfo(params) {
  return request(`${serverApi}/getMemberBaseInfo?${stringify(params)}`)
}

//发送跟换手机验证码
export function sendValidationAuthCode(params) {
  return request(`${serverApi}/sendValidationAuthCode`, {
    method: 'POST',
    body: { ...params }
  })
}

//发送绑定新手机验证码
export function sendBindAuthCode(params) {
  return request(`${serverApi}/sendBindAuthCode`, {
    method: 'POST',
    body: { ...params }
  })
}

//验证 -- 验证码
export function validationNowMobile(params) {
  return request(`${serverApi}/validationNowMobile`, {
    method: 'POST',
    body: { ...params }
  })
}

//修改会员手机号 -- 验证码
export function updateMemberMobile(params) {
  return request(`${serverApi}/updateMemberMobile`, {
    method: 'POST',
    body: { ...params }
  })
}

//绑定会员手机号
export function bindMemberMobile(params) {
  return request(`${serverApi}/bindMemberMobile`, {
    method: 'POST',
    body: { ...params }
  })
}

// 获取账号信息
export function getPersonalData(params) {
  return request(`${serverApi}/getPersonalData?${stringify(params)}`)
}

// 获取默认头像框页面信息
export function getDefaultHeadPortraitFrameInfo(params) {
  return request(
    `${serverApi}/integralLuckDraw/getDefaultHeadPortraitFrameInfo?${stringify(
      params
    )}`
  )
}

//设置-修改生日
export function updateBirthday(params) {
  return request(`${serverApi}/updateBirthday`, {
    method: 'POST',
    body: { ...params }
  })
}

// 使用头像框
export function useHeadPortraitFrame(params) {
  return request(
    `${serverApi}/integralLuckDraw/useHeadPortraitFrame

  `,
    {
      method: 'POST',
      body: { ...params }
    }
  )
}

//设置-修改性别
export function updateSex(params) {
  return request(`${serverApi}/updateSex`, {
    method: 'POST',
    body: { ...params }
  })
}

// 头像框列表
export function getHeadPortraitFrameList(params) {
  return request(
    `${serverApi}/integralLuckDraw/getHeadPortraitFrameList?${stringify(
      params
    )}`
  )
}
