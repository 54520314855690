import React from 'react'
import styles from './index.module.less'
import { useQuantity } from '@/model'

const Badge = ({ className, ...props }) => {
  const { data } = useQuantity()
  return (
    <div {...props} className={`${styles.carBadge} ${className}`}>
      <span>{data}</span>
    </div>
  )
}

export default Badge
