import test from './test'
import auth from './auth'
import listView from './listView'
import homePage from './homePage'
import searchResult from './searchResult'
import user from './user'
import confirmOrder from './confirmOrder'
import order from './order'
import appEnv from './appEnv'
import global from './global'
import supplier from './supplier'
import home from './home'
import video from './video'
import searchVideoOrLive from './searchVideoOrLive'
import tablive from './tablive'
import playbackLive from './playbackLive'
import liveChannel from './liveChannel'
import account from './account'
import buyout from './buyout'
import integral from './integral'
import focusFans from './focusFans'
import statistic from './statistic'
import hostList from './hostList'
import myPrize from './myPrize'
import goodThing from './goodThing'
import similar from './similar'
import material from './material'

export default {
  test,
  user,
  auth,
  listView,
  homePage,
  searchResult,
  home,
  confirmOrder,
  order,
  appEnv,
  global,
  supplier,
  video,
  searchVideoOrLive,
  tablive,
  playbackLive,
  liveChannel,
  account,
  buyout,
  integral,
  focusFans,
  statistic,
  hostList,
  myPrize,
  goodThing,
  similar,
  material
}
