import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { getWechatSign } from '@/services/global'
import { isWeixin, isWebview } from '@/variable'
import { shareShop, updateDocumentTitle } from '@/utils/utils'
import { routeTitles } from '@/routes'
import MainTain from '@/components/MainTain'
import { withRouter } from 'react-router-dom'
import styles from './styles.module.less'

const BlockComp = () => <MainTain />
const blockMode = false

//允许进入的路由
const allowedRoutesList = [
  { path: '/member' },
  { path: '/member/order' },
  { path: '/member/account' },
  { path: '/member/account/withdraw' },
  { path: '/member/account/finish_withdraw' },
  { path: '/member/account/account_list' },
  { path: '/member/account/account_detail' },
  { path: '/mine' },
  { path: '/mine/order' },
  { path: '/mine/order/detail' }
]

// const InitErrorLayout = ({ text }) => {
//   return <div className={styles.error}>{text}</div>
// }

// 前置验证模块
function Authorized({ children, ...props }) {
  useEffect(() => {
    // 页面切换 微信config
    getSign(props.user.data)
    // 页面title
    wirtePageTitle()
  }, [])
  // 微信签名
  const getSign = async user => {
    // 非微信环境 return
    if (!isWeixin || isWebview || process.env.REACT_APP_ENV === 'development')
      return
    const { data, type, msg } = await getWechatSign({
      url: window.location.href.split('#')[0]
    })
    if (type === 1) return console.log(msg)
    window.wx.config({
      ...data
    })
    // 商品详情和支付结果页面不需要sharShop： 页面单独配置了
    if (
      window.location.pathname === '/goods/detail' ||
      window.location.pathname === '/member/qr-code' ||
      /^\/video\//.test(window.location.pathname) ||
      /^\/feature\//.test(window.location.pathname) ||
      /^\/limit-buy\//.test(window.location.pathname) ||
      /^\/zone\//.test(window.location.pathname) ||
      /^\/topic\//.test(window.location.pathname) ||
      window.location.pathname === '/auction/pay_result'
    ) {
      return
    }
    // 微信分享
    shareShop(user)
  }
  // 页面title
  const wirtePageTitle = () => {
    const pathname = props.match.path
    if (routeTitles[pathname]) updateDocumentTitle(routeTitles[pathname])
  }
  if (blockMode) {
    if (allowedRoutesList.find(el => el.path === window.location.pathname))
      return children
    return <BlockComp />
  }
  return <div className={styles.animatePage}>{children}</div>
}

const mapStateToPorps = state => ({
  user: state.user
})

export default withRouter(connect(mapStateToPorps, null)(Authorized))
