import React from 'react'
import styles from './styles.module.less'
import FontIcon from '@/components/FontIcon'
import history from '@/routes/history'
// 推荐尺码link组件
export default function RcSize({ detail }) {
  const show = detail.size_template_ids && detail.size_template_ids * 1 > 0
  if (!show) return null
  return (
    <div className={styles.rcSize}>
      <span
        onClick={() =>
          history.push(`/goods/recommond-size/${detail.size_template_ids}`)
        }
      >
        尺码推荐
        <FontIcon icon="#icon-right" />
      </span>
    </div>
  )
}
