import React from 'react'
import NavBar from '@/components/NavBar'
import routes from '@/routes'
import 'moment/locale/zh-cn'
import './App.less'
import './theme/swiper.css'
import './theme/animate.less'
import GlobalHeaderAlert from '@/components/GlobalHeaderAlert'
import { isAdminView } from './variable'

function App() {
  return (
    <div className="App">
      {/* 全局组件 */}
      {/* 顶部app下载提示 */}
      {!isAdminView && <GlobalHeaderAlert />}
      {routes}
      {/* 全局导航 */}
      <NavBar />
    </div>
  )
}
export default App
