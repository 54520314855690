import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Toast, InputItem, Flex } from 'antd-mobile'
import { sendBindAuthCode, bindMemberMobile } from '@/services/mine/setting'
import styles from './mobile.module.less'
import { triggerWindowResize, setToken } from '@/utils/utils'
import { setMobileAuthVisible, setUserInfo } from '@/actions'
import { getCurrentUser, postCheckMobile } from '@/services/global'
import history from '@/routes/history'
import JGService from '@/utils/jgservice'
import LazyImg from '../LazyImg'
import copy from 'copy-to-clipboard'

function GlobalMobileAuth(props) {
  const [showCode, setShowCode] = useState(false)
  const [mobile, setMobile] = useState('')
  const [code, setCode] = useState('')
  const [hasMobileError, setHasMobileError] = useState(false)
  const onErrorClick = () => {
    if (hasMobileError) {
      Toast.info('请输入11位手机号码')
    }
  }
  const onMobileChange = value => {
    if (value.replace(/\s/g, '').length < 11) {
      setHasMobileError(true)
    } else {
      setHasMobileError(false)
      setMobile(value.replace(/\s+/g, ''))
    }
  }
  const onCodeChange = value => {
    setCode(value)
  }

  const getCode = async () => {
    if (hasMobileError || !mobile.length) {
      return Toast.info(`请输入正确的手机号`)
    }
    Toast.info('正在验证手机号，请稍后...', null, null)
    // 极光取号
    try {
      const jgservice = new JGService()
      const token = await jgservice.getToken(mobile)
      const { type } = await postCheckMobile({ token, mobile })
      if (type === 1) throw new Error()
      const { type: bindType, msg } = await bindMemberMobile({
        mobile,
        is_jg_verify: 1
      })
      if (bindType === 1) return Toast.fail(msg)
      validateSuccess()
    } catch (error) {
      // 取号失败 走短信验证
      const { type, msg } = await sendBindAuthCode({ mobile })
      if (type === 1) return Toast.fail(msg)
      Toast.hide()
      setCode('')
      setShowCode(true)
    }
  }
  const postValidate = async () => {
    if (code.length !== 5) return Toast.info(`请输入验证码`)
    Toast.info('正在验证', null, null)
    const { type, msg, data } = await bindMemberMobile({
      mobile,
      authCode: code
    })
    Toast.hide()
    if (type === 1) return Toast.fail(msg)
    // 如果返回token 写入
    if (data && data.token) setToken(data.token)
    validateSuccess()
  }
  const validateSuccess = async () => {
    Toast.info('绑定成功，正在同步请稍后～')
    const { data, type, msg } = await getCurrentUser()
    if (type === 1) return Toast.offline(msg)
    props.setUserInfo(data)
    Toast.hide()
    props.setMobileAuthVisible(false)
    Toast.info('同步成功！', 1, () => window.location.reload())
    // 会员中心 刷新页面
    if (window.location.pathname === '/member') {
      history.go(0)
    }
  }
  const {
    user: { data: userData }
  } = props
  if (!props.global.mobile_auth_visible) return null
  return (
    <div className={styles.authMobile}>
      <div className={styles.bg} />
      <div className={styles.inner}>
        <div className={`${styles.wrapper} ${styles.wrapper1}`}>
          <div className={styles.title}>
            <strong>验证手机号</strong>
          </div>
          <Flex className={styles.avatar} align="stretch">
            <LazyImg
              className={styles.avatarImg}
              src={userData.member.avatar}
            />
            <Flex
              justify="between"
              align="start"
              direction="column"
              className={styles.avatarContent}
            >
              <h4>{userData.member.name}</h4>
              <Flex align="center">
                {' '}
                <p>用户码: {userData.member.ids} </p>{' '}
                <Flex
                  onClick={() => {
                    copy(userData.member.ids)
                    Toast.info('复制成功')
                  }}
                  align="center"
                  justify="center"
                  className={styles.avatarCopy}
                >
                  复制
                </Flex>
              </Flex>
            </Flex>
          </Flex>
          <div className={styles.iptBox}>
            <InputItem
              clear
              type="phone"
              placeholder="请输入11位手机号码"
              onChange={onMobileChange}
              error={hasMobileError}
              onErrorClick={onErrorClick}
              onBlur={triggerWindowResize}
            />
          </div>
          {showCode && (
            <div className={styles.iptBox}>
              <InputItem
                clear
                type="number"
                placeholder="请输入您收到的验证码"
                onChange={onCodeChange}
                onBlur={triggerWindowResize}
                maxLength={5}
              />
            </div>
          )}

          <div className={styles.desc}>验证手机号后拥有更多权限哦～</div>
          <div
            onClick={showCode ? postValidate : getCode}
            className={styles.btn}
          >
            {showCode ? '确认' : '获取验证码'}
          </div>
        </div>
      </div>
    </div>
  )
}
const mapStateToProps = state => ({
  user: state.user,
  global: state.global
})
const mapDispatchToProps = dispatch => ({
  setMobileAuthVisible: params => dispatch(setMobileAuthVisible(params)),
  setUserInfo: params => dispatch(setUserInfo(params))
})
export default connect(mapStateToProps, mapDispatchToProps)(GlobalMobileAuth)
