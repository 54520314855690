import React from 'react'
import styles from './styles.module.less'
import { useSelector } from 'react-redux'
import diamond from './diamond.svg'
import gold from './gold.svg'
import manager from './manager.svg'
import vip from './vip.svg'
import rebuy from './rebuy.svg'
import discount from './discount.svg'

// 获取身份text
export const getUserIdentityImg = (info, isRebuy, data) => {
  const target =
    data &&
    data.activity_list &&
    data.activity_list.find(
      item => item.type === 'tbk_coupon' && +item.after_price
    )
  if (target) return discount
  if (data && +data.is_tbk) return
  if (info.vip && info.vipStatus <= 1 && isRebuy) return rebuy
  if (!info) return null
  if (info.superPartner) return diamond
  if (info.highPartner) return gold
  if (info.partner) return manager
  if (info.vip) return vip
  return null
}

export default function RoleTag({ isRebuy, data }) {
  const user = useSelector(state => state.user)
  const info = user.data._userIdentity
  const img = getUserIdentityImg(info, isRebuy * 1, data)
  if (!img) return null
  return <img className={styles.tag} src={img} alt="" />
}
