import React from 'react'
import styles from './maintain.module.less'

function MainTain(props) {
  return (
    <div className={styles.systemBody}>
      <div className={styles.background}>
        <img alt="" src={process.env.PUBLIC_URL + '/images/mine/bg.png'} />
      </div>
      <div className={styles.text}>
        <h3 className={styles.title}>
          预计在{`x`}日{`x`}时恢复运行
        </h3>
        <div className={styles.contain1}>
          系统维护,更新完成后,现系统可完成订单以及收益相关操作,购物请在新版系统完成购物体验
        </div>
        <div className={styles.contain2}>
          造成不便还望见谅,感谢您一直以来的支持!
        </div>
        <div className={styles.contain2}>如有疑问，请咨询4008832599</div>
      </div>
    </div>
  )
}

export default MainTain
