import { fetch as fetchPolyfill } from 'whatwg-fetch'
import { setToken, getToken, urlAddHash } from './utils'
import {
  wechatAuthUrl,
  isWeixin,
  wechatAuthTrigger,
  production
} from '@/variable'
import { getShowUpgradePage } from '@/actions'
import { Toast } from 'antd-mobile'

const codeMessage = {
  200: '服务器成功返回请求的数据。',
  201: '新建或修改数据成功。',
  202: '一个请求已经进入后台排队（异步任务）。',
  204: '删除数据成功。',
  400: '发出的请求有错误，服务器没有进行新建或修改数据的操作。',
  401: '用户没有权限（令牌、用户名、密码错误）。',
  403: '用户得到授权，但是访问是被禁止的。',
  404: '发出的请求针对的是不存在的记录，服务器没有进行操作。',
  406: '请求的格式不可得。',
  410: '请求的资源被永久删除，且不会再得到的。',
  422: '当创建一个对象时，发生一个验证错误。',
  500: '服务器发生错误，请检查服务器。',
  502: '网关错误。',
  503: '服务不可用，服务器暂时过载或维护。',
  504: '网关超时。'
}

const checkStatus = response => {
  if (response.status >= 200 && response.status < 300) {
    return response
  }
  const errortext = codeMessage[response.status] || response.statusText
  const error = new Error(errortext)
  error.name = response.status
  error.response = response
  throw error
}

/**
 * Requests a URL, returning a promise.
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [option] The options we want to pass to "fetch"
 * @return {object}           An object containing either "data" or "err"
 */
export default function request(url, option) {
  const options = {
    ...option
  }

  const defaultOptions = {
    credentials: 'include',
    headers: {
      'device-type': 3
    }
  }
  if (options.headers) {
    options.headers = { ...defaultOptions.headers, ...options.headers }
  }
  const newOptions = { ...defaultOptions, ...options }
  if (
    newOptions.method === 'POST' ||
    newOptions.method === 'PUT' ||
    newOptions.method === 'DELETE'
  ) {
    if (!(newOptions.body instanceof FormData)) {
      newOptions.headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json; charset=utf-8',
        ...newOptions.headers
      }
      newOptions.body = JSON.stringify(newOptions.body)
    } else {
      // newOptions.body is FormData
      newOptions.headers = {
        Accept: 'application/json',
        ...newOptions.headers
      }
    }
  }

  const token = getToken()
  if (token) {
    newOptions.headers.Authorization = `${token}`
  }

  return (
    fetchPolyfill(url, { mode: 'cors', ...newOptions })
      .then(checkStatus)
      .then(response => {
        const newToken = response.headers.get('Authorization')
        if (newToken) setToken(newToken)
        return response
      })
      // .then(response => cachedSave(response, hashcode))
      .then(async response => {
        // DELETE and 204 do not return data by default
        // using .json will report an error.
        let resp
        if (newOptions.method === 'DELETE' || response.status === 204) {
          resp = await response.text()
        } else {
          resp = await response.json()
        }
        if (resp.type === -1) {
          window.init_store.dispatch(getShowUpgradePage(resp.url))
          return { type: 1, msg: '网站维护中...' }
        }
        return resp
      })
      .catch(async e => {
        // if (process.env.NODE_ENV === 'development') return
        const res = await e.response.json()
        const status = e.name
        if (status === 401) {
          Toast.info('登录信息过期, 重新授权', null, false)
          window.localStorage.removeItem('authorization_token')
          if (isWeixin && wechatAuthTrigger) {
            // 重新微信授权
            window.location.href = `${wechatAuthUrl}?redirect=${window.encodeURIComponent(
              window.location.href
            )}`
          } else {
            // 刷新页面 重新登录
            if (production)
              window.location.href = urlAddHash(window.location.href)
          }
          return
        }
        // environment should not be used
        if (status === 403) {
          // 用户被禁用
          window.init_store.dispatch({
            type: 'user/OK',
            payload: { member: res.data }
          })
          return
        }
        if (status <= 504 && status >= 500) {
          // history.replace('/exception/500')
          return {
            msg: res.msg || '系统开了个小差，请刷新页面试试～',
            type: 1,
            data: null
          }
        }
        return {
          msg: res.msg || '系统开了个小差，请刷新页面试试～',
          type: 1,
          data: null
        }
      })
  )
}
