import React from 'react'
import { getToken, setToken, getCookie } from '@/utils/utils'
import { parse } from 'querystring'
import {
  wechatAuthUrl,
  isWeixin,
  production,
  isWebview
  // isIos
} from '@/variable'
import stats from '@/utils/stats'
import NeedWechatView from '@/components/NeedWechatView'
import BindParentStep from './BindParentStep'
import Entry from './Entry'
import { invokeAppEvent } from '@/utils/app'

// 载入统计代码
if (production) {
  stats()
}

const cookieToken = getToken() || getCookie('token')

export default ({ children }) => {
  let token = cookieToken
  // webview中获取token
  if (isWebview) {
    token = invokeAppEvent('getToken')
    // ios 不返回token 而是调用全局setToken方法
    token && setToken(token)
    return <Entry children={children} />
  }

  const location = window.location
  const { search, pathname, href } = location
  const { isAdminView, mids } = parse(search.substr(1))
  // 后台预览
  if (isAdminView) {
    token = process.env.PREVIEW_TOKEN
    setToken(token)
    return <Entry children={children} />
  }
  // prod非微信浏览器不能进入
  if (!isWeixin && process.env.REACT_APP_ENV === 'production') {
    return <NeedWechatView />
  }
  // wx授权获取token
  if (isWeixin && process.env.NODE_ENV === 'production' && !token) {
    // 动态分享页面
    let redirctUrl = /^\/dynamic\/topic\//.test(pathname) ? 'type=1&' : ''
    redirctUrl += `redirect=${window.encodeURIComponent(href)}`
    window.location.href = `${wechatAuthUrl}?${redirctUrl}`
    return null
  }
  // 开发环境
  if (process.env.NODE_ENV === 'development') {
    token = process.env.DEV_TOKEN
  }

  setToken(token)
  return <BindParentStep mids={mids} children={children} />
}
