import { homeActions } from '@/actions/homeActions'

const initialState = {
  loading: false,
  error: false,
  done: false,
  bgcolor: '#f44336',
  data: [],
  scrollPostion: 0,
  currentPage: window.location.hash.substr(1) * 1 || 0
}

export default (state = initialState, action) => {
  let newData = null
  switch (action.type) {
    case homeActions.REQUEST_NAVIGATION:
      return {
        ...state,
        loading: true,
        error: false,
        done: false
      }
    case homeActions.OK_NAVIGATION:
      return {
        ...state,
        loading: false,
        error: false,
        done: true,
        data: action.payload
      }
    case homeActions.ERROR_NAVIGATION:
      return {
        ...state,
        error: true,
        loading: false,
        done: false
      }
    case homeActions.CURRENT_FEATURE:
      return {
        ...state,
        currentPage: action.payload
      }
    case homeActions.REQUEST_FEATURE:
      newData = [...state.data]
      newData[action.payload.key].loading = true
      newData[action.payload.key].error = false
      newData[action.payload.key].done = false
      return {
        ...state,
        data: newData
      }
    case homeActions.OK_FEATURE:
      newData = [...state.data]
      newData[action.payload.key].loading = false
      newData[action.payload.key].error = false
      newData[action.payload.key].done = true
      newData[action.payload.key].data = action.payload.data
      return {
        ...state,
        data: newData,
        currentPage: action.payload.key
      }
    case homeActions.ERROR_FEATURE:
      newData = [...state.data]
      newData[action.payload.key].loading = false
      newData[action.payload.key].error = true
      newData[action.payload.key].done = true
      return {
        ...state,
        data: newData,
        currentPage: action.payload.key
      }
    case homeActions.SET_SCROLL:
      return {
        ...state,
        scrollPostion: action.payload
      }
    case homeActions.SET_BGCOLOR:
      return {
        ...state,
        bgcolor: action.payload
      }
    default:
      return state
  }
}
