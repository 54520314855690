import { getCategoryList } from '@/services/discover/goodThing'

const MATERIAL_LIST = 'material/MATERIAL_LIST'
const MATERIAL_PARAMS = 'material/MATERIAL_PARAMS'
const MATERIAL_POSITION = 'material/MATERIAL_POSITION'
const MATERIAL_NOMORE = 'material/MATERIAL_NOMORE'
const MATERIAL_CATEGORYS = 'material/MATERIAL_CATEGORYS'

export const materialActions = {
  MATERIAL_LIST,
  MATERIAL_PARAMS,
  MATERIAL_POSITION,
  MATERIAL_NOMORE,
  MATERIAL_CATEGORYS
}

const getMaterialCategorys = () => async dispatch => {
  const { data, type } = await getCategoryList()
  if (type === 1) return
  dispatch({ type: MATERIAL_CATEGORYS, payload: data })
}

const updateMaterialList = payload => {
  return {
    type: MATERIAL_LIST,
    payload
  }
}

const updateMaterialParams = payload => ({
  type: MATERIAL_PARAMS,
  payload
})

const updateMaterialPosition = payload => ({
  type: MATERIAL_POSITION,
  payload
})

const updateMaterialNomore = payload => ({
  type: MATERIAL_NOMORE,
  payload
})

export {
  updateMaterialList,
  updateMaterialParams,
  updateMaterialPosition,
  updateMaterialNomore,
  getMaterialCategorys
}
