/**
 * 所有path需要用绝对路径
 * 所有一页面组件路径都是相对于于@/containers
 * containers文件结构:
 * 拿会员页面举例:
 * containers
 * --Member
 * ----index.js 对应会员页面
 * ----Order
 * ------index.js 对应会员订单页面
 * ------Detail.js 对应会员订单详情
 */

export default [
  // 首页
  {
    path: '/',
    // title: '购宜购商城',
    component: 'Home',
    exact: true,
    showNavbar: true
  },
  {
    path: '/bind-parent',
    // title: '购宜购商城',
    component: 'BindParent'
  },
  // 首页
  {
    path: '/limit-buy/:id',
    title: '限时抢购',
    component: 'Zone/FlashBuy',
    showNavbar: true
  },
  {
    path: '/similar',
    title: '找相似',
    component: 'Similar',
    showNavbar: false
  },
  {
    path: '/sales-promotion/:id',
    title: '大促专用页面',
    component: 'SalesPromotion',
    showNavbar: false
  },
  // 发现模块
  {
    path: '/discover',
    exact: true,
    showNavbar: true,
    component: 'Discover',
    title: '素材圈',
    routes: [
      // 个人主页
      {
        path: '/discover/home-page/:id',
        component: 'Discover/HomePage'
      },
      {
        path: '/discover/focus-fans/:ids',
        component: 'Discover/FocusAndFans'
      },
      {
        path: '/discover/search',
        component: 'Discover/SearchPage'
      },
      {
        path: '/discover/tab-lives',
        component: 'Discover/LivesInTab',
        showNavbar: true
      },
      {
        path: '/discover/playback-live/:ids',
        component: 'Discover/PlaybackLive.js'
      },
      {
        path: '/discover/lives',
        component: 'Discover/Lives.js'
      },
      {
        path: '/discover/mini-lives',
        component: 'Discover/MiniLives.js'
      },
      {
        path: '/discover/good-thing',
        component: 'Discover/GoodThingCircle',
        showNavbar: true
      },
      {
        path: '/discover/good-thing-detail/:id',
        component: 'Discover/GoodThingDetail'
      },
      {
        path: '/discover/material',
        component: 'Discover/Material',
        showNavbar: true
      },
      {
        path: '/discover/material-detail/:id',
        component: 'Discover/GoodThingDetail'
      }
    ]
  },
  // 我的资料
  {
    path: '/my-profile',
    exact: true,
    component: 'MyProfile',
    title: '我的资料',
    routes: [
      // 主播列表
      {
        path: '/my-profile/host-list',
        component: 'MyProfile/HostList',
        title: '主播列表'
      },
      // 粉丝勋章
      {
        path: '/my-profile/fans-medal',
        component: 'MyProfile/FansMedal',
        title: '粉丝勋章'
      },
      // 粉丝勋章说明
      {
        path: '/my-profile/medal-instructions',
        component: 'MyProfile/FansMedal/MedalInstructions',
        title: '粉丝勋章说明'
      },
      // 我的邀约成就
      {
        path: '/my-profile/achievement',
        component: 'MyProfile/Achievement',
        title: '我的邀约成就'
      },
      // 全部邀约成就
      {
        path: '/my-profile/all-achievement',
        component: 'MyProfile/Achievement/List'
      },
      // 我的奖品
      {
        path: '/my-profile/prize',
        component: 'MyProfile/Prize',
        title: '我的奖品'
      }
    ]
  },
  {
    path: '/fans-intimacy',
    component: 'FansIntimacy',
    title: '亲密值说明(2.0)'
  },
  // 视频详情
  {
    path: '/video/:id',
    component: 'Video'
  },
  // 视频详情
  {
    path: '/live/detail/:id',
    component: 'Live'
  },
  // 视频详情
  {
    path: '/livetest',
    component: 'Live',
    exact: true
  },
  // 微页面
  {
    path: '/feature/:ids',
    component: 'Home/Page',
    exact: true,
    showNavbar: true
  },
  // 店铺优惠券列表
  {
    path: '/shopCoupon',
    component: 'ShopCoupon'
  },
  // 分类列表
  {
    path: '/category',
    title: '分类',
    exact: true,
    component: 'Category',
    showNavbar: true,
    routes: [
      // 商品详情
      {
        path: '/category/detail',
        component: 'Category/Detail'
      },
      {
        path: '/goods/detail',
        component: 'GoodsDetail'
      },
      {
        path: '/goods/tbk',
        component: 'GoodsDetail/Tbk'
      },
      {
        path: '/goods/comment/:id',
        component: 'GoodsComment'
      },
      {
        path: '/goods/videos/:id',
        component: 'GoodsVideoShow',
        title: '视频秀'
      },
      {
        path: '/goods/recommond-size/:ids',
        component: 'RecommondSize'
      }
    ]
  },
  // 品牌店铺
  {
    path: '/supplier/:id',
    title: '品牌',
    exact: true,
    component: 'Supplier'
  },
  // 购物车
  {
    path: '/cart',
    title: '购物车',
    exact: true,
    showNavbar: true,
    component: 'Cart'
  },
  // 搜索页
  {
    path: '/search',
    exact: true,
    showNavbar: false,
    component: 'Search',
    title: '搜索',
    routes: [
      // 搜索结果
      {
        path: '/search/result',
        showNavbar: false,
        title: '搜索',
        component: 'SearchResult'
      }
    ]
  },
  // 我的
  {
    path: '/mine',
    exact: true,
    showNavbar: true,
    component: 'Mine',
    title: '我的',
    routes: [
      // 我的收藏
      {
        title: '商品收藏',
        path: '/mine/collection',
        component: 'Mine/Collection'
      },
      {
        title: '会员权益',
        path: '/mine/interests',
        component: 'Mine/Interests'
      },
      {
        title: '专属客服',
        path: '/mine/qrcode',
        component: 'Mine/Qrcode'
      },
      // 邀请好友
      {
        title: '招募海报',
        path: '/mine/recruit',
        component: 'Mine/Recruit'
      },
      // 我的收藏
      {
        title: '商品收藏',
        path: '/mine/search',
        component: 'Mine/Search'
      },
      // 优惠券
      {
        title: '优惠券',
        path: '/mine/coupon',
        component: 'Coupons',
        routes: [
          {
            title: '领券中心',
            path: '/mine/coupon/get-coupons',
            component: 'Coupons/GetCoupons'
          }
        ]
      },
      //投诉
      {
        title: '投诉',
        path: '/mine/complain',
        component: 'Mine/Complain',
        routes: [
          {
            title: '投诉列表',
            path: '/mine/complain/list',
            component: 'Mine/Complain/List'
          },
          {
            title: '投诉详情',
            path: '/mine/complain/detail/:id',
            component: 'Mine/Complain/Detail'
          }
        ]
      },
      // 我的订单
      {
        title: '我的订单',
        path: '/mine/order',
        component: 'Order',
        routes: [
          // 搜索结果
          {
            path: '/mine/order/search',
            showNavbar: false,
            title: '搜索',
            component: 'Order/Search',
            routes: [
              // 搜索结果
              {
                path: '/mine/order/search/result',
                showNavbar: false,
                title: '搜索结果',
                component: 'Order'
              }
            ]
          },
          {
            title: '修改地址',
            path: '/mine/address/modify',
            component: 'Address/Modify'
          },
          {
            title: '查看物流',
            path: '/mine/order/logistic/:id',
            component: 'Order/Logistic'
          },
          {
            title: '查看物流',
            path: '/mine/order/logisticList/:id',
            component: 'Order/Logistic/LogisticList'
          },
          {
            title: '发表评价',
            path: '/mine/order/evaluate/:id',
            component: 'Order/Evaluate'
          },
          {
            title: '订单详情',
            path: '/mine/order/detail',
            component: 'Order/Detail'
          },
          {
            title: '交易结果',
            path: '/mine/order/result',
            component: 'Order/Result'
          },
          {
            title: '预售订单',
            path: '/mine/order/advance-sell',
            component: 'Order/AdvanceSell'
          },
          //定金退款
          {
            title: '退款详情',
            path: '/mine/order/deposit/:ids',
            component: 'Order/Deposit'
          },
          //自动退款
          {
            title: '退款详情',
            path: '/mine/order/refund-detail',
            component: 'Order/RefundDetail'
          }
        ]
      },
      //售后退款
      {
        title: '退款/售后',
        path: '/mine/refund',
        component: 'Refund',
        routes: [
          {
            title: '售后详情',
            path: '/mine/refund/detail',
            component: 'Refund/Detail'
          },
          {
            title: '定金退款明细',
            path: '/mine/refund/deposit-detail/:id',
            component: 'Refund/DepositDetail'
          },
          {
            title: '选择售后类型',
            path: '/mine/refund/choose_afterSales',
            component: 'Refund/ChooseAfterSales'
          },
          {
            title: '填写售后申请',
            path: '/mine/refund/apply_afterSales',
            component: 'Refund/ApplyAfterSales'
          },
          {
            title: '提交寄件信息',
            path: '/mine/refund/submit_sendInfo/:ids',
            component: 'Refund/SubmitSendInfo'
          },
          {
            title: '提交成功',
            path: '/mine/refund/submit_apply',
            component: 'Refund/SubmitApply'
          },
          //主动退款详情
          {
            title: '退款详情',
            path: '/mine/refund/initiative-detail/:id',
            component: 'Refund/InitiativeDetail'
          }
        ]
      },
      // 地址
      {
        title: '地址管理',
        path: '/mine/address',
        component: 'Address',
        routes: [
          //添加地址
          {
            title: '新建地址',
            path: '/mine/address/add',
            component: 'Address/Add'
          },
          //选择收货地址
          {
            title: '选择收货地址',
            path: '/mine/address/choose',
            component: 'Address'
          },
          //修改地址
          {
            title: '编辑地址',
            path: '/mine/address/:id',
            component: 'Address/Add'
          }
        ]
      },
      //积分
      {
        path: '/mine/point',
        // exact: true,
        title: '签到积分',
        component: 'Point',
        routes: [
          //积分记录
          {
            title: '积分明细',
            path: '/mine/point/log',
            component: 'Point/Log'
          },
          {
            title: '优惠券详情',
            path: '/mine/point/detail/:id',
            component: 'Point/Detail'
          },
          {
            title: '积分任务',
            path: '/mine/point/task',
            component: 'Point/Task'
          }
        ]
      },
      //设置
      {
        title: '设置',
        path: '/mine/set',
        // exact: true,
        component: 'Setting',
        routes: [
          //微信号
          {
            title: '微信号',
            path: '/mine/set/wechat',
            component: 'Setting/WeChat'
          },
          {
            title: '隐私设置',
            path: '/mine/set/hide',
            component: 'Setting/Hide',
            routes: [
              {
                title: '隐私设置',
                path: '/mine/set/blacklist',
                component: 'Setting/Hide/Blacklist'
              }
            ]
          },
          //消息设置
          {
            title: '消息设置',
            path: '/mine/set/setMassage',
            component: 'Setting/SetMassage'
          },
          // 个人资料
          {
            title: '个人资料',
            path: '/set/personal-info',
            component: 'Setting/PersonalInfo'
          },
          {
            title: '个人资料',
            path: '/set/avatar',
            component: 'Setting/Avatar'
          },
          //账号信息
          {
            title: '账号信息',
            path: '/mine/set/setAccount',
            component: 'Setting/SetAccount',
            routes: [
              {
                title: '手机号',
                path: '/mine/set/setAccount/getMobile',
                component: 'Setting/SetAccount/GetMobile'
              },
              {
                title: '更换手机号',
                path: '/mine/set/setAccount/changeMobile',
                component: 'Setting/SetAccount/ChangeMobile'
              },
              {
                title: '绑定新号码',
                path: '/mine/set/setAccount/bindMobile',
                component: 'Setting/SetAccount/BindMobile'
              }
            ]
          },
          //关于我们
          {
            title: '关于我们',
            path: '/mine/set/setAbout',
            component: 'Setting/SetAbout'
          },
          {
            title: '服务协议',
            path: '/mine/set/service',
            component: 'Setting/Service'
          }
        ]
      }
    ]
  },
  {
    path: '/member',
    exact: true,
    component: 'Member',
    title: '收益管理',
    routes: [
      //二维码
      {
        title: '店铺海报',
        path: '/member/qr-code',
        component: 'Member/QrCode'
      },
      //业绩查看
      {
        title: '业绩查看',
        path: '/member/performance',
        component: 'Member/Performance'
      },
      {
        title: '业绩查看',
        path: '/member/activePerformace',
        component: 'Member/ActivePerformace'
      },
      // 会员订单
      {
        // title: '分销商收益',
        path: '/member/order',
        component: 'Member/Order',
        routes: [
          // 会员订单详情
          {
            title: '收益详情',
            path: '/member/order/detail',
            component: 'Member/Order/Detail'
          }
        ]
      },
      // 账户
      {
        title: '账户',
        path: '/member/account',
        component: 'Member/Account/Account',
        exact: true,
        routes: [
          //账户明细列表
          {
            title: '账户明细',
            path: '/member/account/account_list',
            component: 'Member/Account/NewAccountList'
          },
          {
            title: '账户明细历史数据',
            path: '/member/account/old_account_list',
            component: 'Member/Account/AccountList'
          },
          //账户明细详情 旧
          {
            title: '账户明细',
            path: '/member/account/old_account_detail/:id',
            component: 'Member/Account/AccountDetail'
          },
          //账户明细详情
          {
            title: '账户明细',
            path: '/member/account/account_detail/:ids',
            component: 'Member/Account/NewAccountDetail'
          },
          // 提现
          {
            title: '余额提现',
            path: '/member/account/withdraw',
            component: 'WithDraw',
            exact: true
          },
          // 提现完成
          {
            title: '余额提现',
            path: '/member/account/withdraw/info',
            exact: true,
            component: 'WithDraw/Detail'
          },
          // 提现记录
          {
            title: '提现记录',
            path: '/member/account/withdraw/record',
            exact: true,
            component: 'WithDraw/Record'
          },
          // 提现详情
          {
            title: '提现详情',
            path: '/member/account/withdraw/record/:ids',
            exact: true,
            component: 'WithDraw/RecordDetail'
          }
        ]
      },
      // 创业营
      {
        title: '购宜购商学院',
        path: '/member/business_school',
        component: 'Member/BusinessSchool'
      },
      // 上新预告
      {
        title: '上新预告',
        path: '/prerelease',
        component: 'Setting/Pre'
      },
      // 团队
      {
        title: '我的VIP',
        path: '/member/team',
        component: 'Member/Team',
        routes: [
          // 二级列表页
          {
            title: '二级VIP',
            path: '/member/team/childList',
            component: 'Member/Team/ChildList'
          }
        ]
      },
      // 联系我们
      {
        title: '联系我们',
        path: '/member/contact',
        component: 'Member/Contact'
      },
      //推荐店长
      {
        title: '推荐店长',
        path: '/member/recommend_manager',
        component: 'Member/RecommendManager'
      },
      // 银行卡
      {
        title: '银行卡',
        path: '/member/bank-card',
        component: 'WithDraw/BankCard',
        exact: true
      },
      // 添加银行卡
      {
        title: '添加银行卡',
        path: '/member/bank-card/add',
        component: 'WithDraw/BankCard/Add',
        exact: true
      },
      // 详情银行卡
      {
        title: '银行卡详情',
        path: '/member/bank-card/:id',
        component: 'WithDraw/BankCard/Detail'
      }
    ]
  },
  // 专区
  {
    title: '满减活动',
    path: '/zone/discount/:id',
    component: 'Zone/Discount'
  },
  {
    title: '任选活动',
    path: '/zone/buyout/:id',
    component: 'Zone/Buyout'
  },
  // 领取优惠券
  {
    title: '领取优惠券',
    path: '/zone/get-coupons',
    component: 'Zone/GetCoupons'
  },
  // 优惠券专区
  {
    title: '适用商品',
    path: '/zone/coupon',
    component: 'Zone/Coupon'
  },
  // 签到积分
  {
    title: '签到积分',
    path: '/zone/check',
    component: 'Zone/CheckIn'
  },
  // 公益栏目
  {
    title: '一哥公益',
    path: '/zone/donate',
    component: 'Zone/Donate'
  },
  // 固定模版
  {
    path: '/fixd-temp/:ids',
    component: 'FixdTemp'
  },
  {
    title: '新人专区',
    path: '/zone/new-buyer',
    component: 'Zone/NewBuyer'
  },
  // 爆款商品
  {
    path: '/zone/hot-goods/:id',
    component: 'Zone/HotGoods',
    routes: [
      // 爆款商品搜索页
      {
        path: '/zone/hot-goods/:id/search',
        component: 'Zone/HotGoods/Search'
      },
      // 榜单
      {
        path: '/zone/hot-goods/rank/:id',
        component: 'Zone/HotGoods/InvitationList'
      },
      // 更多商品
      {
        path: '/zone/hot-goods/goods-list/:id',
        component: 'Zone/HotGoods/GoodsList'
      }
    ]
  },
  {
    title: '调查问卷',
    path: '/zone/survey',
    component: 'Zone/Survey'
  },
  {
    title: '找相似',
    path: '/zone/similar/:id',
    component: 'Zone/Similar'
  },
  // 系统行为
  // 用户被禁用
  {
    title: '违规查看',
    path: '/action/disable',
    noAuth: true,
    component: 'Action/Disable'
  },
  // app二维码跳转
  {
    title: 'app二维码下载跳转',
    path: '/action/app-qrcode',
    noAuth: true,
    component: 'Action/AppQrcode'
  },
  // 订单流程
  // 确认订单
  {
    title: '确认订单',
    path: '/auction/confirm_order',
    component: 'Auction/ConfirmOrder'
  },
  // 选择支付方式
  {
    title: '收银台',
    path: '/auction/pay_method',
    component: 'Auction/PayMethod'
  },
  {
    title: '支付宝支付',
    path: '/alipay',
    component: 'Alipay'
  },

  // 支付成功
  {
    title: '支付结果',
    path: '/auction/pay_result',
    component: 'Auction/PayResult'
  },
  // 开具发票
  {
    title: '开具发票',
    path: '/receipt',
    component: 'Receipt',
    routes: [
      //添加发票
      {
        title: '发票抬头',
        path: '/receipt/action/:id',
        component: 'Receipt/Action'
      },
      //发票详情
      {
        title: '发票详情',
        path: '/receipt/detail/:id',
        component: 'Receipt/Detail'
      },
      //历史记录
      {
        title: '历史记录',
        path: '/receipt/log/:id',
        component: 'Receipt/Log'
      }
    ]
  },
  // 话题打卡
  {
    title: '话题打卡排行榜',
    path: '/topic/talk/board/:id',
    component: 'Topic/Talk/Board'
  },
  {
    title: '我的话题打卡详情',
    path: '/topic/talk/me',
    component: 'Topic/Talk/MeDetail'
  },
  {
    title: '话题打卡详情',
    path: '/topic/talk/:id',
    component: 'Topic/Talk/Detail'
  },
  {
    title: '分享得专属优惠',
    path: '/dynamic/topic/coupon-share/:id',
    component: 'Topic/CouponShare'
  },
  {
    title: '积分抵钱商品',
    path: '/topic/points-for-money',
    component: 'Topic/PointsForMoney'
  },
  {
    title: '买赠活动',
    path: '/topic/gifts',
    component: 'Topic/Gifts'
  },
  {
    title: '超值拼团',
    path: '/group-buy',
    component: 'GroupBuy',
    routes: [
      {
        title: '玩法',
        path: '/group-buy/play',
        component: 'GroupBuy/Play'
      },
      {
        title: '万人团',
        path: '/group-buy/ten-thousand-group',
        component: 'GroupBuy/TenThousandGroup'
      },
      {
        title: '拼团详情',
        path: '/group-buy/group-detail/:id',
        component: 'GroupBuy/Detail'
      }
    ]
  },

  {
    title: '企业资质',
    path: '/qualification',
    component: 'Qualification/index'
  },
  {
    title: '企业资质',
    path: '/qualificationDetail',
    component: 'Qualification/Detail'
  },

  // 登录页
  {
    path: '/login',
    exact: true,
    component: 'Login'
  },
  {
    title: '购宜购',
    path: '/qrcode',
    exact: true,
    component: 'Qrcode'
  },
  {
    title: '音乐中心',
    path: '/music',
    exact: true,
    component: 'Music'
  },
  // 商家入驻
  {
    title: '商家入驻',
    path: '/be_bussiness',
    component: 'Auction/Bussiness'
  },
  // Exception报错页面
  {
    title: '测试页面',
    exact: true,
    path: '/test',
    component: 'Test'
  },
  {
    path: '/exception',
    exact: true,
    showNavbar: true,
    noAuth: true,
    component: 'Exception',
    routes: [
      {
        path: '/exception/403',
        component: 'Exception/403'
      },
      {
        title: '404页面',
        path: '/exception/404',
        component: 'Exception/404'
      },
      {
        title: '500页面',
        path: '/exception/500',
        component: 'Exception/500'
      }
    ]
  }
]
